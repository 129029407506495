// @ts-nocheck
import React, {useEffect, useState, useRef} from 'react';
import '../../../../../index.scss';
import { Box, CssBaseline } from "@mui/material";
import Sider from "../../../../Layout/Sider/Sider";
import Header from "../../../../Layout/Header/Header";
import AccountFileUploadM from '../../../../Helpers/AccountFileUploadM/AccountFileUploadM';
import AutomatedImporterTrigger from '../../../BookImporter/AutomatedImporterTrigger/AutomatedImporterTrigger';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
const Fade = require("react-reveal/Fade")
const Reveal = require("react-reveal/Reveal")

function LeadBookImporter() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Lead Book Import" />
                        </Tabs>
                    </Box>
                </Box>

                <div className="mt-4">
                    {/* Manual Import */}
                    {tabValue == 0 &&
                        <div className="row">
                            <AccountFileUploadM importType={'lead'} />
                        </div>
                    }
                </div>

            </Box>
        </Box>
    );
}


export default LeadBookImporter;
