// @ts-nocheck
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    cardCustom: {
       height: '70vh',
       overflowY: 'auto'
    },
    modalCustom: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50vw',
        height: '90vh',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
    }
});

export { useStyles }
