import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

// Import components
import Landing from "../Components/Screens/Landing/Landing";
import Login from "../Components/Screens/Authentication/Login/Login";
import Register from "../Components/Screens/Authentication/Register/Register";
import Home from "../Components/Screens/Dashboard/Home/Home"
import Logout from "../Components/Screens/Authentication/Logout/Logout";
import {RootStateOrAny, useSelector} from "react-redux";
import {useTranslation, Trans} from 'react-i18next';
import {AuthGuard} from "./Guards/AuthGuard/AuthGuard";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


import RoleMain from "../Components/Screens/Dashboard/Role/RoleMain/RoleMain";
import UserMain from "../Components/Screens/Dashboard/User/UserMain/UserMain";
import SupplierMain from "../Components/Screens/Dashboard/Supplier/SupplierMain/SupplierMain";
import LeadMain from "../Components/Screens/Dashboard/Lead/LeadMain/LeadMain";
import AccountstatusMain from "../Components/Screens/Dashboard/Accountstatus/AccountstatusMain/AccountstatusMain";
import DispositionstatusMain
    from "../Components/Screens/Dashboard/Dispositionstatus/DispositionstatusMain/DispositionstatusMain";
import CampaignMain from "../Components/Screens/Dashboard/Campaign/CampaignMain/CampaignMain";
import NoteMain from "../Components/Screens/Dashboard/Note/NoteMain/NoteMain";
import BranchMain from "../Components/Screens/Dashboard/Branch/BranchMain/BranchMain";
import DesktypeMain from "../Components/Screens/Dashboard/Desktype/DesktypeMain/DesktypeMain";
import TeamMain from '../Components/Screens/Dashboard/Team/TeamMain/TeamMain';
import LeadBookImporter from '../Components/Screens/Dashboard/Lead/LeadBookImporter/LeadBookImporter';
import EmailtemplatesMain from '../Components/Screens/Dashboard/Emailtemplates/EmailtemplatesMain/EmailtemplatesMain';
import MediamanagementMain from '../Components/Screens/Dashboard/Mediamanagement/MediamanagementMain/MediamanagementMain';
import LeadBulkAssign from '../Components/Screens/Dashboard/Lead/LeadBulkAssign/LeadBulkAssign';
import MailServerMain from '../Components/Screens/Dashboard/MailServer/MailServerMain/MailServerMain';
import LetterltemplatesMain
    from "../Components/Screens/Dashboard/Lettertemplates/LettertemplatesMain/LetterltemplatesMain";
import PrintleadsMain from "../Components/Screens/Dashboard/Printleads/PrintleadsMain/PrintleadsMain";
import SearchAccount from '../Components/Screens/Dashboard/Searchaccount/SearchAccount';

// %TEMPLATE_IMPORT_COMPONENT%


function Router() {
    const {t, i18n} = useTranslation();

    // get current language
    // const language = useSelector(
    //     (state: RootStateOrAny) => state.global.global.language,
    // );

    // switch language here
    // i18n.changeLanguage('en')

    // get current theme
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );


    const darkTheme = createTheme({
        palette: {
            mode: theme,
            primary: {
                main: "#CDAF72FF",
                contrastText: "#fff" //button text white instead of black to support dark theme
            }
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <BrowserRouter>
                {/*<Header/>*/}
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/dashboard" element={<AuthGuard route={'dashboard'}/>}>
                        <Route path="/dashboard" element={<Home/>}/>
                    </Route>

                    <Route path="/role" element={<AuthGuard route={'role'}/>}>
                        <Route path="/role" element={<RoleMain/>}/>
                    </Route>

                    <Route path="/user" element={<AuthGuard route={'user'}/>}>
                        <Route path="/user" element={<UserMain/>}/>
                    </Route>

                    <Route path="/teams" element={<AuthGuard route={'teams'}/>}>
                      <Route path="/teams" element={<TeamMain />} />
                    </Route>

                    <Route path="/supplier" element={<AuthGuard route={'supplier'}/>}>
                        <Route path="/supplier" element={<SupplierMain/>}/>
                    </Route>

                    <Route path="/bookimporter" element={<AuthGuard route={'bookimporter'}/>} >
                        <Route path="/bookimporter" element={<LeadBookImporter />} />
                    </Route>

                    <Route path="/lettertemplate" element={<AuthGuard route={'lettertemplate'}/>} >
                        <Route path="/lettertemplate" element={<LetterltemplatesMain />} />
                    </Route>

                    <Route path="/printletter" element={<AuthGuard route={'lettertemplate'}/>} >
                        <Route path="/printletter" element={<PrintleadsMain />} />
                    </Route>

                    <Route path="/bulk_assign_lead" element={<AuthGuard route={'bulk_assign_lead'}/>} >
                        <Route path="/bulk_assign_lead" element={<LeadBulkAssign accountStatusData={[1]} />} />
                    </Route>

                    <Route path="/all_lead" element={<AuthGuard route={'all_lead'}/>}>
                        <Route path="/all_lead" element={<LeadMain accountStatusData={[1,2,3,4,5,6,7,8,9,10]}/>}/>
                    </Route>

                    <Route path="/telemarketing" element={<AuthGuard route={'telemarketing'}/>}>
                        <Route path="/telemarketing" element={<LeadMain accountStatusData={[1]}/>}/>
                    </Route>

                    <Route path="/brochured_lead" element={<AuthGuard route={'brochured_lead'}/>}>
                        <Route path="/brochured_lead" element={<LeadMain accountStatusData={[2]}/>}/>
                    </Route>

                    <Route path="/cif_inprogress_lead" element={<AuthGuard route={'cif_inprogress_lead'}/>}>
                        <Route path="/cif_inprogress_lead" element={<LeadMain accountStatusData={[3]}/>}/>
                    </Route>

                    <Route path="/cif_approval_lead" element={<AuthGuard route={'cif_approval_lead'}/>}>
                        <Route path="/cif_approval_lead" element={<LeadMain accountStatusData={[4]}/>}/>
                    </Route>

                    <Route path="/cif_completed_lead" element={<AuthGuard route={'cif_completed_lead'}/>}>
                        <Route path="/cif_completed_lead" element={<LeadMain accountStatusData={[5]}/>}/>
                    </Route>

                    <Route path="/dealt_lead" element={<AuthGuard route={'dealt_lead'}/>}>
                        <Route path="/dealt_lead" element={<LeadMain accountStatusData={[6]}/>}/>
                    </Route>

                    <Route path="/client_lead" element={<AuthGuard route={'client_lead'}/>}>
                        <Route path="/client_lead" element={<LeadMain accountStatusData={[7]}/>}/>
                    </Route>

                    <Route path="/sale_made_lead" element={<AuthGuard route={'sale_made_lead'}/>}>
                        <Route path="/sale_made_lead" element={<LeadMain accountStatusData={[8]}/>}/>
                    </Route>

                    <Route path="/dnc_lead" element={<AuthGuard route={'dnc_lead'}/>}>
                        <Route path="/dnc_lead" element={<LeadMain accountStatusData={[9]}/>}/>
                    </Route>

                    <Route path="/lead" element={<AuthGuard route={'lead'}/>}>
                        <Route path="/lead" element={<LeadMain accountStatusData={[10]}/>}/>
                    </Route>

                    {/* <Route path="/accountstatus" element={<AuthGuard/>}>
                        <Route path="/accountstatus" element={<AccountstatusMain/>}/>
                    </Route>

                    <Route path="/dispositionstatus" element={<AuthGuard/>}>
                        <Route path="/dispositionstatus" element={<DispositionstatusMain/>}/>
                    </Route> */}

                    <Route path="/campaign" element={<AuthGuard route={'campaign'}/>}>
                        <Route path="/campaign" element={<CampaignMain/>}/>
                    </Route>

                    <Route path="/mail_server" element={<AuthGuard route={'mail_server'}/>}>
                        <Route path="/mail_server" element={<MailServerMain/>}/>
                    </Route>

                    {/* <Route path="/note" element={<AuthGuard/>}>
                        <Route path="/note" element={<NoteMain/>}/>
                    </Route> */}

                    <Route path="/branch" element={<AuthGuard route={'branch'}/>}>
                        <Route path="/branch" element={<BranchMain/>}/>
                    </Route>

                    <Route path="/desktype" element={<AuthGuard route={'desktype'}/>}>
                        <Route path="/desktype" element={<DesktypeMain/>}/>
                    </Route>

                    <Route path="/emailtemplate" element={<AuthGuard route={'emailtemplate'}/>}>
                        <Route path="/emailtemplate" element={<EmailtemplatesMain/>}/>
                    </Route>

                    <Route path="/mediamanagement" element={<AuthGuard route={'mediamanagement'}/>}>
                        <Route path="/mediamanagement" element={<MediamanagementMain/>}/>
                    </Route>

                    <Route path="/search_account" element={<AuthGuard route={'search_account'}/>}>
                        <Route path="/search_account" element={<SearchAccount/>}/>
                    </Route>
                    {/* %TEMPLATE_INSERT_ROUTE%  */}


                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default Router;
