// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useStyles } from './Styles';
import Box from '@mui/material/Box';
import { Skeleton, Button, TextField, FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import CssBaseline from '@mui/material/CssBaseline';
import Header from "../../../Layout/Header/Header";
import Sider from "../../../Layout/Sider/Sider";
import { post, getById } from "../../../../Services/GenericApiService";
import { log } from "../../../../Services/LoggerService";
import TextDisplay from "../Chart/TextDisplay/TextDisplay";
import ApexPieChart from "../Chart/Apex/ApexPieChart/ApexPieChart";
import { getUserData } from '../../../../Services/LocalStorageService';
import { RootStateOrAny, useSelector } from "react-redux";
import CallbackChip from './CallbackChip/CallbackChip';
import LeadDetailView from '../Lead/LeadDetailView/LeadDetailView';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

function Home() {
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // module(s) for api
    const moduleMain = 'dashboard';
    const moduleAgentCallback = 'agentcallback';
    const moduleLead = 'lead';

    // get user data
    const userData = getUserData();

    // data vars
    const [allStats, setAllStats] = useState({});
    const [allAgentCallBack, setAllAgentCallBack] = useState([]);
    const [leadsCountByAccountStatusId, setLeadsCountByAccountStatusId] = useState({});
    const [leadsCountByDispositionStatusId, setLeadsCountByDispositionStatusId] = useState({});
    const [leadsCountByCampaignId, setLeadsCountByCampaignId] = useState({});
    const [leadData, setLeadData] = useState({});
    const [showLeadDetailView, setShowLeadDetailView] = useState(false);
    // ui controls
    const [loading, setLoading] = useState(true);
    const [toDate, setToDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);



    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const clearFilter = () => {
        setFromDate(null);
        setToDate(null);
        fetchStats(false);
    };

    const fetch = () => {
        fetchStats(false);
        getAllAssignedCallbacks();
    }

    const backToStats = () => {
        setShowLeadDetailView(false);
    }

    const getAllAssignedCallbacks = () => {
        setLoading(true); // show loader
        getById(moduleAgentCallback + '/assigned', userData?.id)
            .then((_res: any) => {
                // set all data
                setAllAgentCallBack(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const getLeadById = (_leadId) => {
        setLeadData({});
        setLoading(true); // show loader
        getById(moduleLead, _leadId)
            .then((_res: any) => {
                // set all data
                setLeadData(_res);
                setShowLeadDetailView(true);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchStats = (dateFilter = true) => {
        setLoading(true); // show loader

        let sd = null;
        let ed = null;

        if (dateFilter) {
            sd = fromDate;
            ed = toDate;
        }

        let payload = {
            startDate: sd,
            endDate: ed
        }

        post(moduleMain + '/stats', payload)
            .then((_res: any) => {
                // set all data
                setAllStats(_res)

                // prepare leads count by account status id chart data
                let data = [];
                let labels = [];
                _res.leadsCountByAccountStatus.forEach(_data => {
                    data.push(_data.count);
                    labels.push(_data.accountStatus);
                })
                setLeadsCountByAccountStatusId({
                    labels: labels,
                    data: data
                })

                // prepare leads count by account status id chart data
                data = [];
                labels = [];
                _res.leadsCountByDispositionStatus.forEach(_data => {
                    data.push(_data.count);
                    labels.push(_data.dispositionStatus);
                })
                setLeadsCountByDispositionStatusId({
                    labels: labels,
                    data: data
                })

                // prepare leads count by campaign id chart data
                data = [];
                labels = [];
                _res.leadsCountByCampaign.forEach(_data => {
                    data.push(_data.count);
                    labels.push(_data.campaign);
                })
                setLeadsCountByCampaignId({
                    labels: labels,
                    data: data
                })

                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <Header />

            <Sider />


            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>

                {/* date range */}
                <div className="row">
                    <div className="col-md-12">
                        <div className={(theme == 'dark' ? 'bg-dark' : '') + "card shadow-lg pt-1"}>
                            <div className="card-body">
                                <div className="row">
                                    <h3>
                                        Filters
                                    </h3>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={fromDate}
                                                    onChange={handleFromDateChange}
                                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                    label="From Date"
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </div>

                                    <div className="col-md-3">
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={toDate}
                                                    onChange={handleToDateChange}
                                                    renderInput={(params) => <TextField {...params} variant="outlined" readOnly />}
                                                    label="To Date"
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </div>

                                    <div className="col-md-3">
                                        <Button
                                            variant="contained"
                                            onClick={fetchStats}
                                            className={'bg-custom-gradient py-3'}
                                            fullWidth>
                                            Apply Filter
                                        </Button>
                                    </div>
                                    <div className="col-md-3">
                                        <Button
                                            variant="contained"
                                            onClick={clearFilter}
                                            className={'bg-custom-gradient py-3'}
                                            fullWidth>
                                            Clear Filter
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Stats Tiles */}
                <div className="row mt-5">
                    {userData?.roleId === 1 && !showLeadDetailView &&
                        <>
                            <div className="col-md-3">
                                <TextDisplay isLoading={loading} label={'Total Users'} data={allStats?.totalUsers || 0} background={'dark'} />
                            </div>
                            <div className="col-md-3">
                                <TextDisplay isLoading={loading} label={'Total Suppliers'} data={allStats?.totalSuppliers || 0} background={'dark'} />
                            </div>
                            <div className="col-md-3">
                                <TextDisplay isLoading={loading} label={'Total Campaigns'} data={allStats?.totalCampaigns || 0} background={'dark'} />
                            </div>
                        </>
                    }
                    {!showLeadDetailView &&
                        <div className="col-md-3">
                            <TextDisplay isLoading={loading} label={'Total Leads'} data={allStats?.totalLeads || 0} background={'dark'} />
                        </div>
                    }

                    {showLeadDetailView &&
                        <div className="row">
                            <div className="col-md-10">
                                <h2 className="mt-3">
                                    {t('module.lead')}
                                </h2>
                            </div>
                            <div className="col-md-2">
                                <Button
                                    onClick={backToStats}
                                    variant="contained"
                                    className='bg-custom-gradient my-2 py-3'
                                    fullWidth>
                                    Back
                                </Button>
                            </div>
                        </div>
                    }

                </div>

                {/* upcoming call backs  */}
                {!showLeadDetailView &&
                    <>
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <div className={(theme == 'dark' ? 'bg-dark' : '') + "card shadow-lg pt-1 pb-5"}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <h3>
                                                    Upcoming Callbacks
                                                </h3>
                                            </div>
                                            {/* onClick={getLeadById(agent?.leadId)} */}
                                            {!loading && allAgentCallBack.length > 0 && allAgentCallBack.map((agent, index) => (
                                                <div className="col-md-6 mt-4">
                                                    <div className={(theme == 'dark' ? 'bg-dark' : '') + "card shadow-lg"}>
                                                        <div className="card-body" onClick={() => getLeadById(agent?.leadId)} style={{ cursor: 'pointer' }}>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <span>
                                                                        {agent?.agentcallbackLead?.firstName + ' ' + agent?.agentcallbackLead?.lastName + ' (' + agent?.agentcallbackLead?.email + ')'}
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6 d-flex justify-content-end">
                                                                    <span style={{ display: 'inline-block' }}>
                                                                        {/* <Chip label={new Date(agent?.callback).toLocaleDateString("en-US",{month:'short', day: 'numeric', year:'numeric'})} variant="outlined" /> */}
                                                                        <CallbackChip agent={agent?.callback} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {!loading && allAgentCallBack.length == 0 &&
                                                <h3 className={'mt-4 mb-5 text-center'}>No Data</h3>
                                            }
                                            {loading &&
                                                <Skeleton className="my-4" height={50} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Pie Charts */}
                        <div className="row">
                            {/* leads count by account status id chart */}
                            <div className="col-md-6">
                                <ApexPieChart
                                    legendPosition={'left'}
                                    data={leadsCountByAccountStatusId.data}
                                    labels={leadsCountByAccountStatusId.labels}
                                    chartTitle={'Leads Count By Account Status'} />
                            </div>

                            {/* leads count by disposition status id chart */}
                            <div className="col-md-6">
                                <ApexPieChart
                                    legendPosition={'left'}
                                    data={leadsCountByDispositionStatusId.data}
                                    labels={leadsCountByDispositionStatusId.labels}
                                    chartTitle={'Leads Count By Disposition Status'} />
                            </div>

                            {/* leads count by campaign id chart */}
                            <div className="col-md-6">
                                <ApexPieChart
                                    legendPosition={'left'}
                                    data={leadsCountByCampaignId.data}
                                    labels={leadsCountByCampaignId.labels}
                                    chartTitle={'Leads Count By Campaign'} />
                            </div>
                        </div>
                    </>
                }
                {!loading && showLeadDetailView && Object.keys(leadData).length > 0 &&
                    <LeadDetailView data={leadData} />
                }

            </Box>
        </Box>
    );
}

export default Home;
