import React, { useRef } from 'react';
import { useStyles } from './Styles';
import { RootStateOrAny, useSelector } from "react-redux";
import { post } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";
import { encrypt } from '../../../../../Services/CryptoService';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { Button } from "@mui/material";
import MailServerForm from '../MailServerForm/MailServerForm';

// @ts-ignore
function MailServerCreate({ refreshDataEvent }) {
    // css for module
    const classes = useStyles();

    // module(s) for api
    const moduleMain = 'mailserver';

    // translation
    const { t } = useTranslation();

    // Redux
    const uploadedFiles = useSelector(
        (state: RootStateOrAny) => state.global.global.formData,
    );

    // child ref for alert
    const notifications = useRef();

    // ui handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);


    // handle add record event
    const handleAddEvent = (_formData: any) => {
        _formData.password = encrypt(_formData.password);
        
        setLoading(true); // show loader
        post(moduleMain, _formData)
            .then(_res => {
                setLoading(false); // hide loader
                refreshDataEvent(); // refresh data
                handleClose();
                // @ts-ignore
                notifications.current.successAlert(t('common.createSuccess'), '');
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }


    return (
        <div>
            <Button
                variant="contained"
                onClick={handleOpen}
                className={'bg-custom-gradient my-2 py-3'}
                fullWidth>
                {t('common.add')}
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <MailServerForm handleAddEvent={handleAddEvent}
                            isEditMode={false}
                            handleEditEvent={() => { }}
                            handleCancelEvent={handleCancelEvent}
                            recordToUpdate={null}
                            isLoading={loading} />
                    </Box>
                </Fade>
            </Modal>


            {/* Alerts */}
            <AlertM ref={notifications} />

        </div>
    );
}

export default MailServerCreate;
