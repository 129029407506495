// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./Styles";
import "../../../../../index.scss";
import {
    Box,
    FormControl,
    Button,
    Skeleton,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Card,
    Tooltip,
    IconButton,
    Autocomplete,
    Tabs,
    Tab,
} from "@mui/material";
import { RootStateOrAny, useSelector } from "react-redux";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
    getAll,
    getById,
    patch,
    post,
} from "../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimeline, faUser } from "@fortawesome/free-solid-svg-icons";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import Timeline from "@mui/lab/Timeline";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import { getUserData } from "../../../../../Services/LocalStorageService";
import ConfirmationDialog from "../../../../Helpers/ConfirmationDialog/ConfirmationDialog";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import LeadUpdate from "../LeadUpdate/LeadUpdate";
import { CoPresentOutlined } from "@mui/icons-material";

const accountStatusLabelToIdMap = JSON.parse(
    process.env.REACT_APP_SALES_STATUS_NAME_TO_ID_MAP
);
const dispositionStatusLabelToIdMap = JSON.parse(
    process.env.REACT_APP_DISPOSITION_STATUS_NAME_TO_ID_MAP
);
const dncStatusLabelToIdMap = JSON.parse(
    process.env.REACT_APP_DNC_STATUS_NAME_TO_ID_MAP
);

// @ts-ignore
function LeadDetailView({
    data,
    refreshDataCallback,
    refreshFlag = false,
    allData = [],
}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = "lead";

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme
    );

    // linked components API modules (endpoints)
    const moduleCampaign = "campaign";
    const moduleSupplier = "supplier";
    const moduleAccountstatus = "accountstatus";
    const moduleDispositionStatus = "dispositionstatus";
    const moduleAccountNotes = "note";
    const moduleCallBack = "agentcallback";
    const moduleLead = "lead";
    const moduleLeadUser = "leaduser";
    const moduleDnc = "dncstatus";
    const moduleEmailTemplate = "emailtemplate";
    const moduleLetterTemplate = "lettertemplate";
    const moduleLetterTemplateLead = "lettertemplatelead";
    const moduleUser = "user";
    const moduleDashboard = "dashboard";

    // translation
    const { t } = useTranslation();

    // get user data from jwt
    const userData = getUserData();

    // child ref for alert
    const notifications = useRef();

    // get campaign id from redux
    const CAMPAIGN_ID = useSelector(
        (state: RootStateOrAny) => state.global.global.campaignId
    );

    // UI methods
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [editMode, setEditMode] = React.useState(false);
    const [record, setRecord] = React.useState(data);
    const [recordIndex, setRecordIndex] = React.useState(-1);

    useEffect(() => {
        setRecord(data);
    }, [data]);

    useEffect(() => {
        if (allData.length !== 0) {
            setRecordIndex(allData.findIndex((val) => val.id === data.id));
        }
    }, [data, allData]);

    // form data
    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        // if (!isEditMode){
        //     handleAddEvent(_data);
        // } else {
        //     handleEditEvent(_data);
        // }
    };

    // data vars
    // Convert a JavaScript Date to Dayjs
    const minDate = dayjs(new Date());
    const [dispositionStatus, setDispositionStatus] = React.useState([{}]);
    const [selectedDispositionStatus, setSelectedDispositionStatus] =
        React.useState(0);
    const [selectedDncStatus, setSelectedDncStatus] = React.useState(0);
    const [accountStatus, setAccountStatus] = React.useState([]);
    const [dncStatus, setDncStatus] = React.useState([]);
    const [selectedAccountStatus, setSelectedAccountStatus] = React.useState(0);
    const [systemNotepad, setSystemNotepad] = React.useState([]);
    const [userNotepad, setUsernotepad] = React.useState([]);
    const [notesInput, setNotesInput] = React.useState("");
    const [
        accountStatusConfirmationModalOpen,
        setAccountStatusConfirmationModalOpen,
    ] = React.useState(false);
    const [
        dispositionStatusConfirmationModalOpen,
        setDispositionStatusConfirmationModalOpen,
    ] = React.useState(false);
    const [dncStatusConfirmationModalOpen, setDncStatusConfirmationModalOpen] =
        React.useState(false);
    const [tempAccountStatus, setTempAccountStatus] = React.useState(-1);
    const [tempDispositionStatus, setTempDispositionStatus] =
        React.useState(-1);
    const [tempDncStatus, setTempDncStatus] = React.useState(-1);
    const [selectedEmailTemplate, setSelectedEmailTemplate] =
        React.useState(null);
    const [allEmailTemplate, setAllEmailTemplate] = React.useState([]);
    const [selectedLetterTemplate, setSelectedLetterTemplate] =
        React.useState(null);
    const [allLetterTemplate, setAllLetterTemplate] = React.useState([]);
    const [allAgent, setAllAgent] = React.useState([]);
    const [selectedAgent, setSelectedAgent] = React.useState("");
    const [dateValue, setDatevalue] = React.useState<Dayjs | null>(
        dayjs(new Date())
    );
    const [senderEmail, setSenderEmail] = React.useState("");
    const [mailServerData, setMailServerData] = React.useState({});
    const [dataToEdit, setDataToEdit] = React.useState({});
    const [displayInfo, setDisplayInfo] = useState("");
    const [tabvalue, setTabvalue] = React.useState(0);

    const resetEdit = (_state) => {
        setEditMode(false);
    };

    // event handlers
    const onEditClick = (_displayInfo: any) => {
        if (_displayInfo === "detail") {
            setDisplayInfo(_displayInfo);
        } else {
            setDisplayInfo(_displayInfo);
        }
        setDataToEdit(record);
        setEditMode(true);
    };

    const handleDispositionStatusChange = (event: any) => {
        const newValue = parseInt(event.target.value);

        if (newValue !== selectedDispositionStatus) {
            setTempDispositionStatus(newValue);
            // Display the confirmation modal
            setDispositionStatusConfirmationModalOpen(true);
        }
    };

    const handleDncStatusChange = (event: any) => {
        const newValue = event.target.value;

        if (newValue !== selectedDncStatus) {
            setTempDncStatus(newValue);

            // Display the confirmation modal
            setDncStatusConfirmationModalOpen(true);
        }
    };

    const handleAccountStatusChange = (event: any) => {
        const newValue = event.target.value;

        if (newValue !== selectedAccountStatus) {
            setTempAccountStatus(newValue);

            // Display the confirmation modal
            setAccountStatusConfirmationModalOpen(true);
        }
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabvalue(newValue);
    };

    // fetch email template
    const getAllEmailTemplate = () => {
        setLoading(true); // show loader
        getAll(moduleEmailTemplate)
            .then((_res: any) => {
                // set all data
                setAllEmailTemplate(_res);
                setLoading(false); // hide loader
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    // fetch letter template
    const getAllLetterTemplate = () => {
        setLoading(true); // show loader
        getAll(moduleLetterTemplate)
            .then((_res: any) => {
                // set all data
                setAllLetterTemplate(_res);
                setLoading(false); // hide loader
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    // send letter for printing
    const printLetter = () => {
        setLoading(true);
        post(moduleLetterTemplateLead, {
            letterTemplateId: selectedLetterTemplate,
            leadId: record.id,
        })
            .then((_res) => {
                setLoading(false); // hide loader
                let payload = {
                    leadId: record?.id,
                    content: "Letter has been sent for print",
                    // @ts-ignore
                    createdBy: userData.id,
                };
                notpadEvent(payload);
                // @ts-ignore
                notifications.current.successAlert(
                    "Letter sent for printing",
                    ""
                );
            })
            .catch((_err) => {
                console.log(_err);
                if (_err.response.status === 500) {
                    // @ts-ignore
                    notifications.current.errorAlert(
                        "Error!!",
                        _err.response.data
                    );
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(
                        t("common.somethingWentWrong"),
                        t("common.tryAgain")
                    );
                }
                setLoading(false); // hide loader
            });
    };

    const sendBrochure = () => {
        setLoading(true);
        let emailPayload = {
            emailTemplateId: selectedEmailTemplate,
            title: record?.title,
            firstName: record?.firstName,
            lastName: record?.lastName,
            email: record?.email,
            contact: record?.mobileNo,
            address1: record?.address1,
            address2: record?.address2,
            address3: record?.address3,
            address4: record?.address4,
            campaignId: record["leadLeadcampaign.CampaignId"],
            // @ts-ignore
            senderEmail: userData?.email,
        };
        post(moduleLead + "/send/brochure", emailPayload)
            .then((_res) => {
                let payload = {
                    leadId: record?.id,
                    //account status changes to Brochured
                    content: "Account status changed to Brochured",
                    // @ts-ignore
                    createdBy: userData.id,
                    accountStatusId:
                        process.env.REACT_APP_ACCOUNT_STATUS_BROCHURED,
                };

                updateAccountStatus(record?.id, payload);

                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.successAlert(
                    "Brochure successfully Sent",
                    "Success"
                );
            })
            .catch((_err) => {
                console.log(_err);
                if (_err.response.status === 500) {
                    // @ts-ignore
                    notifications.current.errorAlert(
                        "Error!!",
                        _err.response.data
                    );
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(
                        t("common.somethingWentWrong"),
                        t("common.tryAgain")
                    );
                }
                setLoading(false); // hide loader
            });
    };

    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    };

    // ui handlers
    const onCancel = () => {
        // handleCancelEvent();
    };

    function getKeyByValue(object, value) {
        const keys = Object.keys(object);
        const foundKey = keys.find((key) => object[key] === value);
        return foundKey || null;
    }

    const handleAccountConfirmationYes = () => {
        setSelectedAccountStatus(tempAccountStatus);
        let payload = {
            leadId: record?.id,
            content:
                "Account status changed to " +
                getKeyByValue(accountStatusLabelToIdMap, tempAccountStatus),
            // @ts-ignore
            createdBy: userData.id,
            accountStatusId: tempAccountStatus,
        };
        updateAccountStatus(record?.id, payload);
        setAccountStatusConfirmationModalOpen(false);
    };

    const handleAccountStatusConfirmationNo = () => {
        setAccountStatusConfirmationModalOpen(false);
    };

    const handleDncConfirmationYes = () => {
        if (
            selectedAccountStatus !=
            parseInt(process.env.REACT_APP_ACCOUNT_STATUS_DNC) &&
            tempDncStatus != parseInt(process.env.REACT_APP_DNC_STATUS_NONE)
        ) {
            setSelectedAccountStatus(
                parseInt(process.env.REACT_APP_ACCOUNT_STATUS_DNC)
            );
            let payload = {
                leadId: record?.id,
                content:
                    "Account status changed to " +
                    getKeyByValue(
                        accountStatusLabelToIdMap,
                        parseInt(process.env.REACT_APP_ACCOUNT_STATUS_DNC)
                    ),
                // @ts-ignore
                createdBy: userData.id,
                accountStatusId: process.env.REACT_APP_ACCOUNT_STATUS_DNC,
            };
            updateAccountStatus(record?.id, payload);
        }
        setSelectedDncStatus(tempDncStatus);
        let payload = {
            leadId: record?.id,
            content:
                "DNC status changed to " +
                getKeyByValue(dncStatusLabelToIdMap, tempDncStatus),
            // @ts-ignore
            createdBy: userData.id,
            dncStatusId: tempDncStatus,
        };
        updateDncStatus(record?.id, payload);
        setDncStatusConfirmationModalOpen(false);
    };

    const handleDncStatusConfirmationNo = () => {
        setDncStatusConfirmationModalOpen(false);
    };

    const handleDispositionConfirmationYes = () => {
        setSelectedDispositionStatus(tempDispositionStatus);
        let payload = {
            leadId: record?.id,
            content:
                "Disposition status changed to " +
                getKeyByValue(
                    dispositionStatusLabelToIdMap,
                    tempDispositionStatus
                ),
            // @ts-ignore
            createdBy: userData.id,
            dispositionStatusId: tempDispositionStatus,
        };

        if (
            tempDispositionStatus ===
            parseInt(process.env.REACT_APP_DISPOSITION_STATUS_SET_CALL_BACK)
        ) {
            const callBackPayload = {
                leadId: record?.id,
                // @ts-ignore
                agentId: userData.id,
                callback: dateValue,
            };
            assignCallBackDate(callBackPayload);
        }

        updateDispositionStatus(record?.id, payload);
        setDispositionStatusConfirmationModalOpen(false);
    };

    const handleDispositionStatusConfirmationNo = () => {
        setDispositionStatusConfirmationModalOpen(false);
        setTempDispositionStatus(null);
    };

    const handleEmailTemplateChange = (event: any) => {
        setSelectedEmailTemplate(event.target.value);
    };

    const handleLetterTemplateChange = (event: any) => {
        setSelectedLetterTemplate(event.target.value);
    };

    const submitNotes = () => {
        setLoading(true); // show loader
        post(moduleAccountNotes, {
            leadId: record?.id,
            content: notesInput,
            isUserNote: 1,
            // @ts-ignore
            createdBy: userData.id,
            dispositionStatusId: tempDispositionStatus,
        })
            .then((_res) => {
                setNotesInput("");

                // get updated notes
                getAllAccountNodepad();
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    const notpadEvent = (_payload) => {
        setLoading(true); // show loader
        post(moduleAccountNotes, _payload)
            .then((_res) => {
                // get updated notes
                getAllAccountNodepad();
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    const assignCallBackDate = (payload) => {
        setLoading(true); // show loader
        post(moduleCallBack, payload)
            .then((_res) => {
                // get updated notes
                getAllAccountNodepad();
                setLoading(false);
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    //get all account nodepad against debtor id
    const getAllAccountNodepad = () => {
        setLoading(true); // show loader account.id
        getById(moduleAccountNotes + "/all", record?.id)
            .then((_res: any) => {
                setSystemNotepad(_res.systemNotes);
                setUsernotepad(_res.userNotes)
                setLoading(false); // hide loader
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    const getNextRecord = () => {
        if (record?.id !== undefined) {
            if (allData.length > recordIndex + 1) {
                setRecordIndex(recordIndex + 1);
                setRecord(allData[recordIndex + 1]);
            } else {
                setRecordIndex(0);
                setRecord(allData[0]);
                notifications.current.successAlert(
                    "Back to First Record",
                    "No more records in this page"
                );
            }
        } else {
            notifications.current.errorAlert(
                "Something is Wrong",
                "Select a record first"
            );
        }
    };

    const getPreviousRecord = () => {
        if (record?.id !== undefined) {
            if (recordIndex > 0) {
                setRecordIndex(recordIndex - 1);
                setRecord(allData[recordIndex - 1]);
            } else {
                setRecordIndex(allData.length - 1);
                setRecord(allData[allData.length - 1]);
                notifications.current.successAlert(
                    "Back to Last Record",
                    "No more records in this page"
                );
            }
        } else {
            notifications.current.errorAlert(
                "Something is Wrong",
                "Select a record first"
            );
        }
    };
    //get mail server details
    const getMailServerData = () => {
        setLoading(true); // show loader account.id
        getById(moduleCampaign, CAMPAIGN_ID)
            .then((_res: any) => {
                setMailServerData(_res);
                setLoading(false); // hide loader
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    // handle edit record event
    const updateDispositionStatus = (leadId: any, _payload: any) => {
        setLoading(true); // show loader
        patch(moduleLead, leadId, _payload)
            .then(async (_res) => {
                getAllAccountNodepad();
                if (refreshFlag) {
                    refreshDataCallback();
                }
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.successAlert(
                    "Disposition Status Updated",
                    ""
                );
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    // handle edit record event
    const updateAccountStatus = (leadId: any, _payload: any) => {
        setLoading(true); // show loader
        patch(moduleLead, leadId, _payload)
            .then(async (_res) => {
                getAllAccountNodepad();
                if (refreshFlag) {
                    refreshDataCallback();
                }
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.successAlert(
                    "Account Status Updated",
                    ""
                );
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    // handle edit record event
    const updateDncStatus = (leadId: any, _payload: any) => {
        setLoading(true); // show loader
        patch(moduleLead, leadId, _payload)
            .then(async (_res) => {
                getAllAccountNodepad();
                if (refreshFlag) {
                    refreshDataCallback();
                }
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.successAlert(
                    "Account Status Updated",
                    ""
                );
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    // handle edit record event
    const updateAgent = (leadId: any, agentId) => {
        setLoading(true); // show loader
        // @ts-ignore
        patch(moduleLeadUser + "/assignAgent", leadId, {
            agentId: agentId,
            userId: userData.id,
        })
            .then(async (_res) => {
                getAllAccountNodepad();
                //set firstname and last name of agent
                // Find the user with the matching ID
                const user = allAgent.find((item) => item.id === agentId);
                if (user) {
                    record["leadLeadUser.leadUserUser.firstName"] =
                        user.firstName;
                    record["leadLeadUser.leadUserUser.lastName"] =
                        user.lastName;
                    record["leadLeadUser.leadUserUser.email"] = user.email;
                }
                handleClose();
                if (refreshFlag) {
                    refreshDataCallback();
                }
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.successAlert(
                    "Agent Assigned Successfully",
                    ""
                );
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    // const fetch = () => {
    //     getAllDispositionStatus();
    //     getAllAccountStatus();
    //     getAllDncStatus();

    //     getAllEmailTemplate();
    //     getAllAgent();
    //     getAllLetterTemplate();
    // };

    // console.log(data);
    // if (Object.keys(data).length > 0) {
    //     fetch();
    // }


    // fetch suppliers
    const fetchAllStatus = () => {
        setLoading(true); // show loader
        getAll(moduleDashboard)
            .then((_res: any) => {
                setDncStatus(_res.dncStatus);
                setAccountStatus(_res.accountStatus);
                setDispositionStatus(_res.dispositionStatus);
                setAllAgent(_res.agent);
                setLoading(false); // hide loader
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    // hooks
    useEffect(() => {
        if (dncStatus.length === 0 || dispositionStatus.length === 0 || accountStatus.length === 0) {
            fetchAllStatus();
        }
        if (allEmailTemplate.length === 0) {
            getAllEmailTemplate();
        }
        if (allLetterTemplate.length === 0) {
            getAllLetterTemplate();
        }
    }, [data]);

    // hooks
    useEffect(() => {
        // @ts-ignore
        if (userData.roleId === 3 || userData.roleId === 4) {
            getMailServerData()
        }
    }, []);

    useEffect(() => {
        if (record) {
            setSelectedAccountStatus(record?.accountStatusId);
            setSelectedDispositionStatus(record?.dispositionStatusId);
            setSelectedDncStatus(record?.dncStatusId);
            setSelectedAgent(record["leadLeadUser.leadUserUser.id"]);
            if (record.id) {
                getAllAccountNodepad();
            }
        }
    }, [record?.id, record?.accountStatusId, record?.dispositionStatusId]);

    // @ts-ignore
    return (
        <Box sx={{ display: "flex" }} mt={3}>
            <div className="row w-100">
                {/* Accordion Starts */}
                {/* Leads */}
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        className="bg-custom-gradient text-white"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel10a-content"
                        id="panel0a-header"
                    >
                        <Typography>Lead</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row justify-content-end mb-3 mt-3">
                            <div className="col-3">
                                <Button
                                    variant="contained"
                                    disabled={false}
                                    onClick={getNextRecord}
                                    className={"bg-custom-secondary py-3"}
                                    fullWidth
                                >
                                    Next Account
                                </Button>
                            </div>
                            <div className="col-3">
                                <Button
                                    variant="contained"
                                    disabled={false}
                                    onClick={getPreviousRecord}
                                    className={"bg-custom-bw-gradient py-3"}
                                    fullWidth
                                >
                                    Back
                                </Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10">
                                <div className="row">
                                    {/* Account Status */}
                                    <div className="col-4">
                                        {!loading && (
                                            <div className={"pt-3 pb-3"}>
                                                <FormControl fullWidth={true}>
                                                    <InputLabel id="accountStatusLabel">
                                                        Sales Status
                                                    </InputLabel>
                                                    {accountStatus.length >
                                                        0 ? (
                                                        <Select
                                                            labelId="accountStatusLabelLabel"
                                                            id="accountStatusLabelSelect"
                                                            value={
                                                                selectedAccountStatus
                                                            }
                                                            disabled={
                                                                !selectedAccountStatus
                                                            }
                                                            onChange={
                                                                handleAccountStatusChange
                                                            }
                                                            label="Sales Status"
                                                        >
                                                            {accountStatus.map(
                                                                (el: any) => (
                                                                    <MenuItem
                                                                        value={
                                                                            el.id
                                                                        }
                                                                        key={
                                                                            el.id
                                                                        }
                                                                    >
                                                                        {
                                                                            el.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    ) : (
                                                        <>
                                                            <Select
                                                                labelId="accountStatusLabelLabel"
                                                                disabled
                                                                id="accountStatusLabelSelect"
                                                                value={""} // Set an empty value to have no data selected
                                                                label="sales Status"
                                                            >
                                                                <MenuItem
                                                                    value={""}
                                                                >
                                                                    No Data
                                                                </MenuItem>
                                                            </Select>
                                                            <FormHelperText>
                                                                No Data
                                                            </FormHelperText>
                                                        </>
                                                    )}
                                                </FormControl>
                                                <Dialog
                                                    open={
                                                        accountStatusConfirmationModalOpen
                                                    }
                                                    onClose={
                                                        handleAccountStatusConfirmationNo
                                                    }
                                                    aria-labelledby="account-title"
                                                    aria-describedby="account-description"
                                                >
                                                    <DialogTitle id="account-title">
                                                        Confirm Change
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="account-description">
                                                            Are you sure you
                                                            want to change the
                                                            account status?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={
                                                                handleAccountStatusConfirmationNo
                                                            }
                                                            color="primary"
                                                        >
                                                            No
                                                        </Button>
                                                        <Button
                                                            onClick={
                                                                handleAccountConfirmationYes
                                                            }
                                                            color="primary"
                                                        >
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                        )}
                                        {loading && (
                                            <Skeleton
                                                className="my-4"
                                                height={100}
                                            />
                                        )}
                                    </div>

                                    {/* DNC Reason */}
                                    <div className="col-4">
                                        {!loading && (
                                            <div className={"pt-3 pb-3"}>
                                                <FormControl fullWidth={true}>
                                                    <InputLabel id="dncStatusLabel">
                                                        DNC List
                                                    </InputLabel>
                                                    {dncStatus.length > 0 ? (
                                                        <Select
                                                            labelId="DncStatusLabelLabel"
                                                            id="DncStatusLabelSelect"
                                                            value={
                                                                selectedDncStatus
                                                            }
                                                            disabled={
                                                                !selectedDncStatus
                                                            }
                                                            onChange={
                                                                handleDncStatusChange
                                                            }
                                                            label="dncStatus"
                                                        >
                                                            {dncStatus.map(
                                                                (el: any) => (
                                                                    <MenuItem
                                                                        value={
                                                                            el.id
                                                                        }
                                                                        key={
                                                                            el.id
                                                                        }
                                                                    >
                                                                        {
                                                                            el.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    ) : (
                                                        <>
                                                            <Select
                                                                labelId="dncStatusLabelLabel"
                                                                disabled
                                                                id="dncStatusLabelSelect"
                                                                value={""} // Set an empty value to have no data selected
                                                                label="dncStatusLabel"
                                                            >
                                                                <MenuItem
                                                                    value={""}
                                                                >
                                                                    No Data
                                                                </MenuItem>
                                                            </Select>
                                                            <FormHelperText>
                                                                No Data
                                                            </FormHelperText>
                                                        </>
                                                    )}
                                                </FormControl>
                                                <Dialog
                                                    open={
                                                        dncStatusConfirmationModalOpen
                                                    }
                                                    onClose={
                                                        handleDncStatusConfirmationNo
                                                    }
                                                    aria-labelledby="dnc-title"
                                                    aria-describedby="dnc-description"
                                                >
                                                    <DialogTitle id="dnc-title">
                                                        Confirm Change
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="account-description">
                                                            Are you sure you
                                                            want to change the
                                                            DNC status?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={
                                                                handleDncStatusConfirmationNo
                                                            }
                                                            color="primary"
                                                        >
                                                            No
                                                        </Button>
                                                        <Button
                                                            onClick={
                                                                handleDncConfirmationYes
                                                            }
                                                            color="primary"
                                                        >
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                        )}
                                        {loading && (
                                            <Skeleton
                                                className="my-4"
                                                height={100}
                                            />
                                        )}
                                    </div>
                                </div>
                                {!loading && record && (
                                    <div className="row">
                                        {dispositionStatus.map((el: any) => (
                                            <div className="col-3 mt-2">
                                                <Button
                                                    variant="contained"
                                                    value={el.id}
                                                    disabled={
                                                        el.id ===
                                                        selectedDispositionStatus ||
                                                        !selectedDispositionStatus
                                                    }
                                                    onClick={
                                                        handleDispositionStatusChange
                                                    }
                                                    className={
                                                        el.id ===
                                                            selectedDispositionStatus
                                                            ? "bg-custom-secondary text-white py-1"
                                                            : "bg-custom-bw-gradient text-white py-1"
                                                    }
                                                    sx={{ fontSize: "13px" }}
                                                    fullWidth
                                                >
                                                    {el.name}
                                                </Button>
                                                <Dialog
                                                    open={
                                                        dispositionStatusConfirmationModalOpen
                                                    }
                                                    onClose={
                                                        handleDispositionStatusConfirmationNo
                                                    }
                                                    aria-labelledby="disposition-title"
                                                    aria-describedby="disposition-description"
                                                >
                                                    {tempDispositionStatus ===
                                                        parseInt(
                                                            process.env
                                                                .REACT_APP_DISPOSITION_STATUS_SET_CALL_BACK
                                                        ) && (
                                                            <DialogTitle id="disposition-title">
                                                                Select Call Back
                                                                Date
                                                            </DialogTitle>
                                                        )}
                                                    {tempDispositionStatus !=
                                                        parseInt(
                                                            process.env
                                                                .REACT_APP_DISPOSITION_STATUS_SET_CALL_BACK
                                                        ) && (
                                                            <DialogTitle id="disposition-title">
                                                                Confirm Change
                                                            </DialogTitle>
                                                        )}
                                                    <DialogContent>
                                                        {tempDispositionStatus ===
                                                            parseInt(
                                                                process.env
                                                                    .REACT_APP_DISPOSITION_STATUS_SET_CALL_BACK
                                                            ) && (
                                                                <LocalizationProvider
                                                                    dateAdapter={
                                                                        AdapterDayjs
                                                                    }
                                                                >
                                                                    <DateCalendar
                                                                        minDate={
                                                                            minDate
                                                                        }
                                                                        value={
                                                                            dateValue
                                                                        }
                                                                        onChange={(
                                                                            newValue
                                                                        ) =>
                                                                            setDatevalue(
                                                                                newValue
                                                                            )
                                                                        }
                                                                    />
                                                                </LocalizationProvider>
                                                            )}
                                                        {tempDispositionStatus !=
                                                            parseInt(
                                                                process.env
                                                                    .REACT_APP_DISPOSITION_STATUS_SET_CALL_BACK
                                                            ) && (
                                                                <DialogContentText id="disposition-description">
                                                                    Are you sure you
                                                                    want to change
                                                                    the disposition
                                                                    status?
                                                                </DialogContentText>
                                                            )}
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={
                                                                handleDispositionStatusConfirmationNo
                                                            }
                                                            color="primary"
                                                        >
                                                            No
                                                        </Button>
                                                        <Button
                                                            onClick={
                                                                handleDispositionConfirmationYes
                                                            }
                                                            color="primary"
                                                        >
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <div className="row mt-3">
                                    {/* contact detail card */}
                                    <div className="col-12">
                                        <div
                                            className={
                                                (theme == "dark"
                                                    ? "bg-dark"
                                                    : "") +
                                                " container card shadow-lg  mb-5 ms-0 me-0"
                                            }
                                            style={{
                                                height: "20em",
                                                overflow: "auto",
                                            }}
                                        >
                                            <div className="card-body ms-0 me-0">
                                                <h4 className="card-title">
                                                    Lead detail
                                                    <div
                                                        className="d-inline-flex"
                                                        style={{
                                                            float: "inline-end",
                                                        }}
                                                    >
                                                        <div className="px-2">
                                                            <Tooltip
                                                                title={t(
                                                                    "common.edit"
                                                                )}
                                                            >
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        onEditClick(
                                                                            "detail"
                                                                        );
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="edit-btn"
                                                                        icon="edit"
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </h4>
                                                <div className="row">
                                                    {/* Name */}
                                                    <div className="col-3">
                                                        <DataDisplayM
                                                            label={"Name"}
                                                            data={
                                                                record?.title ?
                                                                    `${record.title} ${record.firstName || ""} ${record.lastName || ""}`.trim() :
                                                                    record?.firstName ?
                                                                        `${record.firstName} ${record.lastName || ""}`.trim() :
                                                                        record?.lastName || null
                                                            }

                                                        />
                                                    </div>

                                                    {/* Mobile */}
                                                    <div className="col-2">
                                                        {record?.mobileNo && (
                                                            <DataDisplayM
                                                                label={"Mobile"}
                                                                data={
                                                                    record?.mobileNo
                                                                }
                                                            />
                                                        )}
                                                        {!record?.mobileNo && (
                                                            <DataDisplayM
                                                                label={"Mobile"}
                                                                data={null}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Home Tel */}
                                                    <div className="col">
                                                        <DataDisplayM
                                                            label={"Home Tel"}
                                                            data={null}
                                                        />
                                                    </div>

                                                    {/* Work Tel */}
                                                    <div className="col">
                                                        {record?.officeTel && (
                                                            <DataDisplayM
                                                                label={
                                                                    "Work Tel"
                                                                }
                                                                data={
                                                                    record?.officeTel
                                                                }
                                                            />
                                                        )}
                                                        {!record?.officeTel && (
                                                            <DataDisplayM
                                                                label={
                                                                    "Work Tel"
                                                                }
                                                                data={null}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Email */}
                                                    <div className="col">
                                                        {record?.email && (
                                                            <DataDisplayM
                                                                label={"Email"}
                                                                data={
                                                                    record?.email
                                                                }
                                                            />
                                                        )}
                                                        {!record?.email && (
                                                            <DataDisplayM
                                                                label={"Email"}
                                                                data={null}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {/* Address */}
                                                    <div className="col-5">
                                                        {record?.address1 && (
                                                            <DataDisplayM
                                                                label={
                                                                    "Address"
                                                                }
                                                                data={
                                                                    record.address1
                                                                }
                                                            />
                                                        )}
                                                        {!record?.address1 && (
                                                            <DataDisplayM
                                                                label={
                                                                    "Address"
                                                                }
                                                                data={null}
                                                            />
                                                        )}
                                                        {record?.address2 && (
                                                            <DataDisplayM
                                                                data={
                                                                    record?.address2
                                                                }
                                                            />
                                                        )}
                                                        {!record?.address2 && (
                                                            <DataDisplayM
                                                                data={null}
                                                            />
                                                        )}
                                                        {record?.address3 && (
                                                            <DataDisplayM
                                                                data={
                                                                    record?.address3
                                                                }
                                                            />
                                                        )}
                                                        {!record?.address3 && (
                                                            <DataDisplayM
                                                                data={null}
                                                            />
                                                        )}
                                                        {record?.address4 && (
                                                            <DataDisplayM
                                                                data={
                                                                    record?.address4
                                                                }
                                                            />
                                                        )}
                                                        {!record?.address4 && (
                                                            <DataDisplayM
                                                                data={null}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Last Contact */}
                                                    <div className="col">
                                                        <DataDisplayM
                                                            label={
                                                                "Last Contact"
                                                            }
                                                            data={null}
                                                        />
                                                    </div>

                                                    {/* Call Back */}
                                                    <div className="col">
                                                        <DataDisplayM
                                                            label={"Call Back"}
                                                            data={null}
                                                        />
                                                    </div>
                                                    <div className="col"></div>

                                                    {/* Town */}
                                                    {/* <div className="col-4">
                                                        <DataDisplayM label={'Town'}
                                                            data={null} />
                                                    </div> */}

                                                    {/* Post Code */}
                                                    {/* <div className="col-4">
                                                        {data?.postralCode &&
                                                            <DataDisplayM label={'Post Code'}
                                                                data={data?.postralCode} />
                                                        }
                                                        {!data?.postralCode &&
                                                            <DataDisplayM label={'Post Code'}
                                                                data={null} />
                                                        }
                                                    </div> */}

                                                    {/* Country */}
                                                    {/* <div className="col-4">
                                                        {data?.country &&
                                                            <DataDisplayM label={'Country'}
                                                                data={data?.country} />
                                                        }
                                                        {!data?.country &&
                                                            <DataDisplayM label={'Country'}
                                                                data={null} />
                                                        }
                                                    </div> */}

                                                    {/* Status */}
                                                    {/* <div className="col-4">
                                                        <DataDisplayM label={'Status'}
                                                            data={null} />
                                                    </div> */}

                                                    {/* 'Deal Limit */}
                                                    {/* <div className="col">
                                                        <DataDisplayM
                                                            label={"Deal Limit"}
                                                            data={null}
                                                        />
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-5">
                                                        {record?.country && (
                                                            <DataDisplayM
                                                                data={
                                                                    record?.country
                                                                }
                                                            />
                                                        )}
                                                        {!record?.country && (
                                                            <DataDisplayM
                                                                data={null}
                                                            />
                                                        )}
                                                        {record?.postralCode && (
                                                            <DataDisplayM
                                                                data={
                                                                    record?.postralCode
                                                                }
                                                            />
                                                        )}
                                                        {!record?.postralCode && (
                                                            <DataDisplayM
                                                                data={null}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Deal Total */}
                                                    {/* <div className="col">
                                                        <DataDisplayM
                                                            label={"Deal Total"}
                                                            data={null}
                                                        />
                                                    </div> */}

                                                    {/* Deal Diff */}
                                                    {/* <div className="col">
                                                        <DataDisplayM
                                                            label={"Deal Diff"}
                                                            data={null}
                                                        />
                                                    </div> */}

                                                    {/* Data Source */}
                                                    {/* <div className="col-4">
                                                        <DataDisplayM label={'Data Source'}
                                                            data={null} />
                                                    </div> */}

                                                    {/* Brochure Sent Date */}
                                                    {/* <div className="col-4">
                                                        <DataDisplayM label={'Brochure Sent Date'}
                                                            data={null} />
                                                    </div> */}

                                                    {/* Lead Number */}
                                                    {/* <div className="col-4">
                                                        <DataDisplayM label={'Lead Number'}
                                                            data={null} />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* contact information card */}
                                    <div className="col-12">
                                        <div
                                            className={
                                                (theme == "dark"
                                                    ? "bg-dark"
                                                    : "") +
                                                " container card shadow-lg  mb-5 ms-0 me-0"
                                            }
                                            style={{
                                                height: "20em",
                                                overflow: "auto",
                                            }}
                                        >
                                            <div className="card-body">
                                                <h4 className="card-title">
                                                    Lead information
                                                    <div
                                                        className="d-inline-flex"
                                                        style={{
                                                            float: "inline-end",
                                                        }}
                                                    >
                                                        <div className="px-2">
                                                            <Tooltip
                                                                title={t(
                                                                    "common.edit"
                                                                )}
                                                            >
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        onEditClick(
                                                                            "info"
                                                                        );
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="edit-btn"
                                                                        icon="edit"
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </h4>
                                                <div className="row w-100">
                                                    {/* Last Contac */}
                                                    <div className="col-4">
                                                        <DataDisplayM
                                                            label={
                                                                "Last Contact"
                                                            }
                                                            data={null}
                                                        />
                                                    </div>

                                                    {/* Call Back */}
                                                    <div className="col-4">
                                                        <DataDisplayM
                                                            label={"Call Back"}
                                                            data={null}
                                                        />
                                                    </div>

                                                    {/* Email */}
                                                    <div className="col-4">
                                                        {record?.email && (
                                                            <DataDisplayM
                                                                label={"Email"}
                                                                data={
                                                                    record?.email
                                                                }
                                                            />
                                                        )}
                                                        {!record?.email && (
                                                            <DataDisplayM
                                                                label={"Email"}
                                                                data={null}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Work Tel */}
                                                    <div className="col-4">
                                                        {record?.officeTel && (
                                                            <DataDisplayM
                                                                label={
                                                                    "Work Tel"
                                                                }
                                                                data={
                                                                    record?.officeTel
                                                                }
                                                            />
                                                        )}
                                                        {!record?.officeTel && (
                                                            <DataDisplayM
                                                                label={
                                                                    "Work Tel"
                                                                }
                                                                data={null}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Mobile */}
                                                    <div className="col-4">
                                                        {record?.mobileNo && (
                                                            <DataDisplayM
                                                                label={"Mobile"}
                                                                data={
                                                                    record?.mobileNo
                                                                }
                                                            />
                                                        )}
                                                        {!record?.mobileNo && (
                                                            <DataDisplayM
                                                                label={"Mobile"}
                                                                data={null}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Home Tel */}
                                                    <div className="col-4">
                                                        <DataDisplayM
                                                            label={"Home Tel"}
                                                            data={null}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-2">
                                {/* Agent Assign */}
                                <div
                                    className={
                                        (theme == "dark" ? "bg-dark" : "") +
                                        "card shadow-lg  mb-5 ms-0 me-0 text-center"
                                    }
                                >
                                    <div className="card-body">
                                        <h4 className="card-title">
                                            <FontAwesomeIcon icon={faUser} />{" "}
                                            {/* Use faTimeline here */}
                                            &nbsp;&nbsp;Assigned Agent
                                        </h4>
                                        <div className="row mt-4">
                                            {record[
                                                "leadLeadUser.leadUserUser.firstName"
                                            ] &&
                                                record[
                                                "leadLeadUser.leadUserUser.lastName"
                                                ] &&
                                                record[
                                                "leadLeadUser.leadUserUser.email"
                                                ] && (
                                                    <>
                                                        <div className="col-6">
                                                            <DataDisplayM
                                                                label={
                                                                    "First Name"
                                                                }
                                                                data={
                                                                    record[
                                                                    "leadLeadUser.leadUserUser.firstName"
                                                                    ]
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <DataDisplayM
                                                                label={
                                                                    "Last Name"
                                                                }
                                                                data={
                                                                    record[
                                                                    "leadLeadUser.leadUserUser.lastName"
                                                                    ]
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-12">
                                                            <DataDisplayM
                                                                label={"Email"}
                                                                data={
                                                                    record[
                                                                    "leadLeadUser.leadUserUser.email"
                                                                    ]
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            {(!record[
                                                "leadLeadUser.leadUserUser.firstName"
                                            ] ||
                                                !record[
                                                "leadLeadUser.leadUserUser.lastName"
                                                ] ||
                                                !record[
                                                "leadLeadUser.leadUserUser.email"
                                                ]) && (
                                                    <div className="col-12 mt-5 mb-5 d-flex justify-content-center m-auto">
                                                        <h4>No data</h4>
                                                    </div>
                                                )}

                                            <div className="col-12">
                                                <LoadingButton
                                                    variant="outlined"
                                                    className={
                                                        "full-width mt-4"
                                                    }
                                                    size="small"
                                                    onClick={handleOpen}
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    // @ts-ignore
                                                    disabled={
                                                        !record?.firstName ||
                                                        userData?.roleId != 1
                                                    }
                                                >
                                                    <span>Assign</span>
                                                </LoadingButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                {/* Account Note Pad */}
                <Accordion className="mt-3">
                    <AccordionSummary
                        className="bg-custom-gradient text-white"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Account Notepad</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div className="row">
                                <div
                                    className={
                                        (theme == "dark" ? "bg-dark" : "") +
                                        " container card shadow-lg  mb-5 ms-0 me-0"
                                    }
                                    style={{
                                        maxHeight: "80%",
                                        overflow: "auto",
                                    }}
                                >
                                    <div className="card-body">
                                        <h4 className="card-title">
                                            <FontAwesomeIcon
                                                icon={faTimeline}
                                            />{" "}
                                            {/* Use faTimeline here */}
                                            &nbsp;&nbsp;Account Notepad
                                        </h4>

                                        <div
                                            className={
                                                "mt-5 card shadow-lg py-3 " +
                                                classes.cardCustom
                                            }
                                        >
                                            <Tabs
                                                value={tabvalue}
                                                onChange={handleChangeTab}
                                                centered
                                            >
                                                <Tab label="User Notes" />
                                                <Tab label="System Notes" />
                                            </Tabs>

                                            {/*letters in queue*/}
                                            {tabvalue == 0 && (
                                                <div className="row mt-4">
                                                    <div className="mt-2 mb-5">
                                                        <div className="p-3">
                                                            {/*<h4 className="card-title">*/}
                                                            {/*    Add Notes*/}
                                                            {/*</h4>*/}

                                                            <TextField
                                                                id="addNotesInput"
                                                                label="Add Notes"
                                                                multiline
                                                                className={
                                                                    "full-width"
                                                                }
                                                                minRows={2}
                                                                value={
                                                                    notesInput
                                                                }
                                                                onChange={(e) =>
                                                                    setNotesInput(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />

                                                            {/* <Button variant="outlined" className={'full-width mt-4'} onClick={submitNotes}>
                                                                    Save Notes
                                                                    </Button> */}

                                                            <LoadingButton
                                                                variant="outlined"
                                                                className={
                                                                    "full-width mt-4"
                                                                }
                                                                size="small"
                                                                onClick={
                                                                    submitNotes
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                loadingPosition="start"
                                                                startIcon={
                                                                    <SaveIcon />
                                                                }
                                                                disabled={
                                                                    !notesInput
                                                                }
                                                            >
                                                                <span>
                                                                    Save
                                                                </span>
                                                            </LoadingButton>

                                                            <div className="row mt-4">
                                                                {userNotepad && (
                                                                    <Timeline
                                                                        sx={{
                                                                            [`& .${timelineItemClasses.root}:before`]:
                                                                            {
                                                                                flex: 0,
                                                                                padding: 0,
                                                                            },
                                                                        }}
                                                                    >
                                                                        {userNotepad.map(
                                                                            (item: any) => (
                                                                                <TimelineItem
                                                                                    key={
                                                                                        item?.id
                                                                                    }
                                                                                >
                                                                                    <TimelineSeparator>
                                                                                        <TimelineConnector />
                                                                                        <TimelineDot />
                                                                                        <TimelineConnector />
                                                                                    </TimelineSeparator>
                                                                                    <TimelineContent
                                                                                        sx={{
                                                                                            py: "12px",
                                                                                            px: 2,
                                                                                        }}
                                                                                    >
                                                                                        <Typography
                                                                                            variant="h6"
                                                                                            component="span"
                                                                                        >
                                                                                            {
                                                                                                item?.content
                                                                                            }
                                                                                        </Typography>

                                                                                        <p className="py-0 my-0 text-muted">
                                                                                            {new Date(
                                                                                                item?.createdAt
                                                                                            ).toDateString()}
                                                                                        </p>
                                                                                        <p className="py-0 my-0 mb-2 text-muted">
                                                                                            {item
                                                                                                ?.noteUser
                                                                                                ?.email ==
                                                                                                null
                                                                                                ? "System Event"
                                                                                                : item
                                                                                                    ?.noteUser
                                                                                                    ?.email}
                                                                                        </p>

                                                                                        <Typography>
                                                                                            {
                                                                                                item?.notes
                                                                                            }
                                                                                        </Typography>
                                                                                    </TimelineContent>
                                                                                </TimelineItem>
                                                                            )
                                                                        )}
                                                                    </Timeline>
                                                                )}

                                                                {!loading &&
                                                                    !userNotepad && (
                                                                        <h2
                                                                            className={
                                                                                "text-center my-5"
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon="triangle-exclamation" />
                                                                            &nbsp; No Data
                                                                        </h2>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {tabvalue == 1 && (
                                                <div className="row mt-4">
                                                    {systemNotepad && (
                                                        <Timeline
                                                            sx={{
                                                                [`& .${timelineItemClasses.root}:before`]:
                                                                {
                                                                    flex: 0,
                                                                    padding: 0,
                                                                },
                                                            }}
                                                        >
                                                            {systemNotepad.map(
                                                                (item: any) => (
                                                                    <TimelineItem
                                                                        key={
                                                                            item?.id
                                                                        }
                                                                    >
                                                                        <TimelineSeparator>
                                                                            <TimelineConnector />
                                                                            <TimelineDot />
                                                                            <TimelineConnector />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent
                                                                            sx={{
                                                                                py: "12px",
                                                                                px: 2,
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                variant="h6"
                                                                                component="span"
                                                                            >
                                                                                {
                                                                                    item?.content
                                                                                }
                                                                            </Typography>

                                                                            <p className="py-0 my-0 text-muted">
                                                                                {new Date(
                                                                                    item?.createdAt
                                                                                ).toDateString()}
                                                                            </p>
                                                                            <p className="py-0 my-0 mb-2 text-muted">
                                                                                {item
                                                                                    ?.noteUser
                                                                                    ?.email ==
                                                                                    null
                                                                                    ? "System Event"
                                                                                    : item
                                                                                        ?.noteUser
                                                                                        ?.email}
                                                                            </p>

                                                                            <Typography>
                                                                                {
                                                                                    item?.notes
                                                                                }
                                                                            </Typography>
                                                                        </TimelineContent>
                                                                    </TimelineItem>
                                                                )
                                                            )}
                                                        </Timeline>
                                                    )}

                                                    {!loading &&
                                                        !systemNotepad && (
                                                            <h2
                                                                className={
                                                                    "text-center my-5"
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon="triangle-exclamation" />
                                                                &nbsp; No Data
                                                            </h2>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {/* Letters & Emails Accordion */}
                <Accordion className="mt-3">
                    <AccordionSummary
                        className="bg-custom-gradient text-white"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Letters</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* email templates*/}
                        <div className="row">
                            <div className="col-md-8 mt-3">
                                {!loading && (
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="emailTemplateLabel">
                                            Email Template
                                        </InputLabel>
                                        {allEmailTemplate.length > 0 ? (
                                            <Select
                                                labelId="emailTemplateLabelLabel"
                                                id="emailTemplateLabelSelect"
                                                value={selectedEmailTemplate}
                                                onChange={
                                                    handleEmailTemplateChange
                                                }
                                                label="emailTemplate"
                                            >
                                                {allEmailTemplate.map(
                                                    (el: any) => (
                                                        <MenuItem
                                                            value={el.id}
                                                            key={el.id}
                                                        >
                                                            {el.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        ) : (
                                            <>
                                                <Select
                                                    labelId="emailTemplateLabelLabel"
                                                    disabled
                                                    id="emailTemplateLabelSelect"
                                                    value={""} // Set an empty value to have no data selected
                                                    label="emailTemplate"
                                                >
                                                    <MenuItem value={""}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        )}

                                        {/* @ts-ignore */}
                                        {mailServerData.isSpecificEmailServer ==
                                            1 &&
                                            mailServerData[
                                            "campaignMailserver.email"
                                            ] && (
                                                <div className="ps-1 pt-2">
                                                    Sender Email:{" "}
                                                    <strong>
                                                        {
                                                            mailServerData[
                                                            "campaignMailserver.email"
                                                            ]
                                                        }
                                                    </strong>
                                                </div>
                                            )}
                                        {/* @ts-ignore */}
                                        {mailServerData.isSpecificEmailServer ==
                                            0 && (
                                                <>
                                                    <div className="ps-1 pt-2">
                                                        Sender Email:{" "}
                                                        <strong>
                                                            {userData?.email}
                                                        </strong>
                                                    </div>
                                                </>
                                            )}
                                    </FormControl>
                                )}
                                {loading && (
                                    <Skeleton variant="text" height={60} />
                                )}
                            </div>
                            <div className="col-md-4 mt-2">
                                {!loading &&
                                    record?.id &&
                                    selectedEmailTemplate && (
                                        <ConfirmationDialog
                                            title={"Send Brochure Confirmation"}
                                            body={
                                                "Are you sure you want to send brochure?"
                                            }
                                            type={"confirm"}
                                            buttonText={"Send Email"}
                                            showButton={true}
                                            isLoading={loading}
                                            dataItemIdx={record?.id}
                                            confirmEvent={sendBrochure}
                                        />
                                    )}
                                {!loading &&
                                    (!record?.id || !selectedEmailTemplate) && (
                                        <Button
                                            className="mt-2 py-3"
                                            disabled={
                                                !record?.id ||
                                                !selectedEmailTemplate
                                            }
                                            variant="contained"
                                            fullWidth
                                        >
                                            Send Email
                                        </Button>
                                    )}
                                {loading && (
                                    <Skeleton variant="text" height={70} />
                                )}
                            </div>
                        </div>

                        {/* Letter templates*/}
                        <div className="row">
                            <div className="col-md-8 mt-3">
                                {!loading && (
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="letterTemplateLabel">
                                            Letter Template
                                        </InputLabel>
                                        {allLetterTemplate.length > 0 ? (
                                            <Select
                                                labelId="letterTemplateLabel"
                                                id="letterTemplateLabelSelect"
                                                value={selectedLetterTemplate}
                                                onChange={
                                                    handleLetterTemplateChange
                                                }
                                                label="letterTemplate"
                                            >
                                                {allLetterTemplate.map(
                                                    (el: any) => (
                                                        <MenuItem
                                                            value={el.id}
                                                            key={el.id}
                                                        >
                                                            {el.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        ) : (
                                            <>
                                                <Select
                                                    labelId="letterTemplateLabel"
                                                    disabled
                                                    id="letterTemplateLabel"
                                                    value={""} // Set an empty value to have no data selected
                                                    label="letterTemplate"
                                                >
                                                    <MenuItem value={""}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        )}
                                    </FormControl>
                                )}
                                {loading && (
                                    <Skeleton variant="text" height={60} />
                                )}
                            </div>
                            <div className="col-md-4 mt-2">
                                {!loading &&
                                    record?.id &&
                                    selectedLetterTemplate && (
                                        <ConfirmationDialog
                                            title={"Print Letter Confirmation"}
                                            body={
                                                "Are you sure you want to print letter?"
                                            }
                                            type={"confirm"}
                                            buttonText={"Print"}
                                            showButton={true}
                                            isLoading={loading}
                                            dataItemIdx={record?.id}
                                            confirmEvent={printLetter}
                                        />
                                    )}
                                {!loading &&
                                    (!record?.id ||
                                        !selectedLetterTemplate) && (
                                        <Button
                                            className="mt-2 py-3"
                                            disabled={
                                                !record?.id ||
                                                !selectedLetterTemplate
                                            }
                                            variant="contained"
                                            fullWidth
                                        >
                                            Print Letter
                                        </Button>
                                    )}
                                {loading && (
                                    <Skeleton variant="text" height={70} />
                                )}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                {/* CIF Information */}
                <Accordion className="mt-3">
                    <AccordionSummary
                        className="bg-custom-gradient text-white"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>CIF Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {/* AML Documents */}
                <Accordion className="mt-3">
                    <AccordionSummary
                        className="bg-custom-gradient text-white"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>AML Documents</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {/* Position */}
                <Accordion className="mt-3">
                    <AccordionSummary
                        className="bg-custom-gradient text-white"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Position</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div className="row mt-3">
                                {/* contact detail card */}
                                <div className="col-12">
                                    <div
                                        className={
                                            (theme == "dark" ? "bg-dark" : "") +
                                            " container card shadow-lg  mb-5 ms-0 me-0"
                                        }
                                        style={{
                                            height: "10em",
                                            overflow: "auto",
                                        }}
                                    >
                                        <div className="card-body ms-0 me-0">
                                            <h4 className="card-title">
                                                Other details
                                                {/* <div
                                                    className="d-inline-flex"
                                                    style={{
                                                        float: "inline-end",
                                                    }}
                                                >
                                                    <div className="px-2">
                                                        <Tooltip
                                                            title={t(
                                                                "common.edit"
                                                            )}
                                                        >
                                                            <IconButton
                                                                aria-label="delete"
                                                                size="small"
                                                                onClick={() => {
                                                                    onEditClick(
                                                                        "detail"
                                                                    );
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="edit-btn"
                                                                    icon="edit"
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </div> */}
                                            </h4>
                                            <div className="row">
                                                {/* 'Deal Limit */}
                                                <div className="col">
                                                    <DataDisplayM
                                                        label={"Deal Limit"}
                                                        data={null}
                                                    />
                                                </div>
                                                {/* Deal Total */}
                                                <div className="col">
                                                    <DataDisplayM
                                                        label={"Deal Total"}
                                                        data={null}
                                                    />
                                                </div>

                                                {/* Deal Diff */}
                                                <div className="col">
                                                    <DataDisplayM
                                                        label={"Deal Diff"}
                                                        data={null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {/* Client Payments */}
                <Accordion className="mt-3">
                    <AccordionSummary
                        className="bg-custom-gradient text-white"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Client Payments</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Box sx={{ display: "flex" }}>
                            <Card sx={{ minWidth: 1000, minHeight: "23vh" }}>
                                <div className="container mt-2">
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <h2>Select Agent</h2>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <FormControl fullWidth={true}>
                                                {allAgent.length > 0 && (
                                                    <Autocomplete
                                                        id="agentIdLabel"
                                                        options={allAgent}
                                                        getOptionLabel={(el) =>
                                                            el.email
                                                        }
                                                        onChange={(
                                                            event,
                                                            newValue
                                                        ) => {
                                                            if (newValue) {
                                                                setSelectedAgent(
                                                                    newValue.id
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            allAgent.find(
                                                                (el) =>
                                                                    el.id ===
                                                                    selectedAgent
                                                            ) || null
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Select Agent"
                                                            />
                                                        )}
                                                    />
                                                )}
                                                {allAgent.length == 0 && (
                                                    <>
                                                        <Autocomplete
                                                            id="agentIdLabel"
                                                            options={[
                                                                {
                                                                    label: "No Data",
                                                                    value: "",
                                                                },
                                                            ]}
                                                            disabled
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Select Agent"
                                                                />
                                                            )}
                                                        />
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                )}
                                            </FormControl>
                                        </div>
                                        <div className="col-12 mt-4 pb-2 d-flex justify-content-end">
                                            {/* Cancel Button */}
                                            <Button
                                                variant="outlined"
                                                onClick={handleClose}
                                                className="me-3"
                                            >
                                                Cancel
                                            </Button>

                                            {/* Okay Button */}
                                            <LoadingButton
                                                loading={loading}
                                                variant="contained"
                                                onClick={() => {
                                                    updateAgent(
                                                        record?.id,
                                                        selectedAgent
                                                    );
                                                }}
                                                className="bg-custom-gradient"
                                            >
                                                Update
                                            </LoadingButton>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Box>
                    </Box>
                </Fade>
            </Modal>

            {/* display edit modal */}
            {editMode && (
                <div>
                    <LeadUpdate
                        refreshDataEvent={refreshDataCallback}
                        displayInfo={displayInfo}
                        resetEditState={resetEdit}
                        recordToUpdate={record}
                    />
                </div>
            )}

            {/* Alerts */}
            <AlertM ref={notifications} />
        </Box>
    );
}

export default LeadDetailView;
