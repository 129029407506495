// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Header from "../../../../Layout/Header/Header";
import { useStyles } from "./Styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Sider from "../../../../Layout/Sider/Sider";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { getAll, post, patch } from "../../../../../Services/GenericApiService";
import { log } from "../../../../../Services/LoggerService";
import { useReactToPrint } from "react-to-print";
import Modal from "@mui/material/Modal";
import { getUserData } from "../../../../../Services/LocalStorageService";
import { Cookie } from "@mui/icons-material";

const Fade = require("react-reveal/Fade");
const Reveal = require("react-reveal/Reveal");

export const LetterView = React.forwardRef(({ templateLeadDetails }, ref) => {

  if (templateLeadDetails?.letterTemplateLeadLetterTemplate?.content) {

    if (templateLeadDetails?.letterTemplateLeadLead?.title) {
      
      templateLeadDetails.letterTemplateLeadLetterTemplate.content =
      templateLeadDetails?.letterTemplateLeadLetterTemplate?.content.replaceAll(
        "$title",
        templateLeadDetails?.letterTemplateLeadLead?.title
      );
    
    }

    if (templateLeadDetails?.letterTemplateLeadLead?.firstName) {
    
      templateLeadDetails.letterTemplateLeadLetterTemplate.content =
      templateLeadDetails?.letterTemplateLeadLetterTemplate?.content.replaceAll(
        "$firstName",
        templateLeadDetails?.letterTemplateLeadLead?.firstName
      );
    
    }

    if (templateLeadDetails?.letterTemplateLeadLead?.lastName) {
    
    templateLeadDetails.letterTemplateLeadLetterTemplate.content =
      templateLeadDetails?.letterTemplateLeadLetterTemplate?.content.replaceAll(
        "$lastName",
        templateLeadDetails?.letterTemplateLeadLead?.lastName
      );

    }

    if (templateLeadDetails?.letterTemplateLeadLead?.email) {
    
    templateLeadDetails.letterTemplateLeadLetterTemplate.content =
      templateLeadDetails?.letterTemplateLeadLetterTemplate?.content.replaceAll(
        "$email",
        templateLeadDetails?.letterTemplateLeadLead?.email
      );

    }

    if (templateLeadDetails?.letterTemplateLeadLead?.address1) {
    
    templateLeadDetails.letterTemplateLeadLetterTemplate.content =
      templateLeadDetails?.letterTemplateLeadLetterTemplate?.content.replaceAll(
        "$address1",
        templateLeadDetails?.letterTemplateLeadLead?.address1
      );

    }

    if (templateLeadDetails?.letterTemplateLeadLead?.address2) {
    
    templateLeadDetails.letterTemplateLeadLetterTemplate.content =
      templateLeadDetails?.letterTemplateLeadLetterTemplate?.content.replaceAll(
        "$address2",
        templateLeadDetails?.letterTemplateLeadLead?.address2
      );

    }


    if (templateLeadDetails?.letterTemplateLeadLead?.address3) {
    
    templateLeadDetails.letterTemplateLeadLetterTemplate.content =
      templateLeadDetails?.letterTemplateLeadLetterTemplate?.content.replaceAll(
        "$address3",
        templateLeadDetails?.letterTemplateLeadLead?.address3
      );

    }


    if (templateLeadDetails?.letterTemplateLeadLead?.address4) {
    
    templateLeadDetails.letterTemplateLeadLetterTemplate.content =
      templateLeadDetails?.letterTemplateLeadLetterTemplate?.content.replaceAll(
        "$address4",
        templateLeadDetails?.letterTemplateLeadLead?.address4
      );

    }
    
    templateLeadDetails.letterTemplateLeadLetterTemplate.content =
      templateLeadDetails?.letterTemplateLeadLetterTemplate?.content.replaceAll(
        "$todayDate",
        getCurrentDateFormatted()
      );

    templateLeadDetails.letterTemplateLeadLetterTemplate.content =
      templateLeadDetails?.letterTemplateLeadLetterTemplate?.content.replaceAll(
        "$ourRef",
        generateReferenceCode(templateLeadDetails?.letterTemplateLeadLead?.firstName, templateLeadDetails?.letterTemplateLeadLead?.lastName)
      );

  }

  function generateReferenceCode(firstName, lastName) {
    // Initialize initials as empty
    let initials = '';

    // Extract the first character of the first name if it exists
    if (firstName && firstName.length > 0) {
      initials += firstName.charAt(0).toUpperCase();
    }

    // Extract the first character of the last name if it exists
    if (lastName && lastName.length > 0) {
      initials += lastName.charAt(0).toUpperCase();
    }

    // Get the current date
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');  // Months are zero-based, so we add 1
    const day = today.getDate().toString().padStart(2, '0');           // Pad the day with leading zero if necessary

    // Combine the initials with the static "AL" and the date
    const referenceCode = `${initials}AL${month}${day}-01`;

    return referenceCode;
  }

  function getCurrentDateFormatted() {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = today.getFullYear().toString().slice(-2); // Get the last two digits of the year

    return `${day}/${month}/${year}`;
  }

  return (
    <div ref={ref}>
      <div>
        <div dangerouslySetInnerHTML={{ __html: templateLeadDetails?.letterTemplateLeadLetterTemplate?.content }} />
      </div>
    </div>
  );
});

function PrintleadsMain() {
  // css for module
  const classes = useStyles();

  // child ref for alert
  const notifications = useRef();
  const leadIdRef = useRef();

  // translation
  const { t } = useTranslation();

  // module
  const moduleMain = "lettertemplatelead";
  const moduleAccountNotes = "note";

  // get user data from jwt
  const userData = getUserData();

  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  // data vars
  const [tabvalue, setTabvalue] = React.useState(0);
  const [leadId, setLeadId] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [allLetters, setAllLetters] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedLetterTemplate, setSelectedLetterTemplate] = React.useState(
    {}
  );

  const updateEditableFields = () => {
    // patch(moduleMain, selectedLetterTemplate.id, {
    //   date: date,
    //   ourref: ourref,
    //   attachments: attachments,
    // })
    //   .then(async (_res) => {
    //     setLoading(false); // hide loade
    //   })
    //   .catch((_err) => {
    //     log(_err);
    //     setLoading(false); // hide loader
    //   });
  };

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    // updatdDataAfterPrint();
    // let payload = {
    //   leadId: selectedLetterTemplate.leadId,
    //   content: "Letter has been printed",
    //   // @ts-ignore
    //   createdBy: userData.id,
    // };

    notpadEvent();
  }, []);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Letter",
    onBeforeGetContent: updateEditableFields,
    onBeforePrint: () => {
      handleBeforePrint();
      updatdDataAfterPrint();
    },
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    pageStyle: `
    @page {
      margin: 60px;
      @top-left {
        content: none;
      }
      @top-right {
        content: none;
      }
      @bottom-left {
        content: none;
      }
      @bottom-right {
        content: none;
      }
    }
    @media print {
      body {
        margin: 0;
      }
    }
  `,
  });

  const updatdDataAfterPrint = () => {
    patch(moduleMain, selectedLetterTemplate.id, { isPrinted: true })
      .then(async (_res) => {
        setLoading(false); // hide loader
        setAllLetters((prevLetters) =>
          prevLetters.map((letter) =>
            letter.id === selectedLetterTemplate.id
              ? { ...letter, isPrinted: true }
              : letter
          )
        );
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  const notpadEvent = () => {
    setLoading(true); // show loader
    post(moduleAccountNotes, {
      leadId: leadIdRef.current,
      content: "Letter has been printed",
      // @ts-ignore
      createdBy: userData.id,
    })
      .then((_res) => {
        fetchLetters();
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  const handleOpen = (_letter) => {
    console.log("_letter", _letter);
    setSelectedLetterTemplate(_letter);
    leadIdRef.current = _letter?.leadId;
    setOpen(true);
  };
  const handleClose = () => {
    leadIdRef.current = null;
    setOpen(false);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabvalue(newValue);
  };

  const fetchLetters = () => {
    setLoading(true); // show loader
    getAll(moduleMain + `?page=${currentPage}&size=${pageSize}`)
      .then((_res: any) => {
        // console.log("res---", _res);
        // set all data
        setAllLetters(_res.rows);

        // update currentPage
        setCurrentPage((prevPage) => prevPage + 1);

        setLoading(false); // hide loader
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  useEffect(() => {
    fetchLetters();
  }, []);

  // useEffect(() => {
  //   console.log(selectedLetterTemplate);
  // }, [selectedLetterTemplate]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* Header */}
      <Header />

      {/* Sider */}
      <Sider />

      {/* Content */}
      <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
        <div className="px-1 px-md-5">
          {/* Upper Section */}
          <div className="row">
            <div className="col-md-10">
              <h2 className="mt-3">{t("module.printLetters")}</h2>
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className={"mt-5 card shadow-lg py-3 " + classes.cardCustom}>
            <Tabs value={tabvalue} onChange={handleChangeTab} centered>
              <Tab label="Letters in Queue" />
              <Tab label="Letters Printed" />
            </Tabs>

            {/*letters in queue*/}
            {tabvalue == 0 && (
              <div className={"mt-3 mx-2"}>
                {allLetters.length > 0 &&
                  allLetters.map((_letter) => {
                    if (!_letter.isPrinted) {
                      return (
                        <List>
                          <ListItem
                            disablePadding
                            onClick={() => {
                              handleOpen(_letter);
                              // setLeadId(_letter.leadId);
                            }}
                          >
                            <ListItemButton>
                              <ListItemText
                                secondary={
                                  "Letter: " +
                                  _letter?.letterTemplateLeadLetterTemplate
                                    ?.name
                                }
                                primary={`${_letter?.letterTemplateLeadLead?.title} ${_letter?.letterTemplateLeadLead?.firstName} ${_letter?.letterTemplateLeadLead?.lastName} (${_letter?.letterTemplateLeadLead?.email})`}
                              />
                              <ListItemIcon>
                                <LocalPrintshopIcon />
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                        </List>
                      );
                    }
                  })}
              </div>
            )}

            {/*letters printed*/}
            {tabvalue == 1 && (
              <div className={"mt-3 mx-2"}>
                {allLetters.length > 0 &&
                  allLetters.map((_letter) => {
                    if (_letter.isPrinted) {
                      return (
                        <List>
                          <ListItem
                            disablePadding
                            onClick={() => handleOpen(_letter)}
                          >
                            <ListItemButton>
                              <ListItemText
                                secondary={
                                  "Letter: " +
                                  _letter?.letterTemplateLeadLetterTemplate
                                    ?.name
                                }
                                primary={`${_letter?.letterTemplateLeadLead?.title} ${_letter?.letterTemplateLeadLead?.firstName} ${_letter?.letterTemplateLeadLead?.lastName} (${_letter?.letterTemplateLeadLead?.email})`}
                              />
                              <ListItemIcon>
                                {!_letter.isPrinted && <LocalPrintshopIcon />}
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                        </List>
                      );
                    }
                  })}
              </div>
            )}
          </div>
        </div>

        {/* Alerts */}
        <AlertM ref={notifications} />

        {/* Modal */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalCustom}>
            <Card className={"shadow-lg"}>
              <CardContent sx={{ overflow: "auto", padding: "50px" }}>
                <LetterView
                  ref={componentRef}
                  templateLeadDetails={selectedLetterTemplate}
                />
              </CardContent>
              <CardActions className={"float-end p-2"}>
                {!selectedLetterTemplate.isPrinted && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handlePrint();
                    }}
                  >
                    &nbsp;Print&nbsp;
                  </Button>
                )}
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default PrintleadsMain;
