// @ts-nocheck
import React, { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import Chip from '@mui/material/Chip';

function CallbackChip({ agent }) {

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    const [colorClass, setColorClass] = React.useState('bg-success');

    const selectColor = () => {
        const callbackDate = new Date(agent);
        const currentDate = new Date();
        const currentDateWithoutTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()); // Create a date with no time information
       
        if (callbackDate < currentDateWithoutTime) {
            setColorClass('bg-danger');
        } else if (callbackDate >= currentDateWithoutTime ) {
            setColorClass('bg-success');
        }
    }

    useEffect(() => {
        selectColor();
    }, [agent])

    return (
        <>
            <Chip
                className={`text-white ${colorClass} `}
                label={new Date(agent).toLocaleDateString("en-US", { month: 'short', day: 'numeric', year: 'numeric' })}
                variant="outlined"
            />
        </>
    );
}

export default CallbackChip;
