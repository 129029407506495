import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    register: {
                        tagline: 'Register with email',
                        haveAccount: 'Already have an account?',
                        createSuccess: 'Account Created',
                        creationMailSent: 'An activation email has been sent to you,'
                    },
                    login: {
                        tagline: 'Enter your email and password to login',
                        dontHaveAccount: 'Don\'t have an account?',
                        loginFailed: 'Login Failed',
                        invalidCredentials: 'Invalid login credentials'
                    },
                    form: {
                        email: 'Email',
                        password: 'Password'
                    },
                    common: {
                        add: 'Add',
                        edit: 'Update',
                        delete: 'Delete',
                        cancel: 'Cancel',
                        view: 'View',
                        success: 'Success',
                        allRecords: 'All Records',
                        recordDetails: 'Record Details',
                        tryAgain: 'Please try again later',
                        somethingWentWrong: 'Something went wrong',
                        createSuccess: 'Record created successfully',
                        editSuccess: 'Record updated successfully',
                        deleteSuccess: 'Record deleted successfully',
                        genericSuccess: 'Operation completed successfully',
                    },
                    module: {
                        register: 'Register',
                        login: 'Login',
                        role: 'Roles',
                        user: 'Users',
                        supplier: 'Supplier',
                        lead: 'Leads',
                        accountstatus: 'Account Status',
                        dispositionstatus: 'Disposition Status',
                        campaign: 'Campaign',
                        note: 'Notes',
                        desktype: 'Desk Type',
                        branch: 'Branch',
                        team: 'Team',
                        emailTemplate: 'Email Template',
                        mailServer: 'Mail Server',
                        letterTemplate: 'Letter Template',
                        printLetters: 'Print Letters'
                        // add new module here %TEMPLATE_MODULE_NAME%
                    },
                    role: {
                        title: 'Title',
                    },
                    user: {
                        firstName: 'First Name',
                        middleName: 'Middle Name',
                        lastName: 'Last Name',
                        email: 'Email',
                        team: 'Team',
                        contact: 'Contact',
                        roleId: 'Role',
                        isActive: 'Active',
                        isMicrosoftLinked: 'nan',
                        isGoogleLinked: 'nan',
                        password: 'Password',
                        token: 'nan',
                        lastLogin: 'Last Login',
                        branch: 'Branch',
                        deskType: 'Desk Type',
                        initial: 'Initials',
                        ddi: 'DDI',
                        extension: 'Extension',
                        signaturePosition: 'Signature Position',
                        startDate: 'Start Date',
                        endDate: 'End Date',
                        position: 'Position',
                        qualification: 'Qualification',
                        notes: 'Notes',
                    },
                    supplier: {
                        name: 'Name',
                        email: 'Email',
                        contactP: 'Contact Personal',
                        contactW: 'Contact Work',
                        companyName: 'Company',
                        companyWebsite: 'Website',
                    },
                    emailServer: {
                        signature: 'Signature',
                        host: 'Host',
                        email: 'Email',
                        port: 'Port',
                        ssltls: 'SSL/TLS',
                        active: 'Active',
                        password: 'Password',
                        details: 'Mail Server Details'
                    },
                    lead: {
                        name: 'Name',
                        email: 'Email',
                        contact: 'Contact',
                        campaignId: 'Campaign',
                        supplierId: 'Supplier',
                        accountStatusId: 'Account Status',
                        dispositionStatusId: 'Disposition Status',
                    },
                    accountstatus: {
                        name: 'Name',
                    },
                    dispositionstatus: {
                        name: 'Name',
                    },
                    campaign: {
                        name: 'Name',
                        description: 'Description',
                        isSpecificEmailServer: "Send Mail From Specific Mail Server",
                        emailServer: 'Email For Mail Server',
                        companyName: 'Company Name',
                        companyContact: 'Company Contact'
                    },
                    note: {
                        content: 'Notes',
                        createdBy: 'Created By',
                        leadId: 'Lead',
                    },
                    branch: {
                        name: 'Name',
                    },
                    team: {
                        name: 'Name',
                    },
                    desktype: {
                        name: 'Name',
                    }// add new module screen items here %TEMPLATE_MODULE_SCREEN_ITEMS%
                }
            },
            de: {
                translation: {
                    description: {
                        part1: 'Ändere <1>src/App.js</1> und speichere um neu zu laden.',
                        part2: 'Lerne React'
                    }
                }
            }
        }
    });

export default i18n;
