import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { post, getAll } from "../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import { Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from '@mui/lab';
import FileUploadM from "../../../../Helpers/FileUploadM/FileUploadM";


// @ts-ignore
function CampaignForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'campaign';
    const moduleMailServer = 'mailserver';

    // linked components API modules (endpoints)


    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            _data.mailServerId = specificMailServerId
            handleAddEvent(_data);
        } else {
            _data.mailServerId = specificMailServerId
            handleEditEvent(_data);
        }
    }

    // data vars
    const [isSpecificEmailServer, setIsSpecificEmailServer] = React.useState(false);
    const [allActiveEmail, setAllActiveEmail] = React.useState([]);
    const [specificMailServerId, setSpecificMailServerId] = React.useState(0);

    const handleSpecificEmailServerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsSpecificEmailServer(event.target.checked);
    };

    const handleSpecificMailServerIdChange = (event) => {
        setSpecificMailServerId(event.target.value);
    };


    // fetch dependent data
    const getAllMailServer = () => {
        setLoading(true); // show loader
        getAll(moduleMailServer)
            .then((_res: any) => {
                // set all data
                setAllActiveEmail(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data



    const fetch = () => {

    }



    // hooks
    useEffect(() => {
        fetch();
        getAllMailServer();
        if (isEditMode) {
            if(recordToUpdate?.isSpecificEmailServer) {
                setSpecificMailServerId(recordToUpdate?.mailServerId);
                setIsSpecificEmailServer(true);
            }
            reset(recordToUpdate);

        }
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.campaign')}
                                    </h3>

                                </div>



                                {/* Field name */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="nameInput"
                                            {...register("name", { required: true })}
                                            label={t('campaign.name')}
                                            type={"text"}
                                            error={!!errors.name}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Company Name */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="companyNameInput"
                                            {...register("companyName", { required: false })}
                                            label={t('campaign.companyName')}
                                            type={"text"}
                                            error={!!errors.companyName}
                                            helperText="Company Name for Email Signature"
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Company Contact */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="companyContactInput"
                                            {...register("companyContact", { required: false })}
                                            label={t('campaign.companyContact')}
                                            helperText="Company Contact for Email Signature"
                                            type={"text"}
                                            error={!!errors.companyContact}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field description */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="descriptionInput"
                                            {...register("description", { required: false })}
                                            label={t('campaign.description')}
                                            type={"text"}
                                            error={!!errors.description}
                                            multiline
                                            rows={2}
                                            // maxRows={4}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field isSpecific Email Server */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <FormControlLabel
                                            {...register("isSpecificEmailServer", {})}
                                            label={t('campaign.isSpecificEmailServer')}
                                            control={<Checkbox
                                                checked={isSpecificEmailServer}
                                                onChange={handleSpecificEmailServerChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                        />
                                    </FormControl>
                                </div>

                                {/* Select Specific Mail Server */}
                                {isSpecificEmailServer === true &&
                                    <div className="col-md-6 mt-4">
                                        {/* Field dispositionStatusId */}
                                        <FormControl fullWidth={true}>
                                            <InputLabel id="mailServerIdLabel">
                                                Mail Server
                                            </InputLabel>
                                            {allActiveEmail.length > 0 &&
                                                <Select labelId="mailServerIdLabel"
                                                    id="mailServerIdIdSelect"
                                                    value={specificMailServerId}
                                                    onChange={handleSpecificMailServerIdChange}
                                                    label="mailServerId">
                                                    {
                                                        allActiveEmail.map((el: any) =>
                                                            <MenuItem value={el.id}
                                                                key={el.id}> {el.email}
                                                            </MenuItem>)
                                                    }
                                                </Select>
                                            }
                                            {allActiveEmail.length == 0 &&
                                                <>
                                                    <Select labelId="mailServerIdLabel"
                                                        disabled
                                                        id="mailServerIdIdSelect"
                                                        label="mailServerId">
                                                        <MenuItem value={''}>
                                                            No Data
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        No Data
                                                    </FormHelperText>
                                                </>
                                            }
                                        </FormControl>
                                    </div>
                                }


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default CampaignForm;
