//@ts-nocheck
import { Box, CssBaseline, FormControl, TextField } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import Header from "../../../Layout/Header/Header";
import Sider from "../../../Layout/Sider/Sider";
import { useStyles } from "./Styles";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import AlertM from "../../../Helpers/AlertM/AlertM";
import { post } from "../../../../Services/GenericApiService";
import { Skeleton } from "@mui/material";
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { formatData } from "../../../../Services/DataFormatService";
import { getUserData } from "../../../../Services/LocalStorageService";

const SearchAccount = () => {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    //module
    const moduleMain = "lead";

    const notifications = useRef();

    // get user data
    const userData = getUserData();

    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm(); // watch is used to get the value e.g watch("name")

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme
    );

    //data vars
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [filter, setFilter] = useState({});

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [editMode, setEditMode] = React.useState(false);

    const onSubmit = (_data: any) => {
        if (!_data.email.trim() && !_data.name.trim() && !_data.mobileNo.trim()) {
            notifications.current.errorAlert(
                t("common.somethingWentWrong"),
                "All Fields are Empty"
            );
        } else {
            setFilter(_data);
            setCurrentPage(0);
            setPageSize(25);        
            filterData(_data);
        }
    };

    const filterData = (_data = null) => {

        setTableData([]);
        setLoading(true);

        let payload = {}

        if(_data) {
            _data.roleId = userData.roleId;
            _data.userId = userData.id;
        } else {
            payload = filter
            payload.roleId = userData.roleId;
            payload.userId = userData.id;
        }

        post(moduleMain + "/search/account", _data ? _data : filter, currentPage, pageSize)
            .then((_res) => {

                setAllData(_res.rows)
                setTotalRowCount(_res.count);

                const rows: any = [];
                _res?.rows.forEach((_record: any) => {
                    rows.push(
                        formatData([
                            _record?.firstName,
                            _record?.lastName,
                            _record?.email,
                            _record?.mobileNo,
                            _record["leadLeadcampaign.leadcampaignCampaign.name"],
                            _record["leadSupplier.name"],
                            _record["leadAccountStatus.name"],
                            _record["leadDispositionStatus.name"],
                            _record["leadDncStatus.name"],
                        ])
                    );
                });
                setTableData(rows);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                notifications.current.errorAlert(
                    t("common.somethingWentWrong"),
                    t("common.tryAgain")
                );
            });
    }

    useEffect(() => {
        filterData();
    }, [currentPage, pageSize])

    let columns = [
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Last Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Email", options: { filterOptions: { fullWidth: true } } },
        { name: "Contact", options: { filterOptions: { fullWidth: true } } },
        { name: "Campaign", options: { filterOptions: { fullWidth: true } } },
        { name: "Supplier", options: { filterOptions: { fullWidth: true } } },
        { name: "Sales Status", options: { filterOptions: { fullWidth: true } } },
        {
            name: "Disposition Status",
            options: { filterOptions: { fullWidth: true } },
        },
        { name: "DNC Status", options: { filterOptions: { fullWidth: true } } }
    ];

    let options = {
        search: false,
        download: false,
        print: false,
        viewColumns: true,
        filter: false,
        filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
          },
          onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
          },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
            // console.log(action);
            // console.dir(state);
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                                Search Account
                            </h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className={(theme == 'dark' ? 'bg-dark' : '') + "mt-3 card shadow-lg pt-1 pb-4"}>
                                <div className="card-body">
                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="row">

                                            {/* Field Name */}
                                            <div className="col-md-3 mt-4">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="name"
                                                        {...register("name", {
                                                            required: false,
                                                        })}
                                                        label={t("lead.name")}
                                                        type={"text"}
                                                        error={!!errors.name}
                                                        variant="outlined"
                                                        className="full-width"
                                                    />
                                                </FormControl>
                                            </div>
                                            {/* Field email */}
                                            <div className="col-md-3 mt-4">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="emailInput"
                                                        {...register("email", {
                                                            required: false,
                                                        })}
                                                        label={t("lead.email")}
                                                        type={"email"}
                                                        error={!!errors.email}
                                                        variant="outlined"
                                                        className="full-width"
                                                    />
                                                </FormControl>
                                            </div>

                                            {/* Field contact */}
                                            <div className="col-md-3 mt-4">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="mobileNo"
                                                        {...register("mobileNo", {
                                                            required: false,
                                                        })}
                                                        label={"Mobile No"}
                                                        type={"number"}
                                                        error={!!errors.mobileNo}
                                                        variant="outlined"
                                                        className="full-width"
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="col-md-3 mt-4">
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    className="bg-custom-gradient"
                                                    fullWidth
                                                >
                                                    Search
                                                </LoadingButton>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <Paper elevation={12}>
                            <MUIDataTable
                                title={t('common.allRecords')}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>

                </div>


                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box>
    );
};

export default SearchAccount;
