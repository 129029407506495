// @ts-nocheck

import React, { useEffect, useRef } from "react";
import { useStyles } from "./Styles";
import {
    Box,
    FormControl,
    Button,
    Skeleton,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import "../../../../../index.scss";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { RootStateOrAny, useSelector } from "react-redux";
import {
    getAll,
    post,
    getById,
    patch,
} from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import { log } from "../../../../../Services/LoggerService";

import CssBaseline from "@mui/material/CssBaseline";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import FileUploadM from "../../../../Helpers/FileUploadM/FileUploadM";

// font awesome stuff
const iconList = Object.keys(Icons)
    .filter((key) => key !== "fas" && key !== "prefix")
    .map((icon) => Icons[icon]);

library.add(...iconList);

function EmailtemplatesMain() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = "emailtemplate";
    const moduleLead = "lead";
    const moduleMailServer = 'mailserver';

    // form data
    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm(); // watch is used to get the value e.g watch("name")

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme
    );

    // Redux
    const uploadedFiles = useSelector(
        (state: RootStateOrAny) => state.global.global.formData
    );

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [selectedEmailTemplate, setSelectedEmailTemplate] = React.useState(0);
    const [showEmailTemplateFields, setShowEmailTemplateFields] =
        React.useState(false);
    const [subject, setSubject] = React.useState("");
    const [content, setContent] = React.useState("");
    const [footer, setFooter] = React.useState("");
    const [showUpload, setShowUpload] = React.useState(false);
    const [uploadFieldName, setUploadFieldName] = React.useState("");
    const [url, setUrl] = React.useState("");

    // ui controls
    const [loading, setLoading] = React.useState(true);

    // fetch dependent data
    const fetch = () => {
        setLoading(true); // show loader
        getAll(moduleMain)
            .then((_res: any) => {
                // set all data
                setAllData(_res);
                setLoading(false); // hide loader
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    const handleEmailTemplateChange = (event: any) => {
        setSelectedEmailTemplate(event.target.value);
        fetchTemplateData(event.target.value);
        setShowEmailTemplateFields(true);
    };

    const fetchTemplateData = async (_id: any) => {
        setUrl("");
        setLoading(true); // show loader
      
        try {
          const _res = await getById(moduleMain, _id);
          const signature = await getSignature(_id);
      
          setSubject(_res.subject);
          setContent(_res.content);
          setFooter(_res.footer);
          setLoading(false);
        } catch (_err) {
          log(_err);
          setLoading(false); // hide loader
        }
      };
      

    const getSignature = async (_id) => {
        getById(moduleMailServer + "/media/signature", _id)
            .then((_res) => {
                let url =
                    process.env.REACT_APP_SERVER_ENDPOINT +
                    _res.basePath +
                    "/" +
                    _res.files[_res.files.length - 1];
                setUrl(url);
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(
                    t("common.somethingWentWrong"),
                    t("common.tryAgain")
                );
            });
    };

    const showFileUpload = (fieldName: any) => {
        setShowUpload(true);
    };

    const back = () => {
        setShowUpload(false);
    };
    const uploadFiles = () => {
        // create a new formdata object because createFormData doesnt works apparently
        var bodyFormData = new FormData();

        Object.keys(uploadedFiles).forEach((_key) => {
            bodyFormData.append(
                "file",
                uploadedFiles[_key],
                uploadedFiles[_key].name
            );
        });
        post(
            moduleLead + "/upload/emailTemplateDoc/" + selectedEmailTemplate,
            bodyFormData
        )
            .then((_res) => {
                setShowUpload(false);
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(
                    t("common.somethingWentWrong"),
                    t("common.tryAgain")
                );
            });
    };

    const viewDocument = (fileId) => {
        getById(moduleLead + "/media/sendTemplate", fileId)
            .then((_res) => {
                let url =
                    process.env.REACT_APP_SERVER_ENDPOINT +
                    _res.basePath +
                    "/" +
                    _res.files[_res.files.length-1];
                // Open the URL in a new tab
                window.open(url, "_blank");
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(
                    t("common.somethingWentWrong"),
                    t("common.tryAgain")
                );
            });
    };
    // save template
    const onSubmit = () => {
        setLoading(true); // show loader
        let payload = {
            content: content,
            subject: subject,
            footer: footer,
        };
        patch(moduleMain, selectedEmailTemplate, payload)
            .then(async (_res) => {
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.successAlert(
                    "Template Successfully Updated",
                    "Success"
                );
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(
                    t("common.somethingWentWrong"),
                    t("common.tryAgain")
                );
            });
    };

    useEffect(() => {
        fetch();
    }, []);

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">
                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                                {t("module.emailTemplate")}
                            </h2>
                        </div>
                    </div>

                    <div className="mt-5">
                        {/* Template Form */}
                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    className={
                                        (theme == "dark" ? "bg-dark" : "") +
                                        " container card shadow-lg  mb-5 ms-0 me-0"
                                    }
                                >
                                    <div className="card-body mb-5 mt-2">
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="row">
                                                    <div className="col-8">
                                                        {!loading && (
                                                            <FormControl
                                                                fullWidth={true}
                                                            >
                                                                <InputLabel id="emailTemplateLabel">
                                                                    Email
                                                                    Template
                                                                </InputLabel>
                                                                {allData.length >
                                                                0 ? (
                                                                    <Select
                                                                        labelId="emailTemplateLabelLabel"
                                                                        id="emailTemplateLabelSelect"
                                                                        value={
                                                                            selectedEmailTemplate
                                                                        }
                                                                        onChange={
                                                                            handleEmailTemplateChange
                                                                        }
                                                                        label="emailTemplate"
                                                                    >
                                                                        {allData.map(
                                                                            (
                                                                                el: any
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        el.id
                                                                                    }
                                                                                    key={
                                                                                        el.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        el.name
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                ) : (
                                                                    <>
                                                                        <Select
                                                                            labelId="emailTemplateLabelLabel"
                                                                            disabled
                                                                            id="emailTemplateLabelSelect"
                                                                            value={
                                                                                ""
                                                                            } // Set an empty value to have no data selected
                                                                            label="emailTemplate"
                                                                        >
                                                                            <MenuItem
                                                                                value={
                                                                                    ""
                                                                                }
                                                                            >
                                                                                No
                                                                                Data
                                                                            </MenuItem>
                                                                        </Select>
                                                                        <FormHelperText>
                                                                            No
                                                                            Data
                                                                        </FormHelperText>
                                                                    </>
                                                                )}
                                                            </FormControl>
                                                        )}
                                                        {loading && (
                                                            <Skeleton
                                                                height={50}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-4 p-0 m-0">
                                                        {/* Save Button */}
                                                        {!loading && (
                                                            <Button
                                                                variant="contained"
                                                                disabled={
                                                                    !selectedEmailTemplate ||
                                                                    !subject ||
                                                                    !content
                                                                }
                                                                onClick={
                                                                    onSubmit
                                                                }
                                                                className={
                                                                    "bg-custom-gradient py-3"
                                                                }
                                                                fullWidth
                                                            >
                                                                Save
                                                            </Button>
                                                        )}
                                                        {loading && (
                                                            <Skeleton
                                                                height={50}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                {!loading &&
                                                    showEmailTemplateFields && (
                                                        <div>
                                                            {/* Email Subject Row */}
                                                            <div className="row mt-4">
                                                                <div className="col-12">
                                                                    <TextField
                                                                        id="subject"
                                                                        label={
                                                                            "Subject"
                                                                        }
                                                                        type={
                                                                            "text"
                                                                        }
                                                                        value={
                                                                            subject
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setSubject(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        variant="outlined"
                                                                        className="full-width"
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* Email Content */}
                                                            <div className="row mt-4">
                                                                <div className="col-12">
                                                                    <TextField
                                                                        id="content"
                                                                        label="Add Content"
                                                                        value={
                                                                            content
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setContent(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        multiline
                                                                        className={
                                                                            "full-width"
                                                                        }
                                                                        minRows={
                                                                            5
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* Email Footer */}
                                                            {/* <div className="row mt-4">
                                                            <div className="col-12">
                                                                <TextField
                                                                    id="footer"
                                                                    label="Add Footer/Signature"
                                                                    value={footer}
                                                                    onChange={e => setFooter(e.target.value)}
                                                                    multiline
                                                                    className={'full-width'}
                                                                    minRows={5}

                                                                />
                                                            </div>
                                                        </div> */}

                                                            <div className="row mt-4">
                                                                <div className="col-12">
                                                                    <img
                                                                        src={
                                                                            url
                                                                        }
                                                                        alt="No Signature Available"
                                                                        width="100%"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                {loading && (
                                                    <Skeleton height={200} />
                                                )}
                                            </div>

                                            {/* System Variables */}
                                            <div className="col-4 ps-5">
                                                <div className="row">
                                                    <div className="col-12">
                                                        {!loading && (
                                                            <>
                                                                <h4
                                                                    className={
                                                                        "text-muted p-0 m-0"
                                                                    }
                                                                >
                                                                    System
                                                                    Variable
                                                                </h4>
                                                                <ul
                                                                    className={
                                                                        "text-muted mt-3"
                                                                    }
                                                                >
                                                                    <li>
                                                                        $title
                                                                    </li>
                                                                    {/* <li
                                                                        className={
                                                                            "mt-2"
                                                                        }
                                                                    >
                                                                        $firstName
                                                                    </li> */}
                                                                    <li
                                                                        className={
                                                                            "mt-2"
                                                                        }
                                                                    >
                                                                        $lastName
                                                                    </li>
                                                                    {/* <li
                                                                        className={
                                                                            "mt-2"
                                                                        }
                                                                    >
                                                                        $email
                                                                    </li> */}
                                                                    {/* <li
                                                                        className={
                                                                            "mt-2"
                                                                        }
                                                                    >
                                                                        $contact
                                                                    </li> */}
                                                                    <li
                                                                        className={
                                                                            "mt-2"
                                                                        }
                                                                    >
                                                                        $senderName
                                                                    </li>
                                                                    {/* <li
                                                                        className={
                                                                            "mt-2"
                                                                        }
                                                                    >
                                                                        $senderEmail
                                                                    </li>
                                                                    <li
                                                                        className={
                                                                            "mt-2"
                                                                        }
                                                                    >
                                                                        $senderContact
                                                                    </li> */}
                                                                </ul>
                                                            </>
                                                        )}
                                                        {loading && (
                                                            <Skeleton
                                                                height={200}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        {!loading &&
                                                            !showUpload && (
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() =>
                                                                        showFileUpload(
                                                                            selectedEmailTemplate
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        !selectedEmailTemplate
                                                                    }
                                                                    className={
                                                                        "bg-custom-gradient py-2"
                                                                    }
                                                                    fullWidth
                                                                >
                                                                    Add/Replace
                                                                </Button>
                                                            )}
                                                        {!loading &&
                                                            showUpload && (
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={
                                                                        uploadFiles
                                                                    }
                                                                    disabled={
                                                                        !selectedEmailTemplate
                                                                    }
                                                                    className={
                                                                        "bg-custom-gradient py-2"
                                                                    }
                                                                    fullWidth
                                                                >
                                                                    Upload
                                                                </Button>
                                                            )}

                                                        {loading && (
                                                            <Skeleton
                                                                height={100}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-6">
                                                        {!loading &&
                                                            !showUpload && (
                                                                <Button
                                                                    variant="contained"
                                                                    disabled={
                                                                        !selectedEmailTemplate
                                                                    }
                                                                    onClick={() =>
                                                                        viewDocument(
                                                                            selectedEmailTemplate
                                                                        )
                                                                    }
                                                                    className={
                                                                        "bg-custom-gradient py-2"
                                                                    }
                                                                    fullWidth
                                                                >
                                                                    View
                                                                    Document
                                                                </Button>
                                                            )}
                                                        {!loading &&
                                                            showUpload && (
                                                                <Button
                                                                    variant="contained"
                                                                    disabled={
                                                                        !selectedEmailTemplate
                                                                    }
                                                                    onClick={
                                                                        back
                                                                    }
                                                                    className={
                                                                        "bg-custom-gradient py-2"
                                                                    }
                                                                    fullWidth
                                                                >
                                                                    Back
                                                                </Button>
                                                            )}
                                                        {loading && (
                                                            <Skeleton
                                                                height={100}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                {showUpload && (
                                                    <div className="col-6 mt-4">
                                                        <FileUploadM />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Alerts */}
                <AlertM ref={notifications} />
            </Box>
        </Box>
    );
}

export default EmailtemplatesMain;
