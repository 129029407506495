// @ts-nocheck

import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import { log } from "../../../../../Services/LoggerService";
import { decrypt, encrypt } from '../../../../../Services/CryptoService';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getById } from '../../../../../Services/GenericApiService';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function MailServerView({ data = null }) {

    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    const moduleMain = 'mailserver';

    // ui handlers
    const [open, setOpen] = React.useState(false);
    const [showToken, setShowToken] = React.useState(false);
    const [inputType, setInputType] = React.useState('password');
    const [password, setPassword] = React.useState('');
    const [url, setUrl] = React.useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    const viewDocument = () => {
        getById(moduleMain + "/media/signature", data?.id)
            .then((_res) => {
                let url =
                    process.env.REACT_APP_SERVER_ENDPOINT +
                    _res.basePath +
                    "/" +
                    _res.files[_res.files.length - 1];
                setUrl(url);
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(
                    t("common.somethingWentWrong"),
                    t("common.tryAgain")
                );
            });
    };

    const triggerTokenView = (flag) => {
        setShowToken(!showToken);
        flag == 'show' ? setInputType('text') : setInputType('password');
        if (flag == 'show') {
            setPassword(decrypt(data.password));
        } else {
            setPassword(encrypt(data.password));
        }
    }

    // hooks
    useEffect(() => {
        setPassword(data?.password);
    }, [data?.password]);

    useEffect(() => {
        if (data) {
            viewDocument();
        }
    }, [data])

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <Tooltip title={t('common.view')}>
                    <IconButton aria-label="delete" size="small" onClick={handleOpen}>
                        <FontAwesomeIcon className="edit-btn" icon='eye' />
                    </IconButton>
                </Tooltip>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                            <CardContent>
                                <div className="py-4 px-md-4 px-0">
                                    <h1 className="mb-3">{t('emailServer.details')}</h1>
                                    <div className="row full-width">


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('emailServer.host')} data={data.host} />
                                            }                                        </div>

                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('emailServer.port')} data={data.port} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('emailServer.email')} data={data.email} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('emailServer.ssltls')} data={data.isSSLTLS} />
                                            }                                        </div>

                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('emailServer.active')} data={data.isActive} />
                                            }                                        </div>

                                        <div className="col-md-6">
                                            {data &&
                                                <>
                                                    <label>{t('emailServer.signature')}</label>
                                                    <div>
                                                        <a href={url} target="_blank" rel="noopener noreferrer">
                                                            {url}
                                                        </a>
                                                    </div>
                                                </>
                                            }
                                        </div>


                                            <div className="col-md-5 pe-0 me-0">
                                                <p>Password</p>
                                                <input
                                                    type={inputType}
                                                    value={password}
                                                    readOnly
                                                    fullWidth
                                                    style={{ backgroundColor: 'transparent' }}
                                                    className="form-control d-inline-block mr-2"
                                                />
                                            </div>
                                            <div className="col-md-1 mt-4 ms-0 pt-2 d-flex justify-content-start align-items-center">
                                                {!showToken &&
                                                    <Tooltip title="Show Password">
                                                        <IconButton>
                                                            <RemoveRedEyeIcon onClick={() => triggerTokenView('show')} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {showToken &&
                                                    <Tooltip title="Hide Password">
                                                        <IconButton>
                                                            <VisibilityOffIcon onClick={() => triggerTokenView('hide')} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </div>

                                        </div>
                                    </div>
                            </CardContent>
                            <CardActions>
                                <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                                    {/* Cancel Button */}
                                    <Button variant="outlined"
                                        onClick={handleCancelEvent}>
                                        {t('common.cancel')}
                                    </Button>

                                    &nbsp;&nbsp;
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default MailServerView;
