// @ts-nocheck

import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "./Styles";
import "../../../../../index.scss";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {
  getAll,
  post,
  patch,
  deleteMultiple,
} from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import LeadCreate from "../LeadCreate/LeadCreate";
import LeadUpdate from "../LeadUpdate/LeadUpdate";
import { log } from "../../../../../Services/LoggerService";
import LeadDetailView from "../LeadDetailView/LeadDetailView";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import {
  IconButton,
  Skeleton,
  FormControl,
  FormHelperText,
  TextField,
  Button,
  Autocomplete,
  DialogActions,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Tabs,
  Tab,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { RootStateOrAny, useSelector } from "react-redux";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { LoadingButton } from "@mui/lab";
import TextDisplay from "../../Chart/TextDisplay/TextDisplay";
import { getUserData } from "../../../../../Services/LocalStorageService";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

function LeadMain({ accountStatusData }) {
  // css for module
  const classes = useStyles();

  // translation
  const { t } = useTranslation();

  // get theme from redux
  const theme = useSelector(
    (state: RootStateOrAny) => state.global.global.theme
  );

  // get campaign id from redux
  const CAMPAIGN_ID = useSelector(
    (state: RootStateOrAny) => state.global.global.campaignId
  );

  // get user data
  const userData = getUserData();

  // child ref for alert
  const notifications = useRef();

  // module(s) for api
  const moduleMain = "lead";
  const moduleCampaign = "campaign";
  const moduleDashboard = "dashboard";

  // data vars
  const [allData, setAllData] = React.useState([]);
  const [dataToEdit, setDataToEdit] = React.useState({});
  let columns = [
    {
      name: "Select Multiple",
      options: { filterOptions: { fullWidth: true } },
    },
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    { name: "Last Name", options: { filterOptions: { fullWidth: true } } },
    { name: "Email", options: { filterOptions: { fullWidth: true } } },
    { name: "Contact", options: { filterOptions: { fullWidth: true } } },
    { name: "Campaign", options: { filterOptions: { fullWidth: true } } },
    { name: "Supplier", options: { filterOptions: { fullWidth: true } } },
    { name: "Sales Status", options: { filterOptions: { fullWidth: true } } },
    {
      name: "Disposition Status",
      options: { filterOptions: { fullWidth: true } },
    },
    { name: "DNC Status", options: { filterOptions: { fullWidth: true } } },
    { name: "Action", options: { filterOptions: { fullWidth: true } } },
  ];

  // <LeadView data={allData[rowIndex]} />
  const [tableData, setTableData] = React.useState([]);
  const [onlyTable, setOnlyTable] = useState(false);
  const [showDataTable, setShowDataTable] = useState(true);
  const [paginationFlag, setPaginationFlag] = useState(false);
  const [
    isAgentAssignedConfirmationModalOpen,
    setIsAgentAssignedConfirmationModalOpen,
  ] = useState(false);
  const [allDispositionstatus, setAllDispositionstatus] = React.useState([]);
  const [allAgent, setAllAgent] = React.useState([]);
  const [allAccountStatus, setAllAccountStatus] = React.useState([]);
  const [allDncStatus, setAllDncStatus] = React.useState([]);
  const [allCampaign, setAllCampaign] = React.useState([]);
  const [allSupplier, setAllSupplier] = React.useState([]);
  const [selectAgentToAssign, setSelectAgentToAssign] = React.useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedIsAgentAssigned, setSelectedIsAgentAssigned] =
    useState("both");
  const [tempAgentAssigned, setTempAgentAssigned] = useState("both");
  const [isAgentPlaceholder, setIsAgentPlaceholder] = useState("both");
  const [selectedDispositionStatusIds, setSelectedDispositionStatusIds] =
    useState([1, 2, 3, 4, 5, 6, 7, 8]);
  const [selectedAccountStatusIds, setSelectedAccountStatusIds] =
    useState(accountStatusData);
  const [selectedDncStatusIds, setSelectedDncStatusIds] = useState([
    1, 2, 3, 4, 5, 6, 7, 8,
  ]);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);
  const [selectedSupplierIds, setSelectedSupplierIds] = React.useState([]);
  const [tabvalue, setTabvalue] = React.useState(0);
  const [currentLeadId, setCurrentLeadId] = React.useState(0);
  const [selectedFilters, setSelectedFilters] = React.useState({
    filter: {
      accountStatusId: accountStatusData,
      dispositionStatusId: selectedDispositionStatusIds,
      dncStatusId: selectedDncStatusIds,
    },
  });

  // ui controls
  const [loading, setLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);

  // table ui controls
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [checked, setChecked] = useState(Array(200).fill(false));
  const [checkedBoxes, setCheckedBoxes] = useState([]);

  const deleteSelected = () => {
    deleteMultiple(moduleMain, checkedBoxes)
      .then((_res) => {
        setAllAgent(_res);
      })
      .catch((_err) => {
        log(_err);
      });
  };

  const handleSelectDispositionChange = (event) => {
    let selectedValues = event.target.value;
    const updateFilter = {
      filter: {
        accountStatusId: selectedAccountStatusIds,
        dispositionStatusId: selectedValues,
        dncStatusId: selectedDncStatusIds,
      },
    };
    setSelectedFilters(updateFilter);
    setSelectedDispositionStatusIds(selectedValues);
  };

  const handleSelectAccountChange = (event) => {
    const selectedValues = event.target.value;
    const updateFilter = {
      filter: {
        accountStatusId: selectedValues,
        dispositionStatusId: selectedDispositionStatusIds,
        dncStatusId: selectedDncStatusIds,
      },
    };
    setSelectedFilters(updateFilter);
    setSelectedAccountStatusIds(selectedValues);
  };

  const handleSelectDncChange = (event) => {
    const selectedValues = event.target.value;
    const updateFilter = {
      filter: {
        accountStatusId: selectedAccountStatusIds,
        dispositionStatusId: selectedDispositionStatusIds,
        dncStatusId: selectedValues,
      },
    };
    setSelectedFilters(updateFilter);
    setSelectedDncStatusIds(selectedValues);
  };

  const handleSupplierChange = (event) => {
    setSelectedSupplierIds(event.target.value);
  };

  const handleAgentChange = (_, newValues) => {
    setSelectedAgentIds(newValues);
  };

  const handleSelectCampaignChange = (event) => {
    setSelectedCampaignIds(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleIsAgentAssignedChange = (event) => {
    const newValue = event.target.value;
    setIsAgentPlaceholder(newValue);
    if (newValue !== selectedIsAgentAssigned) {
      setTempAgentAssigned(event.target.value);
      // Display the confirmation modal
      setIsAgentAssignedConfirmationModalOpen(true);
    }
  };

  const handleIsAgentAssignedNo = () => {
    setIsAgentAssignedConfirmationModalOpen(false);
  };

  const handleIsAgentAssignedYes = () => {
    setSelectedIsAgentAssigned(tempAgentAssigned);
    setSelectAgentToAssign(0);
    setSelectedAgentIds([]);
    getLeadByFilter(null, tempAgentAssigned);
    setIsAgentAssignedConfirmationModalOpen(false);
  };

  const handleAgentToAssignChange = (event) => {
    setSelectAgentToAssign(event.target.value);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabvalue(newValue);
  };

  // fetch Campaign
  const fetchCampaign = () => {
    setLoading(true); // show loader
    getAll(moduleCampaign)
      .then((_res: any) => {
        setAllCampaign(_res);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  // fetch suppliers
  const fetchAllStatus = () => {
    setLoading(true); // show loader
    getAll(moduleDashboard)
      .then((_res: any) => {
        setAllSupplier(_res.supplier);
        setAllDncStatus(_res.dncStatus);
        setAllAccountStatus(_res.accountStatus);
        setAllDispositionstatus(_res.dispositionStatus);
        setAllAgent(_res.agent);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  const getLeadByFilter = (
    filterId = null,
    tempAgentAssigned = "",
    agentId = userData.id
  ) => {
    setTableData([]); // reset table data
    setLoading(true); // show loader

    let payload = {};

    if (filterId?.length > 0) {
      const updateFilter = {
        filter: {
          accountStatusId: filterId,
          dispositionStatusId: selectedDispositionStatusIds,
          dncStatusId: selectedDncStatusIds,
        },
      };
      setSelectedFilters(updateFilter);

      payload = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        selectedFilters: updateFilter,
        campaignId: selectedCampaignIds,
        supplierId: selectedSupplierIds,
        agentId: agentId,
      };

      if (userData.roleId === 3 || userData.roleId === 4) {
        payload["isAgentAssigned"] = "assigned";
        payload["campaignId"] = [CAMPAIGN_ID];
      } else {
        if (tempAgentAssigned != "") {
          payload["isAgentAssigned"] = tempAgentAssigned;
        } else {
          payload["isAgentAssigned"] = selectedIsAgentAssigned;
        }
      }
    } else {
      payload = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        selectedFilters: selectedFilters,
        campaignId: selectedCampaignIds,
        supplierId: selectedSupplierIds,
      };

      if (userData.roleId === 3 || userData.roleId === 4) {
        payload["agentId"] = [userData.id];
        payload["isAgentAssigned"] = "assigned";
        payload["campaignId"] = [CAMPAIGN_ID];
      } else {
        payload["agentId"] = selectedAgentIds.map((item) => item.id);

        if (tempAgentAssigned != "") {
          payload["isAgentAssigned"] = tempAgentAssigned;
        } else {
          payload["isAgentAssigned"] = selectedIsAgentAssigned;
        }
      }
    }

    post(moduleMain + "/filter", payload, currentPage, pageSize)
      .then((_res: any) => {
        setAllData(_res?.rows);
        setTotalRowCount(_res?.count);
        setPaginationFlag(true);
        const rows: any = [];
        if (_res.rows) {
          _res?.rows.forEach((_record: any) => {
            rows.push(
              formatData([
                _record?.firstName,
                _record?.lastName,
                _record?.email,
                _record?.mobileNo,
                _record["leadLeadcampaign.leadcampaignCampaign.name"],
                _record["leadSupplier.name"],
                _record["leadAccountStatus.name"],
                _record["leadDispositionStatus.name"],
                _record["leadDncStatus.name"],
              ])
            );
            if(_record.id == currentLeadId) {
              setDataToEdit(_record);
            }
          });
        }
        setTableData(rows);
        setLoading(false);
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  const handleExportData = () => {
    setLoading(true); // Set Loading

    let payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      selectedFilters: selectedFilters,
      campaignId: selectedCampaignIds,
      supplierId: selectedSupplierIds,
      agentId: selectedAgentIds.map((item) => item.id),
      isAgentAssigned: selectedIsAgentAssigned,
    };

    // post filters
    post(moduleMain + "/export/accounts", payload)
      .then((_res: any) => {
        window.open(_res.url, "_blank");
        // @ts-ignore
        // notifications.current.successAlert('Records Rejected', 'Records Were Rejected');
        setLoading(false);
      })
      .catch((_err: any) => {
        log(_err);
        // @ts-ignore
        notifications.current.errorAlert(
          t("common.somethingWentWrong"),
          t("common.tryAgain")
        );
        setLoading(false); // loading is set false in fetch
      });
  };

  const assignAgent = () => {
    setLoading(true);
    //lead ids will be picked from the backend

    let payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      selectedFilters: selectedFilters,
      campaignId: selectedCampaignIds,
      supplierId: selectedSupplierIds,
      agentId: selectedAgentIds.map((item) => item.id),
      agentIdToAssign: selectAgentToAssign,
      userId: userData?.id,
      isAgentAssigned: tempAgentAssigned,
    };

    patch(moduleMain + "/bulk/assignAgent", "", payload)
      .then(async (_res) => {
        setSelectAgentToAssign(0);
        getLeadByFilter(null, tempAgentAssigned); // refresh data
        // @ts-ignore
        notifications.current.successAlert(
          "Agent Assigned Successfully",
          "Success"
        );
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
        // @ts-ignore
        notifications.current.errorAlert(
          t("common.somethingWentWrong"),
          t("common.tryAgain")
        );
      });
  };

  const handleClick = () => {
    setCurrentPage(0);
    setPageSize(25)
    getLeadByFilter();
  };

  useEffect(() => {
    fetchCampaign();
    fetchAllStatus();
  }, []);

  useEffect(() => {
    if (tableData.length > 0) {
      setLoading(false); // hide loader
    }
  }, [tableData]);

  useEffect(() => {
    setSelectedCampaignIds([CAMPAIGN_ID]);
  }, [CAMPAIGN_ID]);

  useEffect(() => {
    if (selectedAccountStatusIds.length === 0 || !window.location.href.includes("all_lead")) {
      setSelectedAccountStatusIds(accountStatusData);
    }

    if (userData?.roleId != 1) {
      setSelectedAgentIds([userData?.id]);
      getLeadByFilter(accountStatusData, "", [userData?.id]);
    } else {
      // setSelectedIsAgentAssigned("both");
      if (window.location.href.includes("all_lead")) {
        if (selectedAccountStatusIds.length === 0) {
          getLeadByFilter(accountStatusData, isAgentPlaceholder, []);
        } else {
          getLeadByFilter(selectedAccountStatusIds, isAgentPlaceholder, []);
        }
      } else {
        getLeadByFilter(accountStatusData, isAgentPlaceholder, []);
      }

    }

    accountStatusData.length > 1 ? setOnlyTable(false) : setOnlyTable(true);
  }, [currentPage, pageSize, accountStatusData]);

  // event handlers
  const onEditClick = (_dataIndex: any, _rowIndex: any) => {
    
    if (tabvalue === 0) {
      const dispositionstatusNew = allData.filter(
        (value, index) => allData[index].dispositionStatusId === 1
      );
      setCurrentLeadId(dispositionstatusNew[_dataIndex].id);
      setDataToEdit(dispositionstatusNew[_dataIndex]);

    } else {
      const dispositionstatusOthers = allData.filter(
        (value, index) => allData[index].dispositionStatusId !== 1
      );
      setCurrentLeadId(dispositionstatusOthers[_dataIndex].id);
      setDataToEdit(dispositionstatusOthers[_dataIndex]);
    }

    if (accountStatusData.length == 1) {
      setShowDataTable(false);
    }
    // setEditMode(true);
  };

  // event handlers
  const resetShowTable = () => {
    setShowDataTable(true);

    if (userData?.roleId != 1) {
      getLeadByFilter(accountStatusData, "", [userData?.id]);
    } else {
      getLeadByFilter();
    }
  };

  const handleChange = (index) => {
    const checks = [...checked];
    checks[index] = !checks[index];
    setChecked(checks);
    if (!checked[index]) {
      setCheckedBoxes((checkedBoxes) => [...checkedBoxes, index]);
    }
  };

  let options = {
    search: false,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: "textField",
    responsive: "scroll",
    rowsPerPage: pageSize,
    rowsPerPageOptions: [25, 50, 75, 100],
    selectableRowsHideCheckboxes: true,
    page: currentPage,
    count: totalRowCount,
    serverSide: true,
    loading: true,
    onChangePage: (currentPage) => {
      setCurrentPage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setPageSize(numberOfRows);
    },
    onDownload: () => {
      handleExportData();
      // return false to avoid datatable download
      return false;
    },
    sort: true,
    customRowRender: (data, dataIndex, rowIndex) => {
      // Your custom row rendering logic here
      return (
        <TableRow
          sx={{ cursor: "pointer" }}
          key={rowIndex}
          onClick={() => onEditClick(dataIndex, rowIndex)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked[dataIndex]}
                  onChange={(e) => handleChange(dataIndex)}
                  className="mt-2"
                />
              }
            />
          </Box>
          {data.map((item, colIndex) => {
            if (colIndex !== data.length - 2) {
              return <TableCell key={colIndex}>{item}</TableCell>;
            } else {
              return (
                <TableCell key={colIndex}>
                  <div className="d-inline-flex">
                    <div className="px-2">
                      <Tooltip title="Load">
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => onEditClick(dataIndex, rowIndex)}
                        >
                          <FontAwesomeIcon className="edit-btn" icon="fa-solid fa-upload" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </TableCell>
              );
            }
          })}
        </TableRow>
      );
    },
  };

  if (tableData.length === 0) {
    options = {
      textLabels: {
        body: {
          noMatch: loading ? (
            <Box>
              <Skeleton className="my-4" />
              <Skeleton className="my-4" />
              <Skeleton className="my-4" />
            </Box>
          ) : (
            "Sorry, there is no matching data to display"
          ),
        },
      },
    };
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* Header */}
      <Header />

      {/* Sider */}
      <Sider />

      {/* Content */}
      <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
        <div className="px-1 px-md-5">
          {/* Upper Section */}
          <div className="row">
            <div className="col-md-10">
              <h2 className="mt-3">{t("module.lead")}</h2>
            </div>
            <div className="col-md-2">
              {showDataTable && <LeadCreate refreshDataEvent={fetch} />}
              {!showDataTable && (
                <Button
                  onClick={resetShowTable}
                  variant="contained"
                  className="bg-custom-gradient my-2 py-3"
                  fullWidth
                >
                  Back
                </Button>
              )}
            </div>
          </div>

          {!loading && showDataTable && (
            <>
              {/* Filter row */}
              <div
                className={
                  (theme == "dark" ? "bg-dark" : "") +
                  "card shadow-lg ms-0 me-0 mt-4"
                }
              >
                <div className="card-body">
                  <div className="row mt-2">
                    <div className="col-9">
                      <h3>Filters</h3>
                    </div>
                    <div className="col-3">
                      <LoadingButton
                        variant="contained"
                        className={"full-width"}
                        size="small"
                        onClick={handleClick}
                        loading={loading}
                        loadingPosition="start"
                      >
                        <span> Apply Filters</span>
                      </LoadingButton>
                    </div>
                  </div>
                  <div className="row">
                    {/* Disposition Status */}
                    <div className="col-3 mt-5">
                      {/* Field dispositionStatusId */}
                      <FormControl fullWidth={true}>
                        <InputLabel id="dispositionStatusIdLabel">
                          Disposition Status
                        </InputLabel>
                        {allDispositionstatus.length > 0 && (
                          <Select
                            labelId="dispositionStatusIdLabel"
                            id="dispositionStatusIdSelect"
                            value={selectedDispositionStatusIds}
                            onChange={handleSelectDispositionChange}
                            multiple
                            label="Disposition Status"
                          >
                            {allDispositionstatus.map((el: any) => (
                              <MenuItem value={el.id} key={el.id}>
                                {" "}
                                {selectedDispositionStatusIds.includes(
                                  el.id
                                ) ? (
                                  <span>✔ {el.name}</span>
                                ) : (
                                  el.name
                                )}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        {allDispositionstatus.length == 0 && (
                          <>
                            <Select
                              labelId="dispositionStatusLabel"
                              disabled
                              id="dispositionStatusIdSelect"
                              label="Disposition Status"
                            >
                              <MenuItem value={""}>No Data</MenuItem>
                            </Select>
                            <FormHelperText>No Data</FormHelperText>
                          </>
                        )}
                      </FormControl>
                    </div>

                    {/* Sales Status / Account Status*/}
                    <div className="col-3 mt-5">
                      {/* Field accountStatusId */}
                      <FormControl fullWidth={true}>
                        <InputLabel id="accountStatusIdLabel">
                          Sales Status
                        </InputLabel>
                        {allAccountStatus.length > 0 && (
                          <Select
                            labelId="accountStatusIdLabel"
                            id="accountStatusIdSelect"
                            value={selectedAccountStatusIds}
                            onChange={handleSelectAccountChange}
                            multiple
                            label="Sales Status"
                          >
                            {allAccountStatus.map((el: any) => (
                              <MenuItem value={el.id} key={el.id}>
                                {" "}
                                {selectedAccountStatusIds.includes(el.id) ? (
                                  <span>✔ {el.name}</span>
                                ) : (
                                  el.name
                                )}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        {allAccountStatus.length == 0 && (
                          <>
                            <Select
                              labelId="accountLabel"
                              disabled
                              id="accountIdSelect"
                              label="Sales Status"
                            >
                              <MenuItem value={""}>No Data</MenuItem>
                            </Select>
                            <FormHelperText>No Data</FormHelperText>
                          </>
                        )}
                      </FormControl>
                    </div>

                    {/* DNC Status */}
                    <div className="col-3 mt-5">
                      <FormControl fullWidth={true}>
                        <InputLabel id="dncStatusIdLabel">
                          DNC Status
                        </InputLabel>
                        {allDncStatus.length > 0 && (
                          <Select
                            labelId="dncStatusIdLabel"
                            id="dncStatusIdSelect"
                            value={selectedDncStatusIds}
                            onChange={handleSelectDncChange}
                            multiple
                            label="DNC Status"
                          >
                            {allDncStatus.map((el: any) => (
                              <MenuItem value={el.id} key={el.id}>
                                {" "}
                                {selectedDncStatusIds.includes(el.id) ? (
                                  <span>✔ {el.name}</span>
                                ) : (
                                  el.name
                                )}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        {allDncStatus.length == 0 && (
                          <>
                            <Select
                              labelId="dncLabel"
                              disabled
                              id="dncIdSelect"
                              label="DNC Status"
                            >
                              <MenuItem value={""}>No Data</MenuItem>
                            </Select>
                            <FormHelperText>No Data</FormHelperText>
                          </>
                        )}
                      </FormControl>
                    </div>

                    {/* Campaign */}
                    <div className="col-3 mt-5">
                      {/* Field campaignId for other than agent */}
                      {(userData?.roleId != 3 && userData?.roleId != 4) && (
                        <FormControl fullWidth={true}>
                          <InputLabel id="campaignStatusIdLabel">
                            Select Campaign
                          </InputLabel>
                          {allCampaign.length > 0 && (
                            <Select
                              labelId="campaignStatusIdLabel"
                              id="campaignStatusIdSelect"
                              value={selectedCampaignIds}
                              onChange={handleSelectCampaignChange}
                              multiple
                              label="Select Campaign"
                            >
                              {allCampaign.map((el: any) => (
                                <MenuItem value={el.id} key={el.id}>
                                  {" "}
                                  {selectedCampaignIds.includes(el.id) ? (
                                    <span>✔ {el.name}</span>
                                  ) : (
                                    el.name
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                          {allCampaign.length == 0 && (
                            <>
                              <Select
                                labelId="campaignStatusLabel"
                                disabled
                                id="campaignStatusIdSelect"
                                label="Select Campaign"
                              >
                                <MenuItem value={""}>No Data</MenuItem>
                              </Select>
                              <FormHelperText>No Data</FormHelperText>
                            </>
                          )}
                        </FormControl>
                      )}

                      {/* campaignId for agent */}
                      {(userData?.roleId === 3 || userData?.roleId === 4) && (
                        <FormControl fullWidth={true}>
                          <InputLabel id="campaignStatusIdLabel">
                            Select Campaign
                          </InputLabel>
                          {allCampaign.length > 0 && (
                            <Select
                              labelId="campaignStatusIdLabel"
                              id="campaignStatusIdSelect"
                              value={selectedCampaignIds}
                              disabled
                              label="Select Campaign"
                            >
                              {allCampaign.map((el: any) => (
                                <MenuItem value={el.id} key={el.id}>
                                  {" "}
                                  {selectedCampaignIds.includes(el.id) ? (
                                    <span>✔ {el.name}</span>
                                  ) : (
                                    el.name
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </FormControl>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    {/* Supplier */}
                    <div className="col-3 mt-4">
                      <FormControl fullWidth={true}>
                        <InputLabel id="supplierIdLabel">Supplier</InputLabel>
                        {allSupplier.length > 0 && (
                          <Select
                            labelId="supplierIdLabel"
                            id="supplierIdSelect"
                            value={selectedSupplierIds}
                            onChange={handleSupplierChange}
                            multiple
                            label="Supplier"
                          >
                            {allSupplier.map((el: any) => (
                              <MenuItem value={el.id} key={el.id}>
                                {" "}
                                {selectedSupplierIds.includes(el.id) ? (
                                  <span>✔ {el.email}</span>
                                ) : (
                                  el.email
                                )}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        {allSupplier.length == 0 && (
                          <>
                            <Select
                              labelId="supplierIdLabel"
                              disabled
                              id="supplierIdSelect"
                              label="Supplier"
                            >
                              <MenuItem value={""}>No Data</MenuItem>
                            </Select>
                            <FormHelperText>No Data</FormHelperText>
                          </>
                        )}
                      </FormControl>
                    </div>

                    {/* First Name */}
                    <div className="col-3 mt-4">
                      <FormControl fullWidth>
                        <TextField
                          id="firstNameInput"
                          label="First Name"
                          type="text"
                          variant="outlined"
                          className="full-width"
                          value={firstName} // Set the value of the input field to the state variable
                          onChange={handleFirstNameChange}
                        />
                      </FormControl>
                    </div>
                    {/* Last Name */}
                    <div className="col-3 mt-4">
                      <FormControl fullWidth>
                        <TextField
                          id="lastNameInput"
                          label="Last Name"
                          type="text"
                          variant="outlined"
                          className="full-width"
                          value={lastName} // Set the value of the input field to the state variable
                          onChange={handleLastNameChange}
                        />
                      </FormControl>
                    </div>
                    {/* Email */}
                    <div className="col-3 mt-4">
                      <FormControl fullWidth>
                        <TextField
                          id="emailInput"
                          label="Email"
                          type="text"
                          variant="outlined"
                          className="full-width"
                          value={email} // Set the value of the input field to the state variable
                          onChange={handleEmailChange}
                        />
                      </FormControl>
                    </div>
                  </div>

                  {/* agent row for admin section */}
                  {userData?.roleId === 1 && (
                    <div className="row">
                      {/* Agent */}
                      <div className="col-6 mt-4">
                        <FormControl fullWidth={true}>
                          <>
                            <Autocomplete
                              multiple
                              id="agentIdAutoComplete"
                              disabled={
                                selectedIsAgentAssigned != "assigned" ||
                                userData?.roleId != 1
                              }
                              options={allAgent}
                              getOptionLabel={(option) => `${option.firstName || ''} ${option.lastName || ''}`.trim()}
                              value={selectedAgentIds}
                              onChange={handleAgentChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Agent Email"
                                />
                              )}
                            />
                          </>
                        </FormControl>
                      </div>
                      {/* is agent assignd */}
                      <div className="col-6 mt-4">
                        <FormControl fullWidth={true}>
                          <InputLabel id="isAgnetAssigedLabel">
                            Is Agent Assigned
                          </InputLabel>
                          <Select
                            labelId="isAgnetAssigedLabel"
                            id="isAgnetAssigedIdSelect"
                            value={selectedIsAgentAssigned}
                            onChange={handleIsAgentAssignedChange}
                            label="Is Agent Assigned"
                          >
                            <MenuItem value={"both"}>Both</MenuItem>
                            <MenuItem value={"assigned"}>Assigned</MenuItem>
                            <MenuItem value={"unassigned"}>Unassigned</MenuItem>
                          </Select>
                        </FormControl>
                        <Dialog
                          open={isAgentAssignedConfirmationModalOpen}
                          onClose={handleIsAgentAssignedNo}
                          aria-labelledby="account-title"
                          aria-describedby="account-description"
                        >
                          <DialogTitle id="account-title">
                            Confirm Change
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="account-description">
                              Are you sure you want to get {isAgentPlaceholder}{" "}
                              Leads?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleIsAgentAssignedNo}
                              color="primary"
                            >
                              No
                            </Button>
                            <Button
                              onClick={handleIsAgentAssignedYes}
                              color="primary"
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </div>
                  )}

                  {/* agent row for agent section */}
                  {userData?.roleId != 1 && (
                    <div className="row">
                      {/* Agent */}
                      <div className="col-12 mt-4">
                        <FormControl fullWidth={true}>
                          {allAgent.length > 0 && (
                            <>
                              <InputLabel id="isAgnetAssigedLabel">
                                Agent Email
                              </InputLabel>
                              <Select
                                labelId="agentIdAutoComplete"
                                id="agentIdAutoComplete"
                                value={selectedAgentIds}
                                disabled
                                label="Agent Email"
                              >
                                {allAgent.map((el: any) => (
                                  <MenuItem value={el.id} key={el.id}>
                                    {" "}
                                    {selectedAgentIds.includes(el.id) ? (
                                      <span>✔ {`${el.firstName ? el.firstName + ' ' : ''}${el.lastName ? el.lastName : ''}`}</span>
                                    ) : (
                                      el.email
                                    )}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          )}
                        </FormControl>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {loading && <Skeleton height={100} />}
          {userData?.roleId === 1 && (
            <div>
              {!loading && showDataTable && (
                <>
                  {/* Filter row */}
                  <div
                    className={
                      (theme == "dark" ? "bg-dark" : "") +
                      "card shadow-lg ms-0 me-0 mt-4"
                    }
                  >
                    <div className="card-body">
                      <div className="row mt-2">
                        <div className="col-12">
                          <h3>Bulk Assignment</h3>
                        </div>
                        <div className="col-12">
                          <div className="row mt-4">
                            <div className="col-8">
                              {/* select agent */}
                              <FormControl fullWidth={true}>
                                <InputLabel id="agentIdLabel">Agent</InputLabel>
                                {allAgent.length > 0 && (
                                  <Select
                                    labelId="agentIdLabel"
                                    id="agentIdSelect"
                                    disabled={selectedIsAgentAssigned == "both"}
                                    value={selectAgentToAssign}
                                    onChange={handleAgentToAssignChange}
                                    label="Agent"
                                  >
                                    {allAgent.map((el: any) => (
                                      <MenuItem value={el.id} key={el.id}>
                                        {" "}
                                        {`${el.firstName ? el.firstName + ' ' : ''}${el.lastName ? el.lastName : ''}`}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                                {allAgent.length == 0 && (
                                  <>
                                    <Select
                                      labelId="agentIdLabel"
                                      disabled
                                      id="agentIdSelect"
                                      label="Agent"
                                    >
                                      <MenuItem value={""}>No Data</MenuItem>
                                    </Select>
                                    <FormHelperText>No Data</FormHelperText>
                                  </>
                                )}
                              </FormControl>
                            </div>
                            <div className="col-4 p-0 m-0">
                              <Button
                                variant="contained"
                                disabled={selectAgentToAssign === 0}
                                onClick={assignAgent}
                                className={"bg-custom-gradient py-3"}
                                fullWidth
                              >
                                Assign Agent
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {loading && <Skeleton height={100} />}
            </div>
          )}
          {!loading && showDataTable && (
            <>
              <div className="col-12 mt-4">
                <div className="d-flex justify-content-end">
                  <div style={{ minWidth: "20em" }}>
                    <TextDisplay
                      isLoading={loading}
                      label={"Total Leads"}
                      data={totalRowCount || 0}
                      background={"dark"}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {loading && <Skeleton height={100} />}
          {!loading && showDataTable && (
            <div className="row">
              <div className="col-3 p-0 mt-5">
                <Button
                  variant="contained"
                  disabled={false}
                  onClick={deleteSelected}
                  className={"bg-custom-gradient py-3"}
                  fullWidth
                >
                  Delete Multiple
                </Button>
              </div>
              <div className={"col-6 mt-5"}>
                <Tabs value={tabvalue} onChange={handleChangeTab} centered>
                  <Tab label="New Disposition Status" />
                  <Tab label="Disposition Status Added" />
                </Tabs>
              </div>
            </div>
          )}
          {tabvalue === 0 &&
            showDataTable && (
              <div className="mt-4">
                <Paper elevation={12} style={{ width: "72em" }}>
                  <MUIDataTable
                    title={t("common.allRecords")}
                    data={tableData.filter(
                      (_value, index) => tableData[index]?.[7] === "New"
                    )}
                    columns={columns}
                    // @ts-ignore
                    options={options}
                  />
                </Paper>
              </div>
            )}
          {tabvalue === 1 &&
            showDataTable && (
              <div className="mt-4">
                <Paper elevation={12} style={{ width: "72em" }}>
                  <MUIDataTable
                    title={t("common.allRecords")}
                    data={tableData.filter(
                      (value, index) => tableData[index]?.[7] !== "New"
                    )}
                    columns={columns}
                    // @ts-ignore
                    options={options}
                  />
                </Paper>
              </div>
            )}

          {!loading && !showDataTable && (
            <div className="px-1 px-md-5">
              <LeadDetailView
                data={dataToEdit}
                refreshDataCallback={getLeadByFilter}
                allData={
                  allData !== undefined
                    ? tabvalue === 0
                      ? allData.filter(
                        (val, ind) => val.dispositionStatusId === 1
                      )
                      : allData.filter(
                        (val, ind) => val.dispositionStatusId !== 1
                      )
                    : ""
                }
              />
            </div>
          )}
        </div>

        {/* display edit modal */}
        {editMode && (
          <div>
            <LeadUpdate
              refreshDataEvent={fetch}
              resetEditState={resetEdit}
              recordToUpdate={dataToEdit}
            />
          </div>
        )}

        {/* Lead Details View */}
        {!onlyTable && accountStatusData.length > 1 && (
          <div className="px-1 px-md-5">
            <LeadDetailView
              data={dataToEdit}
              refreshDataCallback={getLeadByFilter}
              refreshFlag={true}
              allData={
                allData !== undefined
                  ? tabvalue === 0
                    ? allData.filter(
                      (val, ind) => val.dispositionStatusId === 1
                    )
                    : allData.filter(
                      (val, ind) => val.dispositionStatusId !== 1
                    )
                  : ""
              }
            />
          </div>
        )}

        {/* Alerts */}
        <AlertM ref={notifications} />
      </Box>
    </Box>
  );
}

export default LeadMain;
