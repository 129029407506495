// @ts-nocheck

import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom'
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { collapse } from "./Actions";
import { expand } from "../Header/Actions"
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import { getUserData } from "../../../Services/LocalStorageService";
import { accessGroups } from '../../../Routes/Guards/AccessGroups/AccessGroupsService'
// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

const drawerWidth = 240;

interface menuItem {
    title: '',
    url: '',
    icon: ''
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Sider() {
    // Set Theme
    const theme = useTheme();

    // Css for module
    const classes = useStyles();

    // Router object
    const navigate = useNavigate();

    // current location
    const location = useLocation();

    // Redux
    const sider = useSelector(
        (state: RootStateOrAny) => state.sider,
    );

    const header = useSelector(
        (state: RootStateOrAny) => state.header,
    );

    const activeTheme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // Dispatcher
    const dispatch = useDispatch()

    // UI vars
    const [open, setOpen] = React.useState(sider.state.isExpanded);

    // get user data
    const userData = getUserData();

    // Hooks
    useEffect(() => {
        setOpen(sider.state.isExpanded);
    }, [sider]);

    useEffect(() => {
        // setOpen(!header.state.isExpanded)
    }, [header]);


    // UI toggles
    const handleDrawerClose = () => {
        dispatch(collapse()); //sider state collapses
        dispatch(expand()); //header state expands
        // setOpen(false);
    };

    const navigateToComponent = (url) => {
        navigate(url)
    }


    // Menu Items
    const menuItems: any = 
    userData.roleId!==3?
    [
        {
            title: 'Reporting',
            url: '',
            icon: 'fa-chart-area',
            showDivider: true,
            isHeading: true,
            allowedRoles: accessGroups.routes.reporting
        },
        {
            title: 'Dashboard',
            url: '/dashboard',
            icon: 'chart-line',
            allowedRoles: accessGroups.routes.dashboard
        },
        {
            title: 'Search Account',
            url: '/search_account',
            icon: 'search',
            allowedRoles: accessGroups.routes.search_account
        },
        {
            title: 'Lead Management',
            url: '',
            icon: 'fa-chart-area',
            showDivider: true,
            isHeading: true,
            allowedRoles: accessGroups.routes.lead_management
        },
        {
            title: 'Import',
            url: '/bookimporter',
            icon: 'upload',
            allowedRoles: accessGroups.routes.bookimporter
        },
        {
            title: 'Bulk Assign Lead',
            url: '/bulk_assign_lead',
            icon: 'fa-solid fa-truck-ramp-box',
            allowedRoles: accessGroups.routes.bulk_assign_lead
        },
        {
            title: 'All Leads',
            url: '/all_lead',
            icon: 'list-check',
            allowedRoles: accessGroups.routes.all_lead
        },
        {
            title: 'Brochured Leads',
            url: '/brochured_lead',
            icon: 'list-check',
            allowedRoles: accessGroups.routes.brochured_lead
        },
        {
            title: '2nd Call',//name updated
            url: '/cif_inprogress_lead',
            icon: 'list-check',
            allowedRoles: accessGroups.routes.cif_inprogress_lead
        },
        {
            title: 'T-UP',//name updated
            url: '/cif_approval_lead',
            icon: 'list-check',
            allowedRoles: accessGroups.routes.cif_approval_lead
        },
        {
            title: 'Keep Updated',//name updated
            url: '/cif_completed_lead',
            icon: 'list-check',
            allowedRoles: accessGroups.routes.cif_completed_lead
        },
        {
            title: 'Client Leads',
            url: '/client_lead',
            icon: 'list-check',
            allowedRoles: accessGroups.routes.client_lead
        },
        {
            title: 'Dealt Leads',
            url: '/dealt_lead',
            icon: 'list-check',
            allowedRoles: accessGroups.routes.dealt_lead
        },
        {
            title: 'DNC Leads',
            url: '/dnc_lead',
            icon: 'list-check',
            allowedRoles: accessGroups.routes.dnc_lead
        },
        {
            title: 'Lead',
            url: '/lead',
            icon: 'list-check',
            allowedRoles: accessGroups.routes.lead
        },
        {
            title: 'Telemarketing',
            url: '/telemarketing',
            icon: 'list-check',
            allowedRoles: accessGroups.routes.telemarketing
        },
        {
            title: 'Sale Made Leads',
            url: '/sale_made_lead',
            icon: 'list-check',
            allowedRoles: accessGroups.routes.sale_made_lead
        },
        {
            title: 'Campaign',
            url: '/campaign',
            icon: 'book',
            allowedRoles: accessGroups.routes.campaign
        },
        {
            title: 'Supplier',
            url: '/supplier',
            icon: 'users-between-lines',
            allowedRoles: accessGroups.routes.supplier
        },
        // {
        //     title: 'Branch',
        //     url: '/branch',
        //     icon: 'building',
        //     allowedRoles: accessGroups.routes.branch
        // },
        // {
        //     title: 'Desk Type',
        //     url: '/desktype',
        //     icon: 'computer',
        //     allowedRoles: accessGroups.routes.desktype
        // },
        {
            title: 'Email Template',
            url: '/emailtemplate',
            icon: 'fa-solid fa-envelope',
            allowedRoles: accessGroups.routes.emailtemplate
        },
        {
            title: 'Letter Template',
            url: '/lettertemplate',
            icon: 'fa-solid fa-envelope-open-text',
            allowedRoles: accessGroups.routes.teams
        },
        {
            title: 'Print Letters',
            url: '/printletter',
            icon: 'fa-solid fa-print',
            allowedRoles: accessGroups.routes.print_letters
        },
        // {
        //     title: 'Media Management',
        //     url: '/mediamanagement',
        //     icon: 'fa-solid fa-file-import',
        //     allowedRoles: accessGroups.routes.mediamanagement
        // },
        {
            title: 'User Management',
            url: '',
            icon: 'fa-chart-area',
            showDivider: true,
            isHeading: true,
            allowedRoles: accessGroups.routes.user_management
        },
        {
            title: 'Mail Server',
            url: '/mail_server',
            icon: 'fa-solid fa-cloud',
            allowedRoles: accessGroups.routes.mail_server
        },
        {
            title: 'Roles',
            url: '/role',
            icon: 'user-lock',
            allowedRoles: accessGroups.routes.role
        },
        {
            title: 'Users',
            url: '/user',
            icon: 'users-gear',
            allowedRoles: accessGroups.routes.user
        },
        {
            title: 'Teams',
            url: '/teams',
            icon: 'people-group',
            allowedRoles: accessGroups.routes.teams
        },

        // {
        //     title: 'Account Status',
        //     url: '/accountstatus',
        //     icon: 'tag'
        // },
        // {
        //     title: 'Disposition Status',
        //     url: '/dispositionstatus',
        //     icon: 'headset'
        // },

        // {
        //     title: 'Notes',
        //     url: '/note',
        //     icon: 'note-sticky'
        // },
        {
            title: 'Logout',
            url: '/logout',
            icon: 'power-off',
            allowedRoles: accessGroups.routes.logout
        }
        // %TEMPLATE_MENU_ITEMS%

    ]: [
            {
                title: 'Reporting',
                url: '',
                icon: 'fa-chart-area',
                showDivider: true,
                isHeading: true,
                allowedRoles: accessGroups.routes.reporting
            },
            {
                title: 'Dashboard',
                url: '/dashboard',
                icon: 'chart-line',
                allowedRoles: accessGroups.routes.dashboard
            },
            {
                title: 'Search Account',
                url: '/search_account',
                icon: 'search',
                allowedRoles: accessGroups.routes.search_account
            },
            {
                title: 'Lead Management',
                url: '',
                icon: 'fa-chart-area',
                showDivider: true,
                isHeading: true,
                allowedRoles: accessGroups.routes.lead_management
            },
            {
                title: 'Import',
                url: '/bookimporter',
                icon: 'upload',
                allowedRoles: accessGroups.routes.bookimporter
            },
            {
                title: 'Bulk Assign Lead',
                url: '/bulk_assign_lead',
                icon: 'fa-solid fa-truck-ramp-box',
                allowedRoles: accessGroups.routes.bulk_assign_lead
            },
            {
                title: 'All Leads',
                url: '/all_lead',
                icon: 'list-check',
                allowedRoles: accessGroups.routes.all_lead
            },
            {
                title: 'Lead',
                url: '/lead',
                icon: 'list-check',
                allowedRoles: accessGroups.routes.lead
            },
            {
                title: 'Brochured Leads',
                url: '/brochured_lead',
                icon: 'list-check',
                allowedRoles: accessGroups.routes.brochured_lead
            },
            {
                title: '2nd Call',//name updated
                url: '/cif_inprogress_lead',
                icon: 'list-check',
                allowedRoles: accessGroups.routes.cif_inprogress_lead
            },
            {
                title: 'T-UP',//name updated
                url: '/cif_approval_lead',
                icon: 'list-check',
                allowedRoles: accessGroups.routes.cif_approval_lead
            },
            {
                title: 'Dealt Leads',
                url: '/dealt_lead',
                icon: 'list-check',
                allowedRoles: accessGroups.routes.dealt_lead
            },
            {
                title: 'Client Leads',
                url: '/client_lead',
                icon: 'list-check',
                allowedRoles: accessGroups.routes.client_lead
            },
            {
                title: 'Keep Updated',//name updated
                url: '/cif_completed_lead',
                icon: 'list-check',
                allowedRoles: accessGroups.routes.cif_completed_lead
            },
            {
                title: 'DNC Leads',
                url: '/dnc_lead',
                icon: 'list-check',
                allowedRoles: accessGroups.routes.dnc_lead
            },
            {
                title: 'Telemarketing',
                url: '/telemarketing',
                icon: 'list-check',
                allowedRoles: accessGroups.routes.telemarketing
            },
            {
                title: 'Sale Made Leads',
                url: '/sale_made_lead',
                icon: 'list-check',
                allowedRoles: accessGroups.routes.sale_made_lead
            },
            {
                title: 'Campaign',
                url: '/campaign',
                icon: 'book',
                allowedRoles: accessGroups.routes.campaign
            },
            {
                title: 'Supplier',
                url: '/supplier',
                icon: 'users-between-lines',
                allowedRoles: accessGroups.routes.supplier
            },
            // {
            //     title: 'Branch',
            //     url: '/branch',
            //     icon: 'building',
            //     allowedRoles: accessGroups.routes.branch
            // },
            // {
            //     title: 'Desk Type',
            //     url: '/desktype',
            //     icon: 'computer',
            //     allowedRoles: accessGroups.routes.desktype
            // },
            {
                title: 'Email Template',
                url: '/emailtemplate',
                icon: 'fa-solid fa-envelope',
                allowedRoles: accessGroups.routes.emailtemplate
            },
            {
                title: 'Letter Template',
                url: '/lettertemplate',
                icon: 'fa-solid fa-envelope-open-text',
                allowedRoles: accessGroups.routes.teams
            },
            {
                title: 'Print Letters',
                url: '/printletter',
                icon: 'fa-solid fa-print',
                allowedRoles: accessGroups.routes.print_letters
            },
            // {
            //     title: 'Media Management',
            //     url: '/mediamanagement',
            //     icon: 'fa-solid fa-file-import',
            //     allowedRoles: accessGroups.routes.mediamanagement
            // },
            {
                title: 'User Management',
                url: '',
                icon: 'fa-chart-area',
                showDivider: true,
                isHeading: true,
                allowedRoles: accessGroups.routes.user_management
            },
            {
                title: 'Mail Server',
                url: '/mail_server',
                icon: 'fa-solid fa-cloud',
                allowedRoles: accessGroups.routes.mail_server
            },
            {
                title: 'Roles',
                url: '/role',
                icon: 'user-lock',
                allowedRoles: accessGroups.routes.role
            },
            {
                title: 'Users',
                url: '/user',
                icon: 'users-gear',
                allowedRoles: accessGroups.routes.user
            },
            {
                title: 'Teams',
                url: '/teams',
                icon: 'people-group',
                allowedRoles: accessGroups.routes.teams
            },
    
            // {
            //     title: 'Account Status',
            //     url: '/accountstatus',
            //     icon: 'tag'
            // },
            // {
            //     title: 'Disposition Status',
            //     url: '/dispositionstatus',
            //     icon: 'headset'
            // },
    
            // {
            //     title: 'Notes',
            //     url: '/note',
            //     icon: 'note-sticky'
            // },
            {
                title: 'Logout',
                url: '/logout',
                icon: 'exclamation-triangle',
                allowedRoles: accessGroups.routes.logout
            }
            // %TEMPLATE_MENU_ITEMS%
    
        ];

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>

                {activeTheme == 'dark' &&
                    <img className="img-fluid mx-auto" style={{ maxWidth: '150px' }}
                        src={require('../../../Assets/img/logo/logo_white.png')} />
                }

                {activeTheme == 'light' &&
                    <img className="img-fluid mx-auto" style={{ maxWidth: '150px' }}
                        src={require('../../../Assets/img/logo/logo.png')} />
                }

                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                {menuItems.map((item: any, index: any) => (
                    item.allowedRoles.includes(userData?.roleId) ?
                    <>
                        {/* If menu item */}
                        {!item.isHeading &&
                            <Tooltip title={item.title} placement="right">
                                <ListItemButton
                                    className={location.pathname == item.url ? 'bg-custom-dark-faded' : ''}
                                    onClick={() => navigateToComponent(item.url)}
                                    key={item.title}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={item.icon} />

                                    </ListItemIcon>
                                    <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </Tooltip>
                        }
                        {/* If Heading */}
                        {item.isHeading &&
                            <ListItemButton
                                className={'bg-custom-secondary-faded '}
                                key={item.title}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon icon={item.icon} />

                                </ListItemIcon>
                                <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        }
                        {item.showDivider &&
                            <Divider />
                        }
                    </>: <></>
                ))}

            </List>
        </Drawer>
    );
}

export default Sider;
