// @ts-nocheck

import React, { forwardRef, useRef, useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useFileUpload from "react-use-file-upload";
import Button from "@mui/material/Button";
import {
  getAll,
  post,
  postFormData,
} from "../../../Services/GenericApiService";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { log } from "../../../Services/LoggerService";
import { FormHelperText } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import AlertM from "../../Helpers/AlertM/AlertM";

// var XLSX = require("xlsx");

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
}

// @ts-ignore
const AccountFileUploadM = forwardRef((props, ref) => {
  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const inputRef = useRef();

  // child ref for alert
  const notifications = useRef();

  // get theme from redux
  const theme = useSelector(
    (state: RootStateOrAny) => state.global.global.theme
  );

  // get hardcoded template variables from env
  const allowedFileTypes = ["csv"];

  // data vars
  const [allBookFieldsData, setAllBookFieldsData] = React.useState([]);
  const [accountFile, setAccountFile] = React.useState();
  const [importResponse, setImportResponse] = React.useState({});
  const [pageTitle, setPageTitle] = React.useState("");
  const [allCampaigns, setAllCampaigns] = React.useState([]);
  const [allSuppliers, setAllSuppliers] = React.useState([]);
  const [allAccountStatus, setAllAccountStatus] = React.useState([]);
  const [selectedCampaignIds, setSelectedCampaignIds] = React.useState([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState({});
  const [selectedAccountStatusIds, setSelectedAccountStatusIds] = React.useState([]);

  // ui vars
  const [isMapperUIVisible, setIsMapperUIVisible] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isImported, setIsImported] = React.useState(false);

  // module(s) for api
  const moduleLead = "lead";
  // const moduleLeadBookUpload = 'debtordetail/upload/book';
  // const moduleProcessBook = 'debtordetail';
  // const moduleBookTemplate = 'booktemplate'
  const moduleCampaigns = "campaign";
  const moduleSupplier = "supplier";
  const moduleAccountstatus = "accountstatus";

  const forceUpdate = useForceUpdate();

  // fetch dependent data
  const fetch = () => {
    fetchCampaigns();
    fetchSuppliers();
    fetchAccountstatus();
  };

  const fetchCampaigns = () => {
    getAll(moduleCampaigns)
      .then((_res: any) => {
        setAllCampaigns(_res);
      })
      .catch((_err) => {
        log(_err);
      });
  };

  const fetchSuppliers = () => {
    getAll(moduleSupplier)
      .then((_res: any) => {
        setAllSuppliers(_res);
      })
      .catch((_err) => {
        log(_err);
      });
  };

  const fetchAccountstatus = () => {
    getAll(moduleAccountstatus)
      .then((_res: any) => {
        setAllAccountStatus(_res);
      })
      .catch((_err) => {
        log(_err);
      });
  };

  const handleSelectCampaignChange = (event) => {
    setSelectedCampaignIds(event.target.value);
  };

  const handleSelectAccountChange = (event) => {
    setSelectedAccountStatusIds(event.target.value);
};

  useEffect(() => {
    fetch();

    // check upload type
    const fileImportType = props.importType; // use pre termination import as default

    switch (fileImportType) {
      case "lead":
        setPageTitle("Lead Book Import");
        break;
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // set loader
    setIsUploading(true);

    const formData = createFormData();

    var bodyFormData = new FormData();
    bodyFormData.append("file", accountFile, accountFile.name);

    postFormData(moduleLead + "/upload/lead/lead_account_book", bodyFormData)
      .then((_res) => {
        // set loader
        setIsUploading(false);

        // set processing loader
        setIsProcessing(true);

        let payload = {
          campaignId: selectedCampaignIds,
          supplierId: selectedSupplier.id,
          leadStatusId: selectedAccountStatusIds,
        };

        post(moduleLead + "/import/leadaccount", payload)
          .then((_resChain) => {
            // set processing loader
            setIsProcessing(false);

            // set is imported ui
            setIsImported(true);

            // store response results
            setImportResponse(_resChain);

            // @ts-ignore
            notifications.current.successAlert(
              "File Import Completed",
              "Account were imported successfully"
            );
          })
          .catch((_err) => {
            // set loader
            setIsProcessing(false);

            log(_err);
            // @ts-ignore
            notifications.current.errorAlert(
              t("common.somethingWentWrong"),
              t("common.tryAgain")
            );
          });
      })
      .catch((_err) => {
        // set loader
        setIsUploading(false);

        // set loader
        setIsProcessing(false);

        log(_err);
        // @ts-ignore
        notifications.current.errorAlert(
          t("common.somethingWentWrong"),
          t("common.tryAgain")
        );
      });
  };

  const onFileUpload = (_file, _event) => {
    // check if allowed file type
    if (allowedFileTypes.includes(_file.name.split(".")[1].toLowerCase())) {
      setAccountFile(_file);
      setFiles(_event, "a");
      setIsLoading(false);
    } else {
      notifications.current.errorAlert(
        "Upload Failed",
        "Only csv file types are allowed"
      );
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div
        className={
          (theme == "dark" ? "bg-dark" : "") +
          " container card shadow-lg py-4 px-3"
        }
      >
        <div className="px-3 pb-3">
          <h2 className={" mb-5"}>{pageTitle}</h2>

          {fileNames.length > 0 && (
            <div className={""}>
              {/* File Info */}
              <div className="alert alert-secondary">
                <div className="row">
                  {/* File Name */}
                  <div className="col-12 col-md-12 mb-1">
                    <p className={"text-muted p-0 m-0"}>File Name</p>
                    <h5>
                      {isLoading && (
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      )}
                      {!isLoading && fileNames[0]}
                    </h5>
                  </div>

                  {/* Action Buttons */}
                  {!isImported && (
                    <div className={"row"}>
                      <div className="col-md-4 col-6 mt-3">
                        {/*<BookfieldCreate refreshDataEvent={fetch} isParentNotMain={true}/>*/}
                      </div>
                      <div className="col-md-4 col-6 mt-3">
                        <Button
                          variant="outlined"
                          className={"full-width my-2 py-3"}
                          onClick={() => clearAllFiles()}
                        >
                          Clear
                        </Button>
                      </div>
                      <div className="col-md-4 col-6 mt-3">
                        <Button
                          onClick={handleSubmit}
                          className={"full-width bg-custom-gradient my-2 py-3"}
                          variant="contained"
                        >
                          Import
                        </Button>
                      </div>
                    </div>
                  )}

                  {/*  Import Stats  */}
                  {isImported && (
                    <div className={"row"}>
                      <div className="col-md-4 col-6 mt-3">
                        <p className={"text-muted p-0 m-0"}>Records Imported</p>
                        <h5>{importResponse.success}</h5>
                      </div>
                      <div className="col-md-4 col-6 mt-3">
                        <p className={"text-muted p-0 m-0"}>Records Failed</p>
                        <h5>
                          {parseInt(importResponse.failed)
                            ? importResponse.failed
                            : 0}
                        </h5>
                      </div>
                      <div className="col-md-4 col-6 mt-3">
                        <p className={"text-muted p-0 m-0"}>Duplicate Record</p>
                        <h5>
                          {parseInt(importResponse.totalDuplicate)
                            ? importResponse.totalDuplicate
                            : 0}
                        </h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {fileNames.length == 0 && (
            <div className={"row"}>
              {/* Template Selector */}
              <div className="col-md-8">
                {props.importType == "lead" && (
                  <div className={"row"}>
                    <div className="col-md-4">
                      <FormControl fullWidth={true}>
                        <InputLabel id="bookTemplateIdLabel">
                          Select Campaign
                        </InputLabel>
                        {allCampaigns.length > 0 && (
                          <Select
                            labelId="campaignIdLabel"
                            id="campaignIdSelect"
                            value={selectedCampaignIds}
                            onChange={handleSelectCampaignChange}
                            multiple
                            label="campaignStatusId"
                          >
                            {allCampaigns.map((el: any) => (
                              <MenuItem value={el.id} key={el.id}>
                                {" "}
                                {el.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        {allCampaigns.length == 0 && (
                          <>
                            <Select
                              labelId="bookTemplateIdLabel"
                              disabled
                              id="campaignIdLabel"
                              label="campaignIdSelect"
                            >
                              <MenuItem value={""}>No Data</MenuItem>
                            </Select>
                            <FormHelperText>No Data</FormHelperText>
                          </>
                        )}
                      </FormControl>
                    </div>

                    <div className="col-md-4">
                      <FormControl fullWidth={true}>
                        <InputLabel id="supplierIdLabel">
                          Select Supplier
                        </InputLabel>
                        {allSuppliers.length > 0 && (
                          <Select
                            labelId="supplierIdLabel"
                            id="supplierIdSelect"
                            onChange={(event: any) => {
                              // get supplier obj
                              const selectedItem = allSuppliers.filter(
                                (_supplier) =>
                                  _supplier.id == event.target.value
                              )[0];
                              setSelectedSupplier(selectedItem);
                            }}
                            value={selectedSupplier?.id}
                            label="Select Supplier"
                          >
                            {allSuppliers.map((el: any) => (
                              <MenuItem value={el.id} key={el.id}>
                                {" "}
                                {el.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        {allSuppliers.length == 0 && (
                          <>
                            <Select disabled>
                              <MenuItem value={""}>No Data</MenuItem>
                            </Select>
                            <FormHelperText>No Data</FormHelperText>
                          </>
                        )}
                      </FormControl>
                    </div>

                    <div className="col-md-4">
                      {/* Field accountStatusId */}
                      <FormControl fullWidth={true}>
                        <InputLabel id="accountStatusIdLabel">
                          Select Lead Status
                        </InputLabel>
                        {allAccountStatus.length > 0 && (
                          <Select
                            labelId="accountStatusIdLabel"
                            id="accountStatusIdSelect"
                            value={selectedAccountStatusIds}
                            onChange={handleSelectAccountChange}
                            multiple
                            label="Sales Status"
                          >
                            {allAccountStatus.map((el: any) => (
                              <MenuItem value={el.id} key={el.id}>
                                {" "}
                                {el.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        {allAccountStatus.length == 0 && (
                          <>
                            <Select
                              labelId="accountLabel"
                              disabled
                              id="accountIdSelect"
                              label="Sales Status"
                            >
                              <MenuItem value={""}>No Data</MenuItem>
                            </Select>
                            <FormHelperText>No Data</FormHelperText>
                          </>
                        )}
                      </FormControl>
                    </div>
                  </div>
                )}
              </div>

              {/* File Selector */}
              <div className="col-md-4">
                {props.importType == "lead" &&
                  Object.keys(selectedCampaignIds).length > 0 &&
                  Object.keys(selectedSupplier).length > 0 && 
                  Object.keys(selectedAccountStatusIds).length > 0 &&  (
                    <Button
                      onClick={() => inputRef.current.click()}
                      fullWidth
                      className={"py-3"}
                      variant="outlined"
                    >
                      select account file to upload
                    </Button>
                  )}

                {/* Hide the crappy looking default HTML input */}
                <input
                  ref={inputRef}
                  type="file"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    onFileUpload(e.target.files[0], e);
                    inputRef.current.value = null;
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploading || isProcessing}
      >
        <div className={"d-block text-center"}>
          <CircularProgress color="inherit" />

          {isUploading && <h5>Uploading Book</h5>}

          {isProcessing && <h5>Processing Book</h5>}
        </div>
      </Backdrop>

      {/* Alerts */}
      <AlertM ref={notifications} />
    </div>
  );
});

export default AccountFileUploadM;
