import { retrieveJWT } from "./LocalStorageService";

const axios = require('axios');

const generateHeaders = () => {
    const JWT = retrieveJWT();
    return {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Authorization": `Bearer ${JWT}`
        }
    }
}

const generateFormDataHeaders = () => {
    const JWT = retrieveJWT();
    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            "Authorization": `Bearer ${JWT}`
        }
    }
}

export async function post(_module: string, _data: any, page=0, size=0) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios.post(url + _module + '?page=' + page + '&size=' + size, _data, generateHeaders());
    return response.data;
}

export async function getAll(_module: string, page = 0, size = 0) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    let response;
    // check if with or without pagination
    if (size !== 0) {
        response = await axios.get(url + _module + '?page=' + page + '&size=' + size, generateHeaders());
    } else {
        response = await axios.get(url + _module, generateHeaders());
    }
    return response.data;
}

export async function postFormData(_module: string, _data: any) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios({
        method: "post",
        url: url + _module,
        data: _data,
        headers: generateFormDataHeaders(),
    });
    return response.data;
}

export async function getById(_module: string, _id: string) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios.get(url + _module + '/' + _id, generateHeaders());
    return response.data;
}

export async function patch(_module: string, _id: string, _data: any) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios.patch(url + _module + '/' + _id, _data, generateHeaders());
    return response.data;
}

export async function deleteById(_module: string, _id: string) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios.delete(url + _module + '/soft/' + _id, generateHeaders());
    return response.data;
}

export async function deleteMultiple(_module: string, ids) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios.delete(url + _module + '/multipleDeleteSoft/', generateHeaders(), { data: {
        ids: ids
    }});
    return response.data;
}

export async function deleteByIdHard(_module: string, _id: string) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios.delete(url + _module + '/hard/' + _id, generateHeaders());
    return response.data;
}