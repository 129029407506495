const user = {
    admin: 1,
    manager: 2,
    agent: 3,
    telemarketer: 4,
    printer: 5,
}

export const accessGroups: any = {
    routes: {
        dashboard: [user.admin, user.manager,user.agent, user.telemarketer],  //main heading which is disabled
        role: [user.admin, user.manager],  //main heading which is disabled
        user: [user.admin, user.manager],  //main heading which is disabled
        teams: [user.admin, user.manager],
        print_letters: [user.admin, user.manager, user.printer],
        supplier: [user.admin, user.manager],
        search_account: [user.admin, user.manager,user.agent, user.telemarketer],
        bookimporter: [user.admin, user.manager],
        bulk_assign_lead: [user.admin],
        all_lead: [user.admin, user.manager],
        telemarketing: [user.agent, user.telemarketer],
        lead: [user.agent, user.telemarketer],
        brochured_lead: [user.agent],
        cif_inprogress_lead: [user.agent],
        cif_approval_lead: [user.agent],
        cif_completed_lead: [user.agent],
        dealt_lead: [user.agent],
        client_lead: [user.agent],
        sale_made_lead: [user.agent],
        dnc_lead: [user.admin],// removed user.agent
        campaign: [user.admin, user.manager],
        branch: [user.admin, user.manager],
        desktype: [user.admin, user.manager],
        emailtemplate: [user.admin, user.manager],
        mediamanagement: [user.admin, user.manager],
        reporting: [user.admin, user.manager],
        lead_management: [user.admin, user.manager, user.agent],
        user_management: [user.admin],
        logout: [user.admin, user.manager, user.agent,user.telemarketer, user.printer],
        mail_server: [user.admin],
        lettertemplate: [user.admin, user.manager, user.agent]
    }
}
