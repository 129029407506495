// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { RootStateOrAny, useSelector } from "react-redux";
import { getById, post } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../Services/LoggerService";

import { useForm } from "react-hook-form";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Button, Card, CardContent } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import FileUploadM from '../../../../Helpers/FileUploadM/FileUploadM';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function MediamanagementMain() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    const inputRef = useRef();

    // module(s) for api
    const moduleLead = 'lead';

    // data vars
    const [allData, setAllData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);


    // table ui controls
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [showUpload, setShowUpload] = useState(false);
    const [uploadFieldName, setUploadFieldName] = useState('');
    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // Redux
    const uploadedFiles = useSelector(
        (state: RootStateOrAny) => state.global.global.formData,
    );

    // form methods
    // const onSubmit = () => {
    //     uploadFiles();
    // }

    const showFileUpload = (fieldName: any) => {
        setShowUpload(true);
        setUploadFieldName(fieldName)
    }

    const viewDocument = (fieldName) => {
        getById(moduleLead + '/media/' + moduleLead, fieldName)
            .then(_res => {
                let url = process.env.REACT_APP_SERVER_ENDPOINT + 'public/' + _res.basePath + '/' + _res.files[0];
                // Open the URL in a new tab
                window.open(url, '_blank');
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }
    // fetch dependent data
    const fetch = () => {

    }

    const uploadFiles = () => {
        // create a new formdata object because createFormData doesnt works apparently
        var bodyFormData = new FormData();

        Object.keys(uploadedFiles).forEach(_key => {
            bodyFormData.append('file', uploadedFiles[_key], uploadedFiles[_key].name);
        })

        post(moduleLead + '/upload/' + moduleLead + '/'+ uploadFieldName, bodyFormData)
            .then(_res => {
                setShowUpload(false);
                setUploadFieldName('');
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }


    useEffect(() => {
        fetch();
    }, [currentPage, pageSize])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                                Media Management
                            </h2>
                        </div>
                    </div>

                    {/* <div className="row mt-5">
                        <AccountFileUploadM importType={'brochure'}/>
                    </div> */}

                    {/* Brochure Media Section */}
                    <Card sx={{ maxHeight: '90vh', overflowY: 'auto', marginTop: '4em' }} >
                        <CardContent>
                            <div className="row ps-4">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <h2>Brochure</h2>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-6">
                                            {!showUpload &&
                                                <Button
                                                    onClick={() => showFileUpload('brochure')}
                                                    variant="contained"
                                                    className={'py-3'}
                                                    fullWidth>
                                                    Add/Replace
                                                </Button>
                                            }
                                            {showUpload &&
                                               <FileUploadM />
                                            }
                                        </div>
                                        <div className="col-6">
                                            {!showUpload &&
                                                <Button
                                                    onClick={() => viewDocument('brochure')}
                                                    variant="contained"
                                                    className={'py-3'}
                                                    fullWidth>
                                                    View Document
                                                </Button>
                                            }
                                            {showUpload &&
                                                <Button
                                                    onClick={uploadFiles}
                                                    variant="contained"
                                                    className={'py-3'}
                                                    fullWidth>
                                                    Upload
                                                </Button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>


                </div>

                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box>
    );
}

export default MediamanagementMain;
