// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getAll, getById, post, patch, deleteById } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../Services/LoggerService";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { Button, IconButton, Skeleton, FormControl, TextField, FormHelperText } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationDialog from "../../../../Helpers/ConfirmationDialog/ConfirmationDialog";
import MailServerUpdate from '../MailServerUpdate/MailServerUpdate';
import MailServerCreate from '../MailServerCreate/MailServerCreate';
import MailServerView from '../MailServerView/MailServerView';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function MailServerMain() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'mailserver';

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [dataToEdit, setDataToEdit] = React.useState({});
    const [tableData, setTableData] = React.useState([]);
    const [allActiveEmail, setAllActiveEmail] = React.useState([]);
    const [selectedFromEmail, setSelectedFromEmail] = useState('');
    const [toEmail, setToEmail] = useState('');

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [editMode, setEditMode] = React.useState(false);

    // table ui controls
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const handleSelectFromEmailChange = (event) => {
        setSelectedFromEmail(event.target.value);
    };

    const handleToEmailChange = (event) => {
        setToEmail(event.target.value);
    };

    // fetch dependent data
    const fetch = () => {
        setTableData([]);  // reset table data
        setLoading(true); // show loader
        getAll(moduleMain, currentPage, pageSize)
            .then((_res: any) => {
                // set all data
                setAllData(_res.rows)

                // set total count
                setTotalRowCount(_res.count);

                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.host, _record?.port, _record?.email, _record?.isSSLTLS, _record?.isActive]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    //get all agent
    const getAllActiveEmail = () => {
        setLoading(true); // show loader
        getAll(moduleMain + '/active/all')
            .then((_res: any) => {
                setAllActiveEmail(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    //send test email
    const sendTestEmail = () => {
        const emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
        setLoading(true); // show loader
        if (toEmail.match(emailPattern)) {
            let payload = {
                toEmail: toEmail,
                fromEmail: selectedFromEmail
            }
            post(moduleMain + '/test', payload)
                .then(_res => {
                    setLoading(false); // hide loader
                    // @ts-ignore
                    notifications.current.successAlert('Mail Sent Successfully', 'Success');
                })
                .catch(_err => {
                    log(_err)
                    setLoading(false); // hide loader
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                })
        } else {
            setLoading(false);
            // @ts-ignore
            notifications.current.errorAlert('Incorrect Email', 'Please enter the correct email');
        }

    }


    useEffect(() => {
        fetch();
        getAllActiveEmail();
    }, [currentPage, pageSize])

    // event handlers
    const onEditClick = (_dataIndex: any, _rowIndex: any) => {
        setDataToEdit(allData[_dataIndex]);
        setEditMode(true);
    }

    const resetEdit = (_state) => {
        setEditMode(false);
    }

    const handleDelete = (_dataItemIndex: number) => {
        setLoading(true);   // show spinner
        deleteById(moduleMain, allData[_dataItemIndex].id)
            .then(async (_res) => {
                setLoading(false);  // hide loader
                // @ts-ignore
                notifications.current.successAlert(t('common.deleteSuccess'), '');
                fetch();    // refresh data
            })
            .catch(_err => {
                log(_err)
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false); // hide loader
            })
    }

    let columns = [
        { name: "Host", options: { filterOptions: { fullWidth: true } } },
        { name: "Port", options: { filterOptions: { fullWidth: true } } },
        { name: "Email", options: { filterOptions: { fullWidth: true } } },
        { name: "Is SSL/TLS", options: { filterOptions: { fullWidth: true } } },
        { name: "Active", options: { filterOptions: { fullWidth: true } } },

        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => { return { align: "right" } },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                <Tooltip title={t('common.edit')}>
                                    <IconButton aria-label="delete" size="small" onClick={() => {
                                        onEditClick(dataIndex, rowIndex)
                                    }}>
                                        <FontAwesomeIcon className="edit-btn" icon='edit' />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <div className="px-2">
                                {/*  delete confirmation dialog  */}
                                <ConfirmationDialog title={'Delete Confirmation'}
                                    body={'Are you sure you want to delete this record?'}
                                    type={'delete'}
                                    isLoading={loading}
                                    dataItemIdx={dataIndex}
                                    confirmEvent={handleDelete} />
                            </div>

                            <div className="px-2">
                                {allData &&
                                    <MailServerView data={allData[rowIndex]} />
                                }
                            </div>

                        </div>
                    )
                        ;
                }
            }
        }
    ];


    let options = {
        search: false,
        download: false,
        print: false,
        viewColumns: true,
        filter: false,
        filterType: "textField",
        responsive: "simple",
        // tableBodyHeight: "500px",
        // tableBodyMaxHeight,
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => { setCurrentPage(currentPage); },
        onChangeRowsPerPage: (numberOfRows) => { setPageSize(numberOfRows); },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
            // console.log(action);
            // console.dir(state);
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                                Mail Server
                            </h2>
                        </div>
                        <div className="col-md-2">
                            <MailServerCreate refreshDataEvent={fetch} />
                        </div>
                    </div>

                    {/* Test Email row */}
                    <div className={(theme == 'dark' ? 'bg-dark' : '') + "card shadow-lg ms-0 me-0 mt-5"}>
                        <div className="card-body">
                            <div className="row mt-2">
                                <div className="col-12">
                                    <h3>
                                        Test Server
                                    </h3>
                                </div>
                                <div className="row mt-3">
                                    {/* to email */}
                                    <div className="col-4 pt-2">
                                        {!loading &&
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="toEmailInput"
                                                    label="To Email"
                                                    type="email"
                                                    variant="outlined"
                                                    className="full-width"
                                                    value={toEmail}
                                                    onChange={handleToEmailChange} />
                                            </FormControl>
                                        }

                                        {loading &&
                                            <Skeleton variant="text" height={60} />
                                        }
                                    </div>

                                    {/* from email */}
                                    <div className="col-4 pt-2">
                                        {!loading &&

                                            <FormControl fullWidth={true}>
                                                <InputLabel id="fromEmailLabel">
                                                    From Email
                                                </InputLabel>
                                                {allActiveEmail.length > 0 &&
                                                    <Select labelId="fromEmailLabel"
                                                        id="fromEmailSelect"
                                                        value={selectedFromEmail}
                                                        onChange={handleSelectFromEmailChange}
                                                        label="fromEmailLabel">
                                                        {
                                                            allActiveEmail.map((el: any) =>
                                                                <MenuItem value={el.email}
                                                                    key={el.email}> {el.email}
                                                                </MenuItem>)
                                                        }
                                                    </Select>
                                                }
                                                {allActiveEmail.length == 0 &&
                                                    <>
                                                        <Select labelId="fromEmailLabel"
                                                            disabled
                                                            id="fromEmailSelect"
                                                            label="fromEmailLabel">
                                                            <MenuItem value={''}>
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                }
                                            </FormControl>
                                        }
                                        {loading &&
                                            <Skeleton variant="text" height={60} />
                                        }

                                    </div>
                                    {/* send button */}
                                    <div className="col-4">
                                        {!loading && selectedFromEmail != '' && toEmail != '' &&
                                            <ConfirmationDialog title={'Test Email'}
                                                body={'Are you sure you want to send test email?'}
                                                type={'confirm'}
                                                buttonText={'Send Email'}
                                                showButton={true}
                                                isLoading={loading}
                                                dataItemIdx={allActiveEmail}
                                                confirmEvent={sendTestEmail} />

                                        }
                                        {!loading && (selectedFromEmail == '' || toEmail == '') &&
                                            <Button
                                                className='py-3 mt-2'
                                                disabled={selectedFromEmail == '' || toEmail == ''}
                                                variant="contained"
                                                fullWidth>
                                                Send Email
                                            </Button>
                                        }
                                        {loading &&
                                            <Skeleton variant="text" className="mt-2" height={60} />
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <Paper elevation={12}>
                            <MUIDataTable
                                title={t('common.allRecords')}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>

                </div>

                {/* display edit modal */}
                {editMode &&
                    <div>
                        <MailServerUpdate refreshDataEvent={fetch}
                            resetEditState={resetEdit}
                            recordToUpdate={dataToEdit} />
                    </div>
                }


                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box>
    );
}

export default MailServerMain;
