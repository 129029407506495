import { Navigate, Outlet } from 'react-router-dom';
import {getLoggedInUserRole, isLoggedIn} from "../../../Services/LocalStorageService";
import { accessGroups } from '../AccessGroups/AccessGroupsService';

export function AuthGuard({ route = '' }) {
    const auth = isLoggedIn(); // determine if authorized, from context or however you're doing it
    let role = false;

    if (!auth) return <Navigate to="/login" />

    if (route == '') return <Outlet />
    // checks if requesting user has access to requested route
    role = accessGroups.routes[route].includes(getLoggedInUserRole())
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? <Outlet /> : <Navigate to="/login" />;
}