// @ts-nocheck

import React, { useEffect, useState, useRef } from "react";
import "../../../../../index.scss";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getUserData } from "../../../../../Services/LocalStorageService";
import { getAll, patch } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import { log } from "../../../../../Services/LoggerService";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import {
    Skeleton,
    FormControl,
    FormHelperText,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { RootStateOrAny, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

// font awesome stuff
const iconList = Object.keys(Icons)
    .filter((key) => key !== "fas" && key !== "prefix")
    .map((icon) => Icons[icon]);

library.add(...iconList);

function LeadBulkAssign({ accountStatusData }) {
    // css for module

    // translation
    const { t } = useTranslation();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme
    );


    // get user data
    const userData = getUserData();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = "lead";
    const moduleCampaign = "campaign";

    // data vars
    const [allData, setAllData] = useState([]);
    const [leadRange, setLeadRange] = useState(0);
    const [allAccountStatus, setAllAccountStatus] = React.useState([]);
    const [allDispositionstatus, setAllDispositionstatus] = React.useState([]);
    const [allDncStatus, setAllDncStatus] = React.useState([]);
    const [allCampaign, setAllCampaign] = React.useState([]);
    const [allSupplier, setAllSupplier] = React.useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [selectedAccountStatusIds, setSelectedAccountStatusIds] = useState(
        [1]
    );
    const [selectedAgentTelemarketerIds, setSelectedAgentTelemarketerIds] =
        React.useState([]);
    const [selectedDispositionStatusIds, setSelectedDispositionStatusIds] =
        useState([1]);
    const [selectedDncStatusIds, setSelectedDncStatusIds] = useState([1]);
    const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
    const [selectedSupplierIds, setSelectedSupplierIds] = useState([]);
    const [selectedIsAgentAssigned, setSelectedIsAgentAssigned] =
        useState("both");
    const [isAgentPlaceholder, setIsAgentPlaceholder] = useState("both");
    const [tempAgentAssigned, setTempAgentAssigned] = useState("both");
    const [
        isAgentAssignedConfirmationModalOpen,
        setIsAgentAssignedConfirmationModalOpen,
    ] = useState(false);

    let columns = [
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Last Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Email", options: { filterOptions: { fullWidth: true } } },
        { name: "Contact", options: { filterOptions: { fullWidth: true } } },
        {
            name: "Agent/Telemarketers",
            options: { filterOptions: { fullWidth: true } },
        },
        { name: "Campaign", options: { filterOptions: { fullWidth: true } } },
        { name: "Supplier", options: { filterOptions: { fullWidth: true } } },
        {
            name: "Sales Status",
            options: { filterOptions: { fullWidth: true } },
        },
        {
            name: "Disposition Status",
            options: { filterOptions: { fullWidth: true } },
        },
        { name: "DNC Status", options: { filterOptions: { fullWidth: true } } },
    ];

    const [tableData, setTableData] = useState([]);
    const [paginationFlag, setPaginationFlag] = useState(false);
    const [allAgent, setAllAgent] = useState([]);
    const [selectedAgentId, setSelectedAgentId] = useState(null);

    // ui controls
    const [loading, setLoading] = useState(true);
    const [selectedLeadId, setSelectedLeadId] = useState([]);

    // table ui controls
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleAgentChange = (event) => {
        setSelectedAgentId(event.target.value);
    };

    const handleRangeChange = (event) => {
        setLeadRange(event.target.value);
    };

    const refreshDataEvent = () => {
        getAllUnassignedLead();
    };
    const handleSelectDispositionChange = (event) => {
        let selectedValues = event.target.value;
        setSelectedDispositionStatusIds(selectedValues);
    };

    const handleSelectAccountChange = (event) => {
        const selectedValues = event.target.value;
        setSelectedAccountStatusIds(selectedValues);
    };

    const handleSelectDncChange = (event) => {
        const selectedValues = event.target.value;
        setSelectedDncStatusIds(selectedValues);
    };

    const handleSupplierChange = (event) => {
        setSelectedSupplierIds(event.target.value);
    };

    const handleSelectCampaignChange = (event) => {
        setSelectedCampaignIds(event.target.value);
    };

    const handleIsAgentAssignedChange = (event) => {
        const newValue = event.target.value;
        setIsAgentPlaceholder(newValue);
        if (newValue !== selectedIsAgentAssigned) {
            setTempAgentAssigned(event.target.value);
            // Display the confirmation modal
            setIsAgentAssignedConfirmationModalOpen(true);
        }
    };

    const handleIsAgentAssignedNo = () => {
        setIsAgentAssignedConfirmationModalOpen(false);
    };

    const handleIsAgentAssignedYes = () => {
        setSelectedAgentTelemarketerIds([]);
        setCurrentPage(0);
        setPageSize(25);
        getAllUnassignedLead();
        setIsAgentAssignedConfirmationModalOpen(false);
    };

    const handleAgentOrTelemarketerChange = (event) => {
        const selectedIds = event.target.value; // This will be an array of selected values
        setSelectedAgentTelemarketerIds(selectedIds);
    };

    useEffect(() => {
        setSelectedIsAgentAssigned(tempAgentAssigned);
    }, [handleIsAgentAssignedYes]);

    const fetchData = () => {
        setLoading(true);
        getAll("dashboard")
            .then((_res) => {
                setAllDispositionstatus(_res.dispositionStatus);
                setAllAccountStatus(_res.accountStatus);
                setAllAgent(_res.agent);
                setAllDncStatus(_res.dncStatus);
                setAllSupplier(_res.supplier);
                setLoading(false); // hide loader
            })
            .catch((_err) => {
                console.log(_err);
                setLoading(false); // hide loader
            });
    };

    // fetch Campaign
    const fetchCampaign = () => {
        setLoading(true); // show loader
        getAll(moduleCampaign)
            .then((_res: any) => {
                setAllCampaign(_res);
                setLoading(false); // hide loader
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    const handleClick = () => {
        setCurrentPage(0);
        setPageSize(25);
        getAllUnassignedLead();
    };

    const assignAgent = () => {
        setLoading(true);

        if (selectedLeadId.length === 0 && leadRange > 0) {
            let payload = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                accountStatusId: selectedAccountStatusIds,
                dispositionStatusId: selectedDispositionStatusIds,
                dncStatusId: selectedDncStatusIds,
                campaignId: selectedCampaignIds,
                supplierId: selectedSupplierIds,
                agentIdToAssign: selectedAgentId,
                userId: userData?.id,
                isAgentAssigned: tempAgentAssigned,
                leadId: selectedLeadId,
                leadRange: leadRange,
                agentTelemarketersId: selectedAgentTelemarketerIds,
            };
            patch(moduleMain + "/bulk/assignAgent/nofilter", "", payload)
                .then(async (_res) => {
                    setLoading(false); // hide loader
                    refreshDataEvent(); // refresh data
                    // @ts-ignore
                    notifications.current.successAlert(
                        "Agent Assigned Successfully",
                        _res.message
                    );
                })
                .catch((_err) => {
                    log(_err);
                    setLoading(false); // hide loader
                    // @ts-ignore
                    notifications.current.errorAlert(
                        t("common.somethingWentWrong"),
                        t("common.tryAgain")
                    );
                });
        } else if (selectedLeadId.length > 0) {
            let payload = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                accountStatusId: selectedAccountStatusIds,
                dispositionStatusId: selectedDispositionStatusIds,
                dncStatusId: selectedDncStatusIds,
                campaignId: selectedCampaignIds,
                supplierId: selectedSupplierIds,
                agentIdToAssign: selectedAgentId,
                userId: userData?.id,
                isAgentAssigned: tempAgentAssigned,
                leadId: selectedLeadId,
                leadRange: 0,
            };
            patch(moduleMain + "/bulk/assignAgent/nofilter", "", payload)
                .then(async (_res) => {
                    setLoading(false); // hide loader
                    refreshDataEvent(); // refresh data
                    // @ts-ignore
                    notifications.current.successAlert(
                        "Agent Assigned Successfully",
                        "Success"
                    );
                })
                .catch((_err) => {
                    log(_err);
                    setLoading(false); // hide loader
                    // @ts-ignore
                    notifications.current.errorAlert(
                        t("common.somethingWentWrong"),
                        t("common.tryAgain")
                    );
                });
        } else {
            notifications.current.errorAlert(
                "Please Select Leads",
                "Currently no lead selected"
            );
            setLoading(false);
        }
    };

    const getAllUnassignedLead = (agentId = userData.id) => {
        setTableData([]); // reset table data
        setLoading(true); // show loader

        let statusFilter = selectedAccountStatusIds;
        let agentOrTelemarketersId = [];

        if (selectedAgentTelemarketerIds.length > 0) {
            agentOrTelemarketersId = selectedAgentTelemarketerIds;
        }

        getAll(
            `${moduleMain}/agent/unassigned?page=${currentPage}&size=${pageSize}&status=${statusFilter}&supplierId=${selectedSupplierIds}&agentOrTelemarketersId=${agentOrTelemarketersId}&dispositionStatusId=${selectedDispositionStatusIds}&dncStatusId=${selectedDncStatusIds}&firstName=${firstName}&lastName=${lastName}&email=${email}&campaignId=${selectedCampaignIds}&agentId=${agentId}&isAgentAssigned=${selectedIsAgentAssigned}`
        )
            .then((_res: any) => {
                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(
                        formatData([
                            _record?.firstName,
                            _record?.lastName,
                            _record?.email,
                            _record?.mobileNo,
                            _record["leadLeadUser.leadUserUser.email"],
                            _record[
                            "leadLeadcampaign.leadcampaignCampaign.name"
                            ],
                            _record["leadSupplier.name"],
                            _record["leadAccountStatus.name"],
                            _record["leadDispositionStatus.name"],
                            _record["leadDncStatus.name"],
                        ])
                    );
                });

                setAllData(_res?.rows);
                setTotalRowCount(_res?.count);
                setPaginationFlag(true);
                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
            });
    };

    const renderSelectedValues = (selected) => {
        const selectedEmails = selected.map((id) => {
            const supplier = allSupplier.find((el) => el.id === id);
            return supplier ? supplier.email : '';
        });

        if (selectedEmails.length <= 4) {
            return selectedEmails.join(', ');
        }
        return `${selectedEmails.slice(0, 4).join(', ')} and ${selectedEmails.length - 4} more`;
    };

    useEffect(() => {
        fetchCampaign();
        fetchData();
    }, []);

    useEffect(() => {
        setSelectedAccountStatusIds(accountStatusData);
        if (userData?.roleId != 1) {
            setSelectedAgentTelemarketerIds([userData?.id]);
            getAllUnassignedLead([userData?.id]);
        } else {
            setSelectedIsAgentAssigned("both");
            getAllUnassignedLead();
        }
    }, [currentPage, pageSize]);

    let options: any = {
        search: false,
        download: false,
        print: false,
        viewColumns: true,
        filter: false,
        filterType: "textField",
        selectableRows: "multiple",
        responsive: "simple",
        rowsPerPage: pageSize,
        rowsPerPageOptions: [25, 50, 75, 100],
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
        },
        onRowsSelect: (currentRowsSelected) => {
            // Handle selection
            currentRowsSelected.forEach((item) => {
                const dataIndex = item.dataIndex;
                if (selectedLeadId.includes(allData[dataIndex].id)) {
                    let temp = selectedLeadId;
                    temp.splice(temp.indexOf(allData[dataIndex].id), 1);
                    setSelectedLeadId(temp);
                } else {
                    let temp = selectedLeadId;
                    temp.push(allData[dataIndex].id);
                    setSelectedLeadId(temp);
                }
            });
        },
        sort: true,
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box>
                ) : (
                    "Sorry, there is no matching data to display"
                ),
            },
        },
        onTableChange: () => {
            // console.log(action);
            // console.dir(state);
        },
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ? (
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box>
                    ) : (
                        "Sorry, there is no matching data to display"
                    ),
                },
            },
            count: 0
        };
    }
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">
                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">Bulk Assignment</h2>
                        </div>
                    </div>

                    <div
                        className={
                            (theme == "dark" ? "bg-dark" : "") +
                            "card shadow-lg ms-0 me-0 mt-4"
                        }
                    >
                        <div className="card-body">
                            <div className="row mt-2">
                                <div className="col-9">
                                    <h3>Filters</h3>
                                </div>
                                <div className="col-3">
                                    <LoadingButton
                                        variant="contained"
                                        className={"full-width"}
                                        size="small"
                                        onClick={handleClick}
                                        loading={loading}
                                        loadingPosition="start"
                                    >
                                        <span> Apply Filters</span>
                                    </LoadingButton>
                                </div>
                            </div>
                            <div className="row">
                                {/* Disposition Status */}
                                <div className="col-3 mt-5">
                                    {/* Field dispositionStatusId */}
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="dispositionStatusIdLabel">
                                            Disposition Status
                                        </InputLabel>
                                        {allDispositionstatus.length > 0 && (
                                            <Select
                                                labelId="dispositionStatusIdLabel"
                                                id="dispositionStatusIdSelect"
                                                value={
                                                    selectedDispositionStatusIds
                                                }
                                                onChange={
                                                    handleSelectDispositionChange
                                                }
                                                multiple
                                                label="Disposition Status"
                                            >
                                                {allDispositionstatus.map(
                                                    (el: any) => (
                                                        <MenuItem
                                                            value={el.id}
                                                            key={el.id}
                                                        >
                                                            {" "}
                                                            {selectedDispositionStatusIds.includes(
                                                                el.id
                                                            ) ? (
                                                                <span>
                                                                    ✔ {el.name}
                                                                </span>
                                                            ) : (
                                                                el.name
                                                            )}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                        {allDispositionstatus.length == 0 && (
                                            <>
                                                <Select
                                                    labelId="dispositionStatusLabel"
                                                    disabled
                                                    id="dispositionStatusIdSelect"
                                                    label="Disposition Status"
                                                >
                                                    <MenuItem value={""}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        )}
                                    </FormControl>
                                </div>

                                {/* Sales Status / Account Status*/}
                                <div className="col-3 mt-5">
                                    {/* Field accountStatusId */}
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="accountStatusIdLabel">
                                            Sales Status
                                        </InputLabel>
                                        {allAccountStatus.length > 0 && (
                                            <Select
                                                labelId="accountStatusIdLabel"
                                                id="accountStatusIdSelect"
                                                value={selectedAccountStatusIds}
                                                onChange={
                                                    handleSelectAccountChange
                                                }
                                                multiple
                                                label="Sales Status"
                                            >
                                                {allAccountStatus.map(
                                                    (el: any) => (
                                                        <MenuItem
                                                            value={el.id}
                                                            key={el.id}
                                                        >
                                                            {" "}
                                                            {selectedAccountStatusIds.includes(
                                                                el.id
                                                            ) ? (
                                                                <span>
                                                                    ✔ {el.name}
                                                                </span>
                                                            ) : (
                                                                el.name
                                                            )}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                        {allAccountStatus.length == 0 && (
                                            <>
                                                <Select
                                                    labelId="accountLabel"
                                                    disabled
                                                    id="accountIdSelect"
                                                    label="Sales Status"
                                                >
                                                    <MenuItem value={""}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        )}
                                    </FormControl>
                                </div>

                                {/* DNC Status */}
                                <div className="col-3 mt-5">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="dncStatusIdLabel">
                                            DNC Status
                                        </InputLabel>
                                        {allDncStatus.length > 0 && (
                                            <Select
                                                labelId="dncStatusIdLabel"
                                                id="dncStatusIdSelect"
                                                value={selectedDncStatusIds}
                                                onChange={handleSelectDncChange}
                                                multiple
                                                label="DNC Status"
                                            >
                                                {allDncStatus.map((el: any) => (
                                                    <MenuItem
                                                        value={el.id}
                                                        key={el.id}
                                                    >
                                                        {" "}
                                                        {selectedDncStatusIds.includes(
                                                            el.id
                                                        ) ? (
                                                            <span>
                                                                ✔ {el.name}
                                                            </span>
                                                        ) : (
                                                            el.name
                                                        )}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                        {allDncStatus.length == 0 && (
                                            <>
                                                <Select
                                                    labelId="dncLabel"
                                                    disabled
                                                    id="dncIdSelect"
                                                    label="DNC Status"
                                                >
                                                    <MenuItem value={""}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        )}
                                    </FormControl>
                                </div>

                                {/* Campaign */}
                                <div className="col-3 mt-5">
                                    {/* Field campaignId for other than agent */}
                                    {userData?.roleId != 3 &&
                                        userData?.roleId != 4 && (
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="campaignStatusIdLabel">
                                                    Select Campaign
                                                </InputLabel>
                                                {allCampaign.length > 0 && (
                                                    <Select
                                                        labelId="campaignStatusIdLabel"
                                                        id="campaignStatusIdSelect"
                                                        value={
                                                            selectedCampaignIds
                                                        }
                                                        onChange={
                                                            handleSelectCampaignChange
                                                        }
                                                        multiple
                                                        label="Select Campaign"
                                                    >
                                                        {allCampaign.map(
                                                            (el: any) => (
                                                                <MenuItem
                                                                    value={
                                                                        el.id
                                                                    }
                                                                    key={el.id}
                                                                >
                                                                    {" "}
                                                                    {selectedCampaignIds.includes(
                                                                        el.id
                                                                    ) ? (
                                                                        <span>
                                                                            ✔{" "}
                                                                            {
                                                                                el.name
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        el.name
                                                                    )}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                )}
                                                {allCampaign.length == 0 && (
                                                    <>
                                                        <Select
                                                            labelId="campaignStatusLabel"
                                                            disabled
                                                            id="campaignStatusIdSelect"
                                                            label="Select Campaign"
                                                        >
                                                            <MenuItem
                                                                value={""}
                                                            >
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                )}
                                            </FormControl>
                                        )}

                                    {/* campaignId for agent */}
                                    {(userData?.roleId === 3 ||
                                        userData?.roleId === 4) && (
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="campaignStatusIdLabel">
                                                    Select Campaign
                                                </InputLabel>
                                                {allCampaign.length > 0 && (
                                                    <Select
                                                        labelId="campaignStatusIdLabel"
                                                        id="campaignStatusIdSelect"
                                                        value={selectedCampaignIds}
                                                        disabled
                                                        label="Select Campaign"
                                                    >
                                                        {allCampaign.map(
                                                            (el: any) => (
                                                                <MenuItem
                                                                    value={el.id}
                                                                    key={el.id}
                                                                >
                                                                    {" "}
                                                                    {selectedCampaignIds.includes(
                                                                        el.id
                                                                    ) ? (
                                                                        <span>
                                                                            ✔{" "}
                                                                            {
                                                                                el.name
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        el.name
                                                                    )}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                )}
                                            </FormControl>
                                        )}
                                </div>
                            </div>

                            <div className="row">
                                {/* Supplier */}
                                <div className="col-3 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="supplierIdLabel">
                                            Supplier
                                        </InputLabel>
                                        {allSupplier.length > 0 && (
                                            <Select
                                                labelId="supplierIdLabel"
                                                id="supplierIdSelect"
                                                value={selectedSupplierIds}
                                                onChange={handleSupplierChange}
                                                multiple
                                                renderValue={(selected) => renderSelectedValues(selected)}
                                                label="Supplier"
                                            >
                                                {allSupplier.map((el: any) => (
                                                    <MenuItem
                                                        value={el.id}
                                                        key={el.id}
                                                    >
                                                        {" "}
                                                        {selectedSupplierIds.includes(
                                                            el.id
                                                        ) ? (
                                                            <span>
                                                                ✔ {el.email}
                                                            </span>
                                                        ) : (
                                                            el.email
                                                        )}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                        {allSupplier.length == 0 && (
                                            <>
                                                <Select
                                                    labelId="supplierIdLabel"
                                                    disabled
                                                    id="supplierIdSelect"
                                                    label="Supplier"
                                                >
                                                    <MenuItem value={""}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        )}
                                    </FormControl>
                                </div>
                                {/* First Name */}
                                <div className="col-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField
                                            id="firstNameInput"
                                            label="First Name"
                                            type="text"
                                            variant="outlined"
                                            className="full-width"
                                            value={firstName} // Set the value of the input field to the state variable
                                            onChange={handleFirstNameChange}
                                        />
                                    </FormControl>
                                </div>
                                {/* Last Name */}
                                <div className="col-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField
                                            id="lastNameInput"
                                            label="Last Name"
                                            type="text"
                                            variant="outlined"
                                            className="full-width"
                                            value={lastName} // Set the value of the input field to the state variable
                                            onChange={handleLastNameChange}
                                        />
                                    </FormControl>
                                </div>
                                {/* Email */}
                                <div className="col-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField
                                            id="emailInput"
                                            label="Email"
                                            type="text"
                                            variant="outlined"
                                            className="full-width"
                                            value={email} // Set the value of the input field to the state variable
                                            onChange={handleEmailChange}
                                        />
                                    </FormControl>
                                </div>
                            </div>

                            {userData?.roleId === 1 && (
                                <div className="row">
                                    <div className="col-6 mt-4">
                                        {/* Agent */}
                                        <FormControl fullWidth={true}>
                                            <InputLabel id="agentTelemerkertIdLabel">
                                                Agent or Telemarketers
                                            </InputLabel>
                                            {allAgent.length > 0 ? (
                                                <Select
                                                    labelId="agentTelemerkertIdLabel"
                                                    id="agentTelemerkertIdLabel"
                                                    value={
                                                        selectedAgentTelemarketerIds
                                                    }
                                                    onChange={
                                                        handleAgentOrTelemarketerChange
                                                    }
                                                    multiple
                                                    label="Agent or Telemarketers"
                                                    disabled={
                                                        selectedIsAgentAssigned !=
                                                        "assigned" ||
                                                        userData?.roleId != 1
                                                    }
                                                >
                                                    {allAgent.map((el) => (
                                                        <MenuItem
                                                            value={el.id}
                                                            key={el.id}
                                                        >
                                                            {`${el.firstName ? el.firstName + ' ' : ''}${el.lastName ? el.lastName : ''}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <>
                                                    <Select
                                                        labelId="agentIdLabel"
                                                        disabled
                                                        id="agentIdSelect"
                                                        label="agentId"
                                                    >
                                                        <MenuItem value={""}>
                                                            No Data
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        No Data
                                                    </FormHelperText>
                                                </>
                                            )}
                                        </FormControl>
                                    </div>
                                    <div className="col-6 mt-4">
                                        <FormControl fullWidth={true}>
                                            <InputLabel id="isAgnetAssigedLabel">
                                                Is Agent Assigned
                                            </InputLabel>
                                            <Select
                                                labelId="isAgnetAssigedLabel"
                                                id="isAgnetAssigedIdSelect"
                                                value={selectedIsAgentAssigned}
                                                onChange={
                                                    handleIsAgentAssignedChange
                                                }
                                                label="Is Agent Assigned"
                                            >
                                                <MenuItem value={"both"}>
                                                    Both
                                                </MenuItem>
                                                <MenuItem value={"assigned"}>
                                                    Assigned
                                                </MenuItem>
                                                <MenuItem value={"unassigned"}>
                                                    Unassigned
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Dialog
                                            open={
                                                isAgentAssignedConfirmationModalOpen
                                            }
                                            onClose={handleIsAgentAssignedNo}
                                            aria-labelledby="account-title"
                                            aria-describedby="account-description"
                                        >
                                            <DialogTitle id="account-title">
                                                Confirm Change
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="account-description">
                                                    Are you sure you want to get{" "}
                                                    {isAgentPlaceholder} Leads?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={
                                                        handleIsAgentAssignedNo
                                                    }
                                                    color="primary"
                                                >
                                                    No
                                                </Button>
                                                <Button
                                                    onClick={
                                                        handleIsAgentAssignedYes
                                                    }
                                                    color="primary"
                                                >
                                                    Yes
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                </div>
                            )}

                            {userData?.roleId != 1 && (
                                <div className="col-6 mt-4">
                                    {/* Agent */}
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="agentTelemerkertIdLabel">
                                            Agent or Telemarketers
                                        </InputLabel>
                                        {allAgent.length > 0 ? (
                                            <Select
                                                labelId="agentTelemerkertIdLabel"
                                                id="agentTelemerkertIdLabel"
                                                value={
                                                    selectedAgentTelemarketerIds
                                                }
                                                onChange={
                                                    handleAgentOrTelemarketerChange
                                                }
                                                multiple
                                                label="Agent or Telemarketers"
                                                disabled={
                                                    selectedIsAgentAssigned !=
                                                    "assigned" ||
                                                    userData?.roleId != 1
                                                }
                                            >
                                                {allAgent.map((el) => (
                                                    <MenuItem
                                                        value={el.id}
                                                        key={el.id}
                                                    >
                                                        {`${el.firstName ? el.firstName + ' ' : ''}${el.lastName ? el.lastName : ''}`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        ) : (
                                            <>
                                                <Select
                                                    labelId="agentIdLabel"
                                                    disabled
                                                    id="agentIdSelect"
                                                    label="agentId"
                                                >
                                                    <MenuItem value={""}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        )}
                                    </FormControl>
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        className={
                            (theme == "dark" ? "bg-dark" : "") +
                            "card shadow-lg ms-0 me-0 mt-4"
                        }
                    >
                        <div className="card-body">
                            <div className="row mt-2">
                                <div className="col-4">
                                    {/* select agent */}
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="agentIdLabel">
                                            Agent
                                        </InputLabel>
                                        {allAgent.length > 0 && (
                                            <Select
                                                labelId="agentIdLabel"
                                                id="agentIdSelect"
                                                value={selectedAgentId}
                                                onChange={handleAgentChange}
                                                label="agent"
                                            >
                                                {allAgent.map((el: any) => (
                                                    <MenuItem
                                                        value={el.id}
                                                        key={el.id}
                                                    >
                                                        {" "}
                                                        {`${el.firstName ? el.firstName + ' ' : ''}${el.lastName ? el.lastName : ''}`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                        {allAgent.length == 0 && (
                                            <>
                                                <Select
                                                    labelId="agentIdLabel"
                                                    disabled
                                                    id="agentIdSelect"
                                                    label="agentId"
                                                >
                                                    <MenuItem value={""}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        )}
                                    </FormControl>
                                </div>

                                {/* Range */}
                                <div className="col-4">
                                    <FormControl fullWidth>
                                        <TextField
                                            id="rangeInput"
                                            label={"Number of leads to assign"}
                                            type={"number"}
                                            value={leadRange}
                                            onChange={handleRangeChange}
                                            variant="outlined"
                                            className="full-width"
                                        />
                                    </FormControl>
                                </div>

                                <div className="col-4">
                                    {/* Assign Agent Button */}
                                    {!loading && (
                                        <Button
                                            variant="contained"
                                            disabled={selectedAgentId === null}
                                            onClick={assignAgent}
                                            className={
                                                "bg-custom-gradient py-3"
                                            }
                                            fullWidth
                                        >
                                            Assign Agent
                                        </Button>
                                    )}
                                    {loading && <Skeleton height={50} />}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <Paper elevation={12}>
                            <MUIDataTable
                                title={t("common.allRecords")}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>
                </div>
                {/* Alerts */}
                <AlertM ref={notifications} />
            </Box>
        </Box>
    );
}

export default LeadBulkAssign;
