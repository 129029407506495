import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { post, getAll } from "../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import { Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from '@mui/lab';
import FileUploadM from "../../../../Helpers/FileUploadM/FileUploadM";


// @ts-ignore
function LeadForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent, displayInfo = "" }) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'lead';

    // linked components API modules (endpoints)
    const moduleCampaign = 'campaign';
    const moduleSupplier = 'supplier';
    const moduleAccountstatus = 'accountstatus';
    const moduledispositionStatus = 'dispositionstatus';


    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            console.log(_data);
            handleEditEvent(_data);
        }
    }

    // data vars


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allCampaign, setAllCampaign] = React.useState([]);
    const [allSupplier, setAllSupplier] = React.useState([]);
    const [allAccountstatus, setAllAccountstatus] = React.useState([]);
    const [alldispositionStatus, setAlldispositionStatus] = React.useState([]);



    const fetch = () => {
        fetchCampaign();
        fetchSupplier();
        fetchAccountstatus();
        fetchdispositionStatus();

    }

    // fetch Campaign
    const fetchCampaign = () => {
        setLoading(true); // show loader
        getAll(moduleCampaign)
            .then((_res: any) => {
                setAllCampaign(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Supplier
    const fetchSupplier = () => {
        setLoading(true); // show loader
        getAll(moduleSupplier)
            .then((_res: any) => {
                setAllSupplier(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Accountstatus
    const fetchAccountstatus = () => {
        setLoading(true); // show loader
        getAll(moduleAccountstatus)
            .then((_res: any) => {
                setAllAccountstatus(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch dispositionStatus
    const fetchdispositionStatus = () => {
        setLoading(true); // show loader
        getAll(moduledispositionStatus)
            .then((_res: any) => {
                setAlldispositionStatus(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }



    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);


        }
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.lead')}
                                    </h3>

                                </div>

                                {displayInfo === 'info' &&
                                    <div className='row'>
                                        {/* Field email */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="emailInput"
                                                    {...register("email", { required: false })}
                                                    label={t('lead.email')}
                                                    type={"email"}
                                                    error={!!errors.email}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field contact */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="mobileNo"
                                                    {...register("mobileNo", { required: false })}
                                                    label={'Mobile No'}
                                                    type={"number"}
                                                    error={!!errors.mobileNo}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field work tel */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="officeTel"
                                                    {...register("officeTel", { required: false })}
                                                    label={'Work Tel'}
                                                    type={"number"}
                                                    error={!!errors.officeTel}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                    </div>
                                }

                                {displayInfo != 'info' &&
                                    <div className='row'>

                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="title"
                                                    {...register("title", { required: false })}
                                                    label={'Title'}
                                                    type={"text"}
                                                    error={!!errors.title}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                         <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="firstName"
                                                    {...register("firstName", { required: false })}
                                                    label={'First Name'}
                                                    type={"text"}
                                                    error={!!errors.firstName}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="lastName"
                                                    {...register("lastName", { required: false })}
                                                    label={'Last Name'}
                                                    type={"text"}
                                                    error={!!errors.lastName}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="mobileNo"
                                                    {...register("mobileNo", { required: false })}
                                                    label={'Mobile No'}
                                                    type={"number"}
                                                    error={!!errors.mobileNo}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="officeTel"
                                                    {...register("officeTel", { required: false })}
                                                    label={'Work Tel'}
                                                    type={"number"}
                                                    error={!!errors.officeTel}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="emailInput"
                                                    {...register("email", { required: false })}
                                                    label={t('lead.email')}
                                                    type={"email"}
                                                    error={!!errors.email}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* address */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="address"
                                                    {...register("address1", { required: false })}
                                                    label={'Address 1'}
                                                    type={"text"}
                                                    error={!!errors.address}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="address2"
                                                    {...register("address2", { required: false })}
                                                    label={'Address 2'}
                                                    type={"text"}
                                                    error={!!errors.address2}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="address3"
                                                    {...register("address3", { required: false })}
                                                    label={'Address 3'}
                                                    type={"text"}
                                                    error={!!errors.address3}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="address4"
                                                    {...register("address4", { required: false })}
                                                    label={'Address 4'}
                                                    type={"text"}
                                                    error={!!errors.address4}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Town */}
                                        {/* <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="town"
                                                    {...register("town", { required: false })}
                                                    label={'Town'}
                                                    type={"text"}
                                                    error={!!errors.town}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div> */}


                                        {/* Field post code */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="postcode"
                                                    {...register("postralCode", { required: false })}
                                                    label={'Post Code'}
                                                    type={"text"}
                                                    error={!!errors.postcode}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="country"
                                                    {...register("country", { required: false })}
                                                    label={'Country'}
                                                    type={"text"}
                                                    error={!!errors.country}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>
                                    </div>
                                }

                                {/* Field name */}
                                {/* <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="nameInput"
                                                   {...register("name", {required: true})}
                                                   label={t('lead.name')}
                                                   type={"text"}
                                                   error={!!errors.name}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div> */}

                                {/* Field campaignId */}
                                {/* <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="campaignIdLabel">
                                            Campaign
                                        </InputLabel>
                                        {  allCampaign.length > 0 &&
                                        <Select labelId="campaignIdLabel"
                                                id="campaignIdSelect"
                                                {...register("campaignId", {required: true})}
                                                error={!!errors.campaignId}
                                                value={watch('campaignId')}
                                                label="campaignId">
                                            {
                                                allCampaign.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.name}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                        }
                                         { allCampaign.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="campaignIdSelect"
                                                        {...register("campaignId", {required: true})}
                                                        error={!!errors.campaignId}
                                                        value={watch('campaignId')}
                                                        label="campaignId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div> */}

                                {/* Field supplierId */}
                                {/* <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="supplierIdLabel">
                                            Supplier
                                        </InputLabel>
                                        {  allSupplier.length > 0 &&
                                        <Select labelId="supplierIdLabel"
                                                id="supplierIdSelect"
                                                {...register("supplierId", {required: true})}
                                                error={!!errors.supplierId}
                                                value={watch('supplierId')}
                                                label="supplierId">
                                            {
                                                allSupplier.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.name}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                        }
                                         { allSupplier.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="supplierIdSelect"
                                                        {...register("supplierId", {required: true})}
                                                        error={!!errors.supplierId}
                                                        value={watch('supplierId')}
                                                        label="supplierId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div> */}

                                {/* Field accountStatusId */}
                                {/* <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="accountStatusIdLabel">
                                            Account Status
                                        </InputLabel>
                                        {  allAccountstatus.length > 0 &&
                                        <Select labelId="accountStatusIdLabel"
                                                id="accountStatusIdSelect"
                                                {...register("accountStatusId", {required: true})}
                                                error={!!errors.accountStatusId}
                                                value={watch('accountStatusId')}
                                                label="accountStatusId">
                                            {
                                                allAccountstatus.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.name}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                        }
                                         { allAccountstatus.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="accountStatusIdSelect"
                                                        {...register("accountStatusId", {required: true})}
                                                        error={!!errors.accountStatusId}
                                                        value={watch('accountStatusId')}
                                                        label="accountStatusId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div> */}

                                {/* Field dispositionStatusId */}
                                {/* <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="dispositionStatusIdLabel">
                                            Disposition Status
                                        </InputLabel>
                                        {  alldispositionStatus.length > 0 &&
                                        <Select labelId="dispositionStatusIdLabel"
                                                id="dispositionStatusIdSelect"
                                                {...register("dispositionStatusId", {required: true})}
                                                error={!!errors.dispositionStatusId}
                                                value={watch('dispositionStatusId')}
                                                label="dispositionStatusId">
                                            {
                                                alldispositionStatus.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.name}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                        }
                                         { alldispositionStatus.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="dispositionStatusIdSelect"
                                                        {...register("dispositionStatusId", {required: true})}
                                                        error={!!errors.dispositionStatusId}
                                                        value={watch('dispositionStatusId')}
                                                        label="dispositionStatusId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div> */}


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default LeadForm;
