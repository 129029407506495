import React, { useRef } from 'react';
import { useStyles } from './Styles';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { patch, post } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import CampaignForm from "../CampaignForm/CampaignForm";
import { log } from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Button } from "@mui/material";
import AlertM from '../../../../Helpers/AlertM/AlertM';


// @ts-ignore
function CampaignUpdate({ refreshDataEvent, recordToUpdate, resetEditState }) {
    // css for module
    const classes = useStyles();

    // module(s) for api
    const moduleMain = 'campaign';

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // ui handlers
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);


    // delay before closing update screen
    const sleep = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    // handle edit record event
    const handleEditEvent = (_formData: any) => {
        setLoading(true); // show loader
        if (!_formData.isSpecificEmailServer || _formData.mailServerId > 0) {
            _formData.mailServerId = null
        }
        if (_formData.isSpecificEmailServer && _formData.mailServerId == 0) {
            setLoading(false);
            // @ts-ignore
            notifications.current.errorAlert('Error!!', 'Please select mail server');
        } else {
            patch(moduleMain, _formData.id, _formData)
                .then(async (_res) => {
                    setLoading(false); // hide loader
                    refreshDataEvent(); // refresh data
                    handleClose();
                    // @ts-ignore
                    notifications.current.successAlert(t('common.editSuccess'), '');
                    // @ts-ignore
                    await sleep(process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000);
                    resetEdit();
                })
                .catch(_err => {
                    log(_err)
                    setLoading(false); // hide loader
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                    resetEdit();
                })
        }
    }

    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
        resetEdit();
    }


    // reset isEdit to false in main component
    const resetEdit = () => {
        resetEditState(true);
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <CampaignForm handleCancelEvent={handleCancelEvent}
                            handleAddEvent={() => { }}
                            isEditMode={true}
                            handleEditEvent={handleEditEvent}
                            recordToUpdate={recordToUpdate}
                            isLoading={loading} />
                    </Box>
                </Fade>
            </Modal>


            {/* Alerts */}
            <AlertM ref={notifications} />

        </div>
    );
}

export default CampaignUpdate;
