import React from 'react';
import { useStyles } from './Styles';
import { post, getAll } from "../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import { Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from '@mui/lab';


// @ts-ignore
function UserForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'user';
    const moduleRoles = 'role';
    const moduleBranches = 'branch';
    const moduleDesktypes = 'desktype';
    const moduleTeam = 'team';
    const moduleCheckInitial = 'user/check/initial';

    // linked components API modules (endpoints)


    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            // console.log('Formikkk====', _data)
            handleEditEvent(_data);
        }
    }

    // data vars
    const [isCheckedIsactive, setIsCheckedIsactive] = React.useState(false);
    const [allRoles, setAllRoles] = React.useState([]);
    const [allBranches, setAllBranches] = React.useState([]);
    const [allDeskTypes, setAllDeskTypes] = React.useState([]);
    const [allTeam, setAllTeam] = React.useState([]);
    const [selectedTeamId, setSelectedTeamId] = React.useState('');
    const [startDate, setStartDate] = React.useState<Date | null>(new Date());
    const [endDate, setEndDate] = React.useState<Date | null>(new Date());
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = React.useState(false);
    const [hasInitialError, setHasInitialError] = React.useState(false); // State for indicating error for initial

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    const handleTeamChange = (event: any) => {
        const selectedTeamId = event.target.value;
        setSelectedTeamId(selectedTeamId);
    };

    const handleBlur = (event: any) => {
        handleAddInitial(event.target.value);
    };

    // fetch dependent data
    const fetchRoles = () => {
        getAll(moduleRoles)
            .then((_res: any) => {
                // set all data
                setAllRoles(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchBranches = () => {
        getAll(moduleBranches)
            .then((_res: any) => {
                // set all data
                setAllBranches(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchDeskTypes = () => {
        getAll(moduleDesktypes)
            .then((_res: any) => {
                // set all data
                setAllDeskTypes(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch teams
    const fetchTeam = () => {
        setLoading(true); // show loader
        getAll(moduleTeam)
            .then((_res: any) => {
                setAllTeam(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    const fetch = () => {
        fetchRoles();
        fetchBranches();
        fetchDeskTypes();
        fetchTeam();
        if (isEditMode) {
            let teamId = recordToUpdate.userTeamUser?.teamUserTeam?.id;
            setSelectedTeamId(teamId);
        }
    }

    // check inital already exist or not 
    const handleAddInitial = (_initial: any) => {
        let obj = {
            initial: _initial
        }
        post(moduleCheckInitial, obj)
            .then(_res => {
                if (_res.length === 0) {
                    setIsSubmitButtonDisabled(false) 
                    setHasInitialError(false);
                    
                } else {
                    setIsSubmitButtonDisabled(true)
                    setHasInitialError(true);
                }
            })
            .catch(_err => {
                log(_err)
            })
    }


    // hooks
    React.useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

            setIsCheckedIsactive(recordToUpdate.isActive);

        }
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.user')}
                                    </h3>

                                </div>

                                {/* Field initials */}
                                <div className="col-md-2 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="initialInput"
                                            {...register("initial", { required: true })}
                                            label={t('user.initial')}
                                            type={"text"}
                                            error={hasInitialError}
                                            variant="outlined"
                                            className="full-width"
                                            onBlur={handleBlur} />
                                    </FormControl>
                                </div>

                                <div className="col-md-10">
                                    <div className="row">
                                        {/* Field firstName */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="firstNameInput"
                                                    {...register("firstName", { required: true })}
                                                    label={t('user.firstName')}
                                                    type={"text"}
                                                    error={!!errors.firstName}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field middleName */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="middleNameInput"
                                                    {...register("middleName", { required: false })}
                                                    label={t('user.middleName')}
                                                    type={"text"}
                                                    error={!!errors.middleName}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Field lastName */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="lastNameInput"
                                                    {...register("lastName", { required: true })}
                                                    label={t('user.lastName')}
                                                    type={"text"}
                                                    error={!!errors.lastName}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>

                                {/* Field email */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="emailInput"
                                            {...register("email", { required: true })}
                                            label={t('user.email')}
                                            type={"email"}
                                            error={!!errors.email}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field password */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="passwordInput"
                                            {...register("password", { required: !isEditMode })}
                                            label={t('user.password')}
                                            type={"password"}
                                            error={!!errors.password}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field contact */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="contactInput"
                                            {...register("contact", { required: true })}
                                            label={t('user.contact')}
                                            type={"number"}
                                            error={!!errors.contact}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field roleId */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="roleIdLabel">
                                            Role
                                        </InputLabel>
                                        {allRoles.length > 0 &&
                                            <Select labelId="roleIdLabel"
                                                id="roleIdSelect"
                                                {...register("roleId", { required: false })}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    const selectedRoleId = e.target.value;
                                                    isEditMode && setValue("RoleId", selectedRoleId)
                                                    setValue("roleId", selectedRoleId);
                                                }}
                                                error={!!errors.roleId}
                                                value={watch('roleId')}
                                                label="roleId">
                                                {
                                                    allRoles.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.title}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allRoles.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="roleIdSelect"
                                                    {...register("roleId", { required: false })}
                                                    error={!!errors.roleId}
                                                    // onChange={(e) => {
                                                    //     console.log('Evenet---2', e)
                                                    // }}
                                                    value={watch('roleId')}
                                                    label="roleId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field branchId */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="branchIdLabel">
                                            Branch
                                        </InputLabel>
                                        {allBranches.length > 0 &&
                                            <Select labelId="branchIdLabel"
                                                id="branchIdSelect"
                                                {...register("branchId", { required: false })}
                                                error={!!errors.branchId}
                                                value={watch('branchId')}
                                                label="branchId">
                                                {
                                                    allBranches.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allBranches.length == 0 &&
                                            <>
                                                <Select labelId="branchIdLabel"
                                                    disabled
                                                    id="branchIdSelect"
                                                    {...register("branchId", { required: false })}
                                                    error={!!errors.branchId}
                                                    value={watch('branchId')}
                                                    label="branchId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field deskTypeId */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="deskTypeIdLabel">
                                            Desk Type
                                        </InputLabel>
                                        {allDeskTypes.length > 0 &&
                                            <Select labelId="deskTypeIdLabel"
                                                id="deskTypeIdSelect"
                                                {...register("deskTypeId", { required: false })}
                                                error={!!errors.deskTypeId}
                                                value={watch('deskTypeId')}
                                                label="deskTypeId">
                                                {
                                                    allDeskTypes.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allDeskTypes.length == 0 &&
                                            <>
                                                <Select labelId="deskTypeIdLabel"
                                                    disabled
                                                    id="deskTypeIdSelect"
                                                    {...register("deskTypeId", { required: false })}
                                                    error={!!errors.deskTypeId}
                                                    value={watch('deskTypeId')}
                                                    label="deskTypeId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field startDate */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth>
                                        <DatePicker
                                            label={t('user.startDate')}
                                            {...register("startDate", { required: true })}
                                            value={startDate}
                                            onChange={(newValue) => setStartDate(newValue)}
                                            minDate={new Date()}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field endDate */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth>
                                        <DatePicker
                                            label={t('user.endDate')}
                                            {...register("endDate", { required: true })}
                                            value={endDate}
                                            onChange={(newValue) => setEndDate(newValue)}
                                            minDate={new Date()}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field position */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="positionInput"
                                            {...register("position", { required: false })}
                                            label={t('user.position')}
                                            type={"text"}
                                            error={!!errors.position}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field signaturePosition */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="signaturePositionInput"
                                            {...register("signaturePosition", { required: false })}
                                            label={t('user.signaturePosition')}
                                            type={"text"}
                                            error={!!errors.signaturePosition}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field qualification */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="qualificationInput"
                                            {...register("qualification", { required: false })}
                                            label={t('user.qualification')}
                                            type={"text"}
                                            error={!!errors.qualification}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field extension */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="extensionInput"
                                            {...register("extension", { required: false })}
                                            label={t('user.extension')}
                                            type={"text"}
                                            error={!!errors.extension}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field ddi */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="ddiInput"
                                            {...register("ddi", { required: false })}
                                            label={t('user.ddi')}
                                            type={"text"}
                                            error={!!errors.ddi}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field notes */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="notesInput"
                                            {...register("notes", { required: false })}
                                            label={t('user.notes')}
                                            type={"text"}
                                            error={!!errors.notes}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field team */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="teamIdLabel">
                                            Team
                                        </InputLabel>
                                        {allTeam.length > 0 &&
                                            <Select labelId="teamIdLabel"
                                                id="teamIdSelect"
                                                {...register("teamId", { required: false })}
                                                error={!!errors.teamId}
                                                value={selectedTeamId}
                                                onChange={handleTeamChange}
                                                label="teamId">
                                                {
                                                    allTeam.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allTeam.length == 0 &&
                                            <>
                                                <Select labelId="teamIdLabel"
                                                    disabled
                                                    id="teamIdSelect"
                                                    {...register("teamId", { required: false })}
                                                    error={!!errors.teamId}
                                                    value={selectedTeamId}
                                                    onChange={handleTeamChange}
                                                    label="teamId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field isActive */}
                                <div className="col-md-3 mt-4">
                                    <FormControl fullWidth error={!!errors.isActive}>
                                        <FormControlLabel
                                            {...register("isActive", {})}
                                            label={t('user.isActive')}
                                            control={<Checkbox checked={isCheckedIsactive}
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsCheckedIsactive(_event.target.checked)
                                                    setValue("isActive", _event.target.checked);
                                                }} />}
                                        />
                                    </FormControl>
                                </div>

                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                disabled={isSubmitButtonDisabled}
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default UserForm;
